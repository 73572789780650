export const QUERY_PARAMETER_KEY = {
  LIMIT: 'limit',
  OFFSET: 'offset',
  STARTING_AFTER: 'startingAfter',
  ENDING_BEFORE: 'endingBefore',
  ORDER_ID: 'order_id',
  USER_ID: 'user_id',
  USER_NAME: 'user_name',
  USER_EMAIL: 'user_email',
};
export const ORDER_SEARCH_QUERY_PARAM_KEY = {
  ORDER_ID: 'order',
  ORDER_ITEM_ID: 'order_item',
  USER_ID: 'user',
  INVOICE_NAME: 'user_invoice_name',
  USER_EMAIL: 'user_email',
};
export const ORDER_STATE = {
  PLACED: 'placed',
  RELEASED: 'released',
  PARTIALLY_REFUNDED: 'partially_refunded',
  REFUNDED: 'refunded',
};

export const BUYBACK_ASSIGNABLE_STATUS = Object.freeze({
  UNRESOLVED: 'unresolved',
  RESOLVED: 'resolved',
});

export const MARKETPLACE_ASSIGNABLE_STATUS = Object.freeze({
  RESOLVED_REPLACED: 'resolved.replaced',
  RESOLVED_REFUNDED: 'resolved.refunded',
});

export const ASSIGNABLE_STATUS = Object.freeze({
  ...BUYBACK_ASSIGNABLE_STATUS,
  ...MARKETPLACE_ASSIGNABLE_STATUS,
});

export const TICKET_STATUS = Object.freeze({
  ...ASSIGNABLE_STATUS,
  ASSIGNED_TO: 'assigned',
  EXPEDITE: 'expedite',
  INVOKED: 'invoked',
  OVERDUE: 'overdue',
  DELAYED: 'delayed',
  UNANSWERED: 'unanswered',
  UNASSIGNED: 'unassigned',
  WAITING_FOR_ANSWER: 'waiting_for_answer',
  PANICKED: 'panicked',
  NO_MERCHANT_ANSWER: 'no_merchant_answer',
});

export const DEFAULT_TICKET_STATUS = TICKET_STATUS.UNRESOLVED;

export const TICKET_ACTION_ENDPOINT = Object.freeze({
  HOLD_CONTINUE: 'hold-continue',
  HOLD: 'hold',
  HOLD_WD: 'hold-wd',
  HOLD_H72: 'hold-72',
  HOLD_H120: 'hold-120',
  DONE: 'done',
  COMPLETE: 'complete',
  INVOKE: 'invoke',
  EXPEDITE: 'expedite',
  PANIC: 'panic',
});

export const PANIC_MODE = Object.freeze({
  NEW: 'new',
  ANSWER: 'answer',
  TIMEOUT: 'timeout',
});
export const DEFAULT_PANIC_MODE = PANIC_MODE.NEW;

export const OVERDUE = Object.freeze({
  NEW: true,
  OTHER: false,
});
export const DEFAULT_OVERDUE = OVERDUE.NEW;

export const USER_ROLE = Object.freeze({
  ADMIN: 'refurbed',
  MERCHANT: 'merchant',
  CUSTOMER: 'customer',
});

export const AUDIENCE = Object.freeze({
  CUSTOMER: 'customer',
  MERCHANT: 'merchant',
  REFURBED: 'refurbed',
});

export const TICKET_TOPIC = Object.freeze({
  DELIVERY: 'delivery',
  DELIVERY_STATUS: 'delivery.status',
  RETURN: 'return',
  RETURN_CONDITION: 'return.condition',
  RETURN_DEFECT: 'return.defect',
  RETURN_DEFECT_BATTERY: 'return.defect.battery',
  RETURN_REVOCATION: 'return.revocation',
  WARRANTY: 'warranty',
  PAYMENT: 'payment',
  PAYMENT_INVOICE: 'payment.invoice',
  PRODUCT: 'product',
  OTHER: 'other',
});

export const TICKETS_TREESELECT_FILTER_TYPE = Object.freeze({
  DOMAIN: 'domain',
  TOPIC: 'topic',
  MERCHANT: 'merchant',
  URGENT: 'urgent',
  NEW: 'new',
  UNASSIGNED: 'unassigned',
  ASSIGNED_TO: 'assignedTo',
});
export const TICKETS_TREESELECT_FILTER_UPDATE_TYPE = Object.freeze({
  SELECT: 'select',
  DESELECT: 'deselect',
});
export const TICKETS_TREESELECT_FILTER_CONTEXT = Object.freeze({
  ...TICKET_STATUS,
  OVERDUE_NEW: 'overdue_new',
  OVERDUE_OTHER: 'overdue_other',
  PANICKED_NEW: 'panicked_new',
  PANICKED_ANSWER: 'panicked_answer',
  PANICKED_TIMEOUT: 'panicked_timeout',
});

export const TICKET_TYPE = {
  MARKETPLACE: 0,
  BUYBACK: 1,
};

export const PRODUCT_TYPE = Object.freeze({
  OFFER: 'offer',
  ADDON: 'addon',
});

export const SORT_ORDER = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

export const LOCAL_STORAGE_KEY = Object.freeze({
  TICKET_CHAT_REPLIES: 'ticketChatReplies',
});

export const EVENTBUS_EVENTS = Object.freeze({
  OPEN_LOADING: 'open-loading',
  CLOSE_LOADING: 'close-loading',
});

export const DASHBOARD_UPDATE_INTERVAL = 1000 * 60; // milliseconds
export const ASSIGNMENT_CHECK_INTERVAL = 1000 * 2.5; // ms
export const TICKET_DETAILS_REFRESH_INTERVAL = 1000 * 25; // ms

export const API_DEBOUNCE = 300;
export const API_ITEMS_LIMIT = 25;
export const API_CACHE_TIME_HELP_REQUEST_REASONS = 7200; // 2 hours
export const API_CACHE_TIME_DOMAINS = 7200; // 2 hours
export const API_CACHE_TIME_MERCHANTS = 1800; // 30 minutes
export const API_CACHE_TIME_ASSIGNEES = 900; // 15 minutes

export const LANGUAGE = Object.freeze({
  ENGLISH: 'en',
  GERMAN: 'de',
  ITALIAN: 'it',
  POLISH: 'pl',
  FRENCH: 'fr',
  SWEDISH: 'sv',
  DANISH: 'da',
  DUTCH: 'nl',
});

export const FEATURE_FLAG_EXAMPLE = 'FEATURE_FLAG_EXAMPLE';
export const FEATURE_FLAG_INVOICE_TYPE = 'FEATURE_FLAG_INVOICE_TYPE';
export const FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS = 'FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS';
export const FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS = 'FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS';
export const FEATURE_FLAG_SHOW_BB_SIDE_BAR = 'FEATURE_FLAG_SHOW_BB_SIDE_BAR';
export const FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS = 'FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS';
export const FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID = 'FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID';
export const FEATURE_FLAG_SHOW_DISCOUNTS = 'FEATURE_FLAG_SHOW_DISCOUNTS';
export const FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC = 'FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC';
export const FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE = 'FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE';

export const CREATE_TICKET_BUTTON_TOOLTIP_TITLE = Object.freeze({
  ENABLED: 'Create Ticket',
  DISABLED: 'Ticket for this order item can be created only in Zendesk',
});
