// source: refb/refurbed/core/v1/filters/buyback_attribute_filter.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.refb.refurbed.core.v1.BuybackAttributeFilter', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.ValueCase', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.core.v1.BuybackAttributeFilter.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.core.v1.BuybackAttributeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.BuybackAttributeFilter.displayName = 'proto.refb.refurbed.core.v1.BuybackAttributeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.displayName = 'proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.displayName = 'proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.oneofGroups_);
};
goog.inherits(proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.displayName = 'proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.BuybackAttributeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.BuybackAttributeFilter;
  return proto.refb.refurbed.core.v1.BuybackAttributeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute;
      reader.readMessage(value,proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.BuybackAttributeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    anyOfList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    noneOfList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum;
  return proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAnyOf(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addNoneOf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnyOfList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getNoneOfList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string any_of = 2;
 * @return {!Array<string>}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.getAnyOfList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.setAnyOfList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.addAnyOf = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.clearAnyOfList = function() {
  return this.setAnyOfList([]);
};


/**
 * repeated string none_of = 3;
 * @return {!Array<string>}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.getNoneOfList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.setNoneOfList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.addNoneOf = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.prototype.clearNoneOfList = function() {
  return this.setNoneOfList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    anyOfList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    noneOfList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric;
  return proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAnyOf(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNoneOf(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnyOfList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
  f = message.getNoneOfList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated double any_of = 2;
 * @return {!Array<number>}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.getAnyOfList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.setAnyOfList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.addAnyOf = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.clearAnyOfList = function() {
  return this.setAnyOfList([]);
};


/**
 * repeated double none_of = 3;
 * @return {!Array<number>}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.getNoneOfList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.setNoneOfList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.addNoneOf = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.prototype.clearNoneOfList = function() {
  return this.setNoneOfList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.ValueCase = {
  VALUE_NOT_SET: 0,
  VALUE_STR: 1,
  VALUE_NUM: 2
};

/**
 * @return {proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.ValueCase}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.getValueCase = function() {
  return /** @type {proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.ValueCase} */(jspb.Message.computeOneofCase(this, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueStr: (f = msg.getValueStr()) && proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.toObject(includeInstance, f),
    valueNum: (f = msg.getValueNum()) && proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute;
  return proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum;
      reader.readMessage(value,proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.deserializeBinaryFromReader);
      msg.setValueStr(value);
      break;
    case 2:
      var value = new proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric;
      reader.readMessage(value,proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.deserializeBinaryFromReader);
      msg.setValueNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueStr();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum.serializeBinaryToWriter
    );
  }
  f = message.getValueNum();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric.serializeBinaryToWriter
    );
  }
};


/**
 * optional Enum value_str = 1;
 * @return {?proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.getValueStr = function() {
  return /** @type{?proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum, 1));
};


/**
 * @param {?proto.refb.refurbed.core.v1.BuybackAttributeFilter.Enum|undefined} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute} returns this
*/
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.setValueStr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.clearValueStr = function() {
  return this.setValueStr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.hasValueStr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Numeric value_num = 2;
 * @return {?proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.getValueNum = function() {
  return /** @type{?proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric, 2));
};


/**
 * @param {?proto.refb.refurbed.core.v1.BuybackAttributeFilter.Numeric|undefined} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute} returns this
*/
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.setValueNum = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.clearValueNum = function() {
  return this.setValueNum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute.prototype.hasValueNum = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Attribute attributes = 1;
 * @return {!Array<!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute>}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute, 1));
};


/**
 * @param {!Array<!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute>} value
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter} returns this
*/
proto.refb.refurbed.core.v1.BuybackAttributeFilter.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute}
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.refb.refurbed.core.v1.BuybackAttributeFilter.Attribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeFilter} returns this
 */
proto.refb.refurbed.core.v1.BuybackAttributeFilter.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


goog.object.extend(exports, proto.refb.refurbed.core.v1);
