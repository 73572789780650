import { API_GRPC_URL } from '@/config';
import { AuthService } from '@refb/shared/services/auth.service';
import { AuthServicePromiseClient } from '~grpc/core/v1/services/auth_service_grpc_web_pb';
import GrpcAuthInterceptor from '@/utils/grpcAuthInterceptor';
import { apiService } from './api.service';
import { eventBus } from './eventBus.service';

const grpcAuthServiceClient = new AuthServicePromiseClient(
  API_GRPC_URL,
  null,
  { unaryInterceptors: [new GrpcAuthInterceptor()] },
);

export const authService = new AuthService(apiService, eventBus, grpcAuthServiceClient);

export const sessionInit = (store) => {
  if (AuthService.isAuthenticated()) {
    apiService.setHeader();
    authService.mountAuthErrorInterceptor();
  }

  eventBus.$on('api:AuthError', () => {
    store.dispatch('auth/logout');
  });
};
