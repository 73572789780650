import Vue from 'vue';
import Vuex from 'vuex';
import orderSearch from '@/store/modules/orderSearch';
import createTicket from '@/store/modules/createTicket';
import messageTemplates from '@/store/modules/messageTemplates';
import auth from './modules/auth';
import global from './modules/global';
import tickets from './modules/tickets/index';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global,
    auth,
    tickets,
    user,
    orderSearch,
    createTicket,
    createTicketMessageTemplates: messageTemplates,
    customerChatMessageTemplates: messageTemplates,
    merchantChatMessageTemplates: messageTemplates,
    refurbedChatMessageTemplates: messageTemplates,
  },
  strict: process.env.NODE_ENV !== 'production',
});
