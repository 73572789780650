import Vue from 'vue';
import Router from 'vue-router';
import { routeGuard } from '@refb/shared/services/route.service';
import LoginPage from './views/LoginPage.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      props: true,
      meta: {
        public: true,
        onlyLoggedOut: true,
      },
    },
    {
      path: '/login/logged-in-sso',
      name: 'logged-in-sso',
      component: LoginPage,
      props: true,
      meta: {
        ssoForLoggedIn: true,
      },
    },
    {
      path: '/logout/sso',
      name: 'logout-sso',
      component: LoginPage,
      props: true,
      meta: {
        ssoLogout: true,
      },
    },
  ],
});

router.beforeEach(routeGuard);


export default router;
