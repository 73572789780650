<template>
  <div
    v-if="isSSOForLoggedIn"
    class="login-page"
  >
    <div class="login-box text-white">
      Redirecting...
    </div>
  </div>
  <div
    v-else
    class="login-page"
  >
    <div
      class="login-box"
    >
      <div class="login-box__logo">
        <refurbed-logo
          v-if="shouldShowLoginForm"
          width="300"
          height="79"
        />

        <login-protection
          v-if="shouldShowOtpForm"
          width="80"
          height="80"
        />
      </div>

      <template v-if="shouldShowOtpForm">
        <span class="h2 text-center text-white"> Account authentication </span>
        <span class="login-box__info text-center">
          We sent a code to {{ maskedEmail }}. Please enter it below to authenticate your account.
          The code is valid for 15 minutes.
        </span>
      </template>

      <div class="login-box__container shadow-sm">
        <div
          v-if="showAuthError"
          class="login-box__error"
        >
          <b-alert
            v-for="error in authError"
            :key="error"
            show
            variant="warning"
          >
            {{ error }}
          </b-alert>
        </div>
        <div class="login-box__content">
          <template v-if="shouldShowLoginForm">
            <b-form @submit.prevent="handleFormSubmit">
              <b-form-group
                id="ig-email"
                label="E-mail address:"
                label-for="input-email"
              >
                <b-input
                  id="input-email"
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Enter e-mail address"
                />
              </b-form-group>

              <b-form-group
                id="ig-password"
                label="Password:"
                label-for="input-passw"
              >
                <b-input
                  id="input-passw"
                  v-model="form.password"
                  type="password"
                  required
                  placeholder="Enter password"
                />
              </b-form-group>

              <div class="text-right">
                <b-button
                  :class="{
                    'is-disabled': isSubmitButtonDisabled
                  }"
                  type="submit"
                  variant="primary"
                  :disabled="isSubmitButtonDisabled"
                >
                  Sign In
                </b-button>
              </div>
            </b-form>
          </template>

          <template v-if="shouldShowOtpForm">
            <b-form @submit.prevent="handleOtpSubmit">
              <b-form-group
                id="ig-code"
                label="Code:"
                label-for="input-code"
              >
                <b-input
                  id="input-code"
                  v-model="form.otpCode"
                  type="text"
                  required
                  placeholder="Enter code here"
                  autocomplete="off"
                />
              </b-form-group>

              <div class="text-right">
                <b-button
                  :class="{
                    'is-disabled': isOtpSubmitButtonDisabled
                  }"
                  type="submit"
                  variant="primary"
                  :disabled="isOtpSubmitButtonDisabled"
                >
                  Submit
                </b-button>
              </div>

              <div class="divider my-4" />

              <div class="helper-text px-4 text-center">
                Have you not received a code within a few minutes?
              </div>

              <div class="block text-center">
                <b-button
                  variant="link"
                  type="button"
                  :disabled="isLoading"
                  @click="handleResendCode"
                >
                  Request code
                </b-button>
              </div>
            </b-form>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { mapGetters, mapActions } from 'vuex';
import { SSOAssertionTypeEnum } from '~grpc/core/v1/enums/auth_pb';
import RefurbedLogo from '../assets/images/logo/white_mono.svg';
import LoginProtection from '../assets/images/login_protection.svg';

const LOGIN_STEPS = Object.freeze({
  login: 'login',
  oneTimePassword: 'one-time-password',
});

export default {
  name: 'LoginPage',

  components: {
    RefurbedLogo,
    LoginProtection,
  },

  beforeRouteEnter(to, from, next) {
    if (to.params.logout) {
      window.location.href = to.fullPath;
      return;
    }

    next();
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
        otpCode: null,
        tmpToken: null,
      },
      isLoading: false,
      loginStatus: false,

      requestUrl: '',
      isSSOForLoggedIn: false,
      isSSOLogout: false,

      LOGIN_STEPS,
      currentStep: LOGIN_STEPS.login,
    };
  },

  computed: {
    ...mapGetters('auth', ['authInProgress', 'authError']),

    showAuthError() {
      return this.authError?.length > 0;
    },

    shouldShowLoginForm() {
      return this.currentStep === LOGIN_STEPS.login;
    },

    shouldShowOtpForm() {
      return this.currentStep === LOGIN_STEPS.oneTimePassword;
    },

    maskedEmail() {
      return this.maskEmail(this.form.email);
    },

    isSubmitButtonDisabled() {
      return this.form.email?.length <= 3 ||
      this.form.password?.length <= 2 ||
      this.isLoading;
    },
    isOtpSubmitButtonDisabled() {
      return this.form.otpCode === null || this.form.otpCode?.length <= 3 || this.isLoading;
    },
  },

  watch: {
    async isSSOForLoggedIn(to) {
      if (to === false || !this.requestUrl) {
        return;
      }
      const ssoAssertion = await this.requestSSOAssertionForLoggedIn(
        { ssoRequestUrl: this.requestUrl },
      );
      if (!ssoAssertion) {
        this.$router.replace('/');
        return;
      }
      this.handleSSOLoginFlow(ssoAssertion);
    },

    isSSOLogout(to) {
      if (to === false) {
        return;
      }
      this.handleSSOLogoutFlow();
    },
  },

  beforeMount() {
    this.isSSOForLoggedIn = !!this.$route.meta?.ssoForLoggedIn;
    this.isSSOLogout = !!this.$route.meta?.ssoLogout;

    if (this.isSSOForLoggedIn) {
      this.requestUrl = this.$route.query.redirect;
    } else {
      this.requestUrl = window.location.toString();
    }
  },

  methods: {
    ...mapActions('auth', ['login', 'requestOTP', 'requestSSOAssertionForLoggedIn']),

    async handleFormSubmit(evt) {
      evt.preventDefault();
      this.isLoading = true;

      this.form.tmpToken = null;
      this.form.otpCode = null;

      if (this.form.email !== '' && this.form.password !== '') {
        const { success, data } = await this.login(
          {
            ...this.form, scopes: ['system:refbmyd'], redirect: true, loginRequestUrl: this.requestUrl,
          },
        );

        if (success) {
          this.form.tmpToken = data.token;
          if (data.requiresSecondaryAuthentication) {
            await this.requestOTP({ email: this.form.email });
            this.currentStep = LOGIN_STEPS.oneTimePassword;
          } else if (data.ssoAssertion) {
            this.handleSSOLoginFlow(data.ssoAssertion);
            return;
          }
        } else {
          this.form.password = '';
        }

        this.loginStatus = success;
      }

      this.isLoading = false;
    },
    async handleOtpSubmit(evt) {
      evt.preventDefault();

      this.isLoading = true;

      const form = {
        email: this.form.email,
        otpCode: this.form.otpCode,
        tmpToken: this.form.tmpToken,
      };

      const { success, data } = await this.login({
        ...form, scopes: ['system:refbmyd'], redirect: true, loginRequestUrl: this.requestUrl,
      });

      if (success) {
        this.loginStatus = success;

        if (data.ssoAssertion) {
          this.handleSSOLoginFlow(data.ssoAssertion);
          return;
        }
      }

      this.isLoading = false;
    },
    async handleResendCode(evt) {
      evt.preventDefault();

      this.isLoading = true;

      await this.requestOTP({ email: this.form.email });

      this.isLoading = false;
    },

    handleSSOLoginFlow(ssoAssertionData) {
      if (ssoAssertionData.assertionType !== SSOAssertionTypeEnum.SSOAssertionType.HTML) {
        // The SSO assertion type is not supported yet. Just redirect user to home page.
        this.$router.replace('/');
        return;
      }
      const htmlParser = new DOMParser();
      const assertionHTMLDoc = htmlParser.parseFromString(ssoAssertionData.assertion, 'text/html');
      const [form] = assertionHTMLDoc.getElementsByTagName('form');
      if (!form) {
        Sentry.captureMessage('SSO login flow expected a form element ' +
        'in the SSO assertion contents, but none was found.');
        return;
      }
      document.body.appendChild(form);
      form.submit();
    },

    // SSO logout is typically used for 2 purposes:
    // - Tell the Identity Provider app that the user has logged out of the Relying Party.
    // - Communicate occurring error messages during the login process.
    // We are interested only in the error messages.
    handleSSOLogoutFlow() {
      const params = new URLSearchParams(this.requestUrl);

      if (params.get('kind') === 'error' && params.has('message')) {
        const ssoErrorMessage = `SSO process returned error:  ${params.get('message')}`;
        console.error(ssoErrorMessage);
        Sentry.captureMessage(ssoErrorMessage);
      }

      // For us, no further action is required. Just redirect to the home page.
      this.$router.replace('/');
    },

    maskEmail(email) {
      const [localPart, domainPart] = email.split('@');

      if (localPart.length <= 3) {
        return `${localPart.charAt(0)}***@${domainPart}`;
      }

      return `${
        `${localPart.charAt(0)}***${localPart.charAt(localPart.length - 1)}`
      }@${domainPart}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: $dark;
  background-image: $gradient-background;
}

.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  &__error {
    & > * {
      margin-bottom: 0;
    }
  }

  &__logo {
    margin-bottom: 40px;
  }

  &__info {
    margin-bottom: 40px;
    margin-top: 24px;
    color: white;
    max-width: 524px;
  }

  &__container {
    background: white;
    min-width: 410px;
    max-width: 410px;
  }

  &__content {
    padding: 2 * $spacer;

    .divider {
      border-bottom: 1px solid $gray-300;
    }

    .helper-text {
      color: #6d767e;
    }

    .is-disabled {
      background-color: $gray-300;
      border-color: $gray-300;
      color: $gray-600;
    }
  }
}
</style>
