// import { parse } from 'date-fns';
// import getUnixTime from 'date-fns/getUnixTime';
// import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
// import { TimestampRangeFilter } from '~grpc/core/v1/filters/timestamp_range_filter_pb';

import { SortOrderEnum } from '~grpc/core/v1/enums/sort_order_pb';
import { BoolFilter } from '~grpc/core/v1/filters/bool_filter_pb';
import { Int64Filter } from '~grpc/core/v1/filters/int64_filter_pb';
import { NumericRangeFilter } from '~grpc/core/v1/filters/numeric_range_filter_pb';
import { OptionalNumericRangeFilter } from '~grpc/core/v1/filters/optional_numeric_range_filter_pb';
import { StringFilter } from '~grpc/core/v1/filters/string_filter_pb';
import { UInt32RangeFilter } from '~grpc/core/v1/filters/uint32_range_filter_pb';
import { Int64Pagination } from '~grpc/core/v1/paginations/int64_pagination_pb';

export const resolveItemKeypath = (obj, keyPath) => {
  if (!obj || !keyPath) {
    return null;
  }
  return keyPath.split('.').reduce((prev, curr) => {
    if (prev && (prev[curr] !== undefined)) {
      return prev[curr];
    }
    return null;
  }, obj);
};

export const capitalizeString = string => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
export const capitalizeFirstLetterString = string => string
  .toLowerCase()
  .split(' ')
  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

export const shuffleString = str => [...str].sort(() => Math.random() - 0.5).join('');

export const convertCamelCaseToEnumCase = (keyString) => {
  // eg: camelCase -> CAMEL_CASE
  const split = keyString.split(/(?=[A-Z])/g);
  return split.reduce((acc, word, ix) => `${acc}${ix > 0 ? '_' : ''}${word.toUpperCase()}`, '');
};

export const convertUnderscoreToPascalCase = (underscoreString) => {
  let split = underscoreString.split('_');
  split = split.map(string => capitalizeString(string));
  return split.join('');
};

export const getEnumKeyFromValue = (enumObj, value) => {
  const keys = Object.keys(enumObj);
  return keys.find(key => enumObj[key] === value);
};

export const formatEnumKeyToSentence = (enumKey) => {
  // eg: PARTIALLY_READY -> Partially ready
  const words = enumKey.split('_');
  const formattedWords = words.map((word, ix) => {
    let formatted = word.toLowerCase();
    if (ix === 0) {
      formatted = capitalizeString(formatted);
    }
    return formatted;
  });
  return formattedWords.join(' ');
};

export const getCountryCodeOptions = (sourceArray, valueKey = 'code', textKey = 'name') => sourceArray.map(el => ({
  value: el[valueKey],
  text: `${el[textKey]} (${el[valueKey].toUpperCase()})`,
}));

export const getOptionsArrayFromEnumObject = enumObject => Object.keys(enumObject)
  .filter(enumKey => enumKey !== 'UNSPECIFIED')
  .map(enumKey => (
    {
      value: enumObject[enumKey],
      text: formatEnumKeyToSentence(enumKey).toUpperCase(),
    }
  ));

// export const getWarrantyFromEnum = (warrantyEnum) => {
//   const monthValue = getEnumKeyFromValue(OfferWarrantyEnum.OfferWarranty, warrantyEnum);
//   const readableMonth = `${monthValue.split('M')[1]} months`;
//   return readableMonth;
// };

export const buildListPagination = (paginationParams, CustomPaginationObject) => {
  const pagination = CustomPaginationObject ? new CustomPaginationObject() : new Int64Pagination();
  pagination.setLimit(paginationParams.limit);
  if (paginationParams.startingAfter) {
    pagination.setStartingAfter(paginationParams.startingAfter);
  }
  if (paginationParams.endingBefore) {
    pagination.setEndingBefore(paginationParams.endingBefore);
  }
  return pagination;
};

export const buildListSorting = (sortingParams, ListRequest) => {
  const sort = new ListRequest.Sort();
  const byEnumKey = convertCamelCaseToEnumCase(sortingParams.by);
  const by = ListRequest.Sort.By[byEnumKey];
  const order = sortingParams.order ?
    SortOrderEnum.SortOrder.DESC : SortOrderEnum.SortOrder.ASC;
  sort.setOrder(order);
  sort.setBy(by);
  return sort;
};

export const buildFilter = {
  int64Filter: (int64Param) => {
    const int64Filter = new Int64Filter();
    if (int64Param.any_of) {
      const anyOfIds = int64Param.any_of.split(',').map(idString => parseInt(idString.trim(), 10));
      int64Filter.setAnyOfList(anyOfIds);
    }
    if (int64Param.none_of) {
      const noneOfIds = int64Param.none_of.split(',').map(idString => parseInt(idString.trim(), 10));
      int64Filter.setNoneOfList(noneOfIds);
    }
    return int64Filter;
  },
  /* timestampRangeFilter: (timestampRangeParam) => {
    const operators = ['ge', 'gt', 'le', 'lt'];
    const timestampRangeFilter = new TimestampRangeFilter();
    operators.forEach((operator) => {
      const dateString = timestampRangeParam[operator];
      if (dateString) {
        const date = parse(timestampRangeParam[operator], 'yyyy-MM-dd', new Date());
        const timeStamp = new Timestamp();
        timeStamp.setSeconds(getUnixTime(date));
        const capitalizedOperator = capitalizeString(operator);
        const setterFnKey = `set${capitalizedOperator}`;
        timestampRangeFilter[setterFnKey](timeStamp);
      }
    });
    return timestampRangeFilter;
  }, */
  stringFilter: (stringParam, customOperators, CustomFilterObject) => {
    const stringFilter = CustomFilterObject ? new CustomFilterObject() : new StringFilter();
    const operators = customOperators && customOperators.length > 0 ? customOperators :
      ['any_of', 'none_of', 'all_of'];
    const listOperators = ['any_of', 'none_of', 'all_of'];
    operators.forEach((operator) => {
      let value = stringParam[operator];
      if (value) {
        const capitalized = convertUnderscoreToPascalCase(operator);
        const setAsList = listOperators.includes(operator);
        if (setAsList && !Array.isArray(value)) {
          value = value.split(',').map(str => str.trim());
        }
        const setterFnKey = `set${capitalized}${setAsList ? 'List' : ''}`;
        stringFilter[setterFnKey](value);
      }
    });
    return stringFilter;
  },
  uInt32RangeFilter: (uInt32RangeParam, CustomFilterObject) => {
    const uInt32RangeFilter = CustomFilterObject ?
      new CustomFilterObject() :
      new UInt32RangeFilter();
    if (uInt32RangeParam.any_of) {
      const anyOfNumOffers = uInt32RangeParam.any_of.split(',').map(numString => parseInt(numString.trim(), 10));
      uInt32RangeFilter.setAnyOfList(anyOfNumOffers);
    }
    if (uInt32RangeParam.none_of) {
      const noneOfOffers = uInt32RangeParam.none_of.split(',').map(numString => parseInt(numString.trim(), 10));
      uInt32RangeFilter.setNoneOfList(noneOfOffers);
    }
    const numberOperators = ['gt', 'ge', 'le', 'lt'];
    numberOperators.forEach((operator) => {
      const num = uInt32RangeParam[operator];
      if (num) {
        const firstNum = parseInt(num.split(',')[0], 10);
        const capitalized = capitalizeString(operator); // TODO: shouldn't be parseInt here?
        const setterFnKey = `set${capitalized}`;
        uInt32RangeFilter[setterFnKey](firstNum);
      }
    });
    return uInt32RangeFilter;
  },
  customEnumFilter: (filterParam, availableOperators, EnumFilterObject) => {
    const customEnumFilter = new EnumFilterObject();
    availableOperators.forEach((operator) => {
      let values = filterParam[operator];
      if (values) {
        const capitalized = convertUnderscoreToPascalCase(operator);
        const setterFnKey = `set${capitalized}List`;
        if (!Array.isArray(values)) {
          values = [values];
        }
        customEnumFilter[setterFnKey](values);
      }
    });
    return customEnumFilter;
  },
  numericRangeFilter: (numericRangeParam) => {
    const numericRangeFilter = new NumericRangeFilter();
    if (numericRangeParam.any_of) {
      const anyOfNumOffers = numericRangeParam.any_of.split(',').map(numString => numString.trim());
      numericRangeFilter.setAnyOfList(anyOfNumOffers);
    }
    if (numericRangeParam.none_of) {
      const noneOfOffers = numericRangeParam.none_of.split(',').map(numString => numString.trim());
      numericRangeFilter.setNoneOfList(noneOfOffers);
    }
    const numberOperators = ['gt', 'ge', 'le', 'lt'];
    numberOperators.forEach((operator) => {
      const num = numericRangeParam[operator];
      if (num) {
        const firstNum = num.split(',')[0];
        const capitalized = capitalizeString(operator);
        const setterFnKey = `set${capitalized}`;
        numericRangeFilter[setterFnKey](firstNum);
      }
    });
    return numericRangeFilter;
  },
  optionalNumericRangeFilter: (optionalNumericRangeParam) => {
    const optionalNumericRangeFilter = new OptionalNumericRangeFilter();
    if (optionalNumericRangeParam.is_set) {
      optionalNumericRangeFilter.setIsSet(optionalNumericRangeParam.is_set);
    }
    if (optionalNumericRangeParam.any_of) {
      const anyOfNumOffers = optionalNumericRangeParam.any_of.split(',').map(numString => numString.trim());
      optionalNumericRangeFilter.setAnyOfList(anyOfNumOffers);
    }
    if (optionalNumericRangeParam.none_of) {
      const noneOfOffers = optionalNumericRangeParam.none_of.split(',').map(numString => numString.trim());
      optionalNumericRangeFilter.setNoneOfList(noneOfOffers);
    }
    const numberOperators = ['gt', 'ge', 'le', 'lt'];
    numberOperators.forEach((operator) => {
      const num = optionalNumericRangeParam[operator];
      if (num) {
        const firstNum = num.split(',')[0];
        const capitalized = capitalizeString(operator);
        const setterFnKey = `set${capitalized}`;
        optionalNumericRangeFilter[setterFnKey](firstNum);
      }
    });
    return optionalNumericRangeFilter;
  },
  boolFilter: (boolFilterParam) => {
    const boolFilter = new BoolFilter();
    let paramValue;
    if (typeof boolFilterParam.value === 'boolean') {
      paramValue = boolFilterParam.value;
    } else if (typeof boolFilterParam.value === 'string') {
      paramValue = boolFilterParam.value === 'true';
    }
    boolFilter.setValue(paramValue);
    return boolFilter;
  },
};

export const defaultParams = {
  filters: {},
  pagination: {
    limit: 25,
  },
  sort: {
    by: 'id',
    order: true,
  },
};
