import { ENV } from '../constants';

export function isLocal() {
  return window && /localhost/i.test(window.location.hostname) === true;
}

export function isStaging() {
  return !isLocal() && window &&
    (/staging/i.test(window.location.hostname) === true || /my-stage/i.test(window.location.hostname) === true);
}

export function isPreview() {
  return !isLocal() && window &&
    (/preview/i.test(window.location.hostname) === true || /my-preview/i.test(window.location.hostname) === true);
}

export function isDev() {
  return process.env.NODE_ENV === ENV.DEVELOPMENT;
}

export function isQa() {
  return !isLocal() && /-qa-/i.test(window.location.hostname) === true;
}

export function isProd() {
  return !isLocal() && ENV.PRODUCTION === process.env.NODE_ENV;
}

export function getEnv() {
  const [env] = Object.entries({
    [ENV.DEVELOPMENT]: isDev(),
    [ENV.LOCAL]: isLocal(),
    [ENV.STAGING]: isStaging(),
    [ENV.PREVIEW]: isPreview(),
    [ENV.QA]: isQa(),
    [ENV.PRODUCTION]: isProd(),
  }).find(([, state]) => state);
  return env;
}
