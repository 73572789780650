/**
 * @fileoverview gRPC-Web generated client stub for refb.refurbed.refurbed.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var refb_descriptor_pb = require('../../../../../refb/descriptor_pb.js')

var refb_refurbed_core_v1_enums_sort_order_pb = require('../../../../../refb/refurbed/core/v1/enums/sort_order_pb.js')

var refb_refurbed_core_v1_filters_buyback_attribute_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/buyback_attribute_filter_pb.js')

var refb_refurbed_core_v1_filters_buyback_order_item_state_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/buyback_order_item_state_filter_pb.js')

var refb_refurbed_core_v1_filters_int64_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/int64_filter_pb.js')

var refb_refurbed_core_v1_filters_numeric_range_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/numeric_range_filter_pb.js')

var refb_refurbed_core_v1_filters_string_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/string_filter_pb.js')

var refb_refurbed_core_v1_filters_text_search_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/text_search_filter_pb.js')

var refb_refurbed_core_v1_filters_timestamp_range_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/timestamp_range_filter_pb.js')

var refb_refurbed_core_v1_paginations_int64_pagination_pb = require('../../../../../refb/refurbed/core/v1/paginations/int64_pagination_pb.js')

var refb_refurbed_refurbed_v1_resources_buyback_order_pb = require('../../../../../refb/refurbed/refurbed/v1/resources/buyback_order_pb.js')
const proto = {};
proto.refb = {};
proto.refb.refurbed = {};
proto.refb.refurbed.refurbed = {};
proto.refb.refurbed.refurbed.v1 = require('./buyback_order_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.refb.refurbed.refurbed.v1.ListBuybackOrdersRequest,
 *   !proto.refb.refurbed.refurbed.v1.ListBuybackOrdersResponse>}
 */
const methodDescriptor_BuybackOrderService_ListBuybackOrders = new grpc.web.MethodDescriptor(
  '/refb.refurbed.refurbed.v1.BuybackOrderService/ListBuybackOrders',
  grpc.web.MethodType.UNARY,
  proto.refb.refurbed.refurbed.v1.ListBuybackOrdersRequest,
  proto.refb.refurbed.refurbed.v1.ListBuybackOrdersResponse,
  /**
   * @param {!proto.refb.refurbed.refurbed.v1.ListBuybackOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.refb.refurbed.refurbed.v1.ListBuybackOrdersResponse.deserializeBinary
);


/**
 * @param {!proto.refb.refurbed.refurbed.v1.ListBuybackOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.refb.refurbed.refurbed.v1.ListBuybackOrdersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.refb.refurbed.refurbed.v1.ListBuybackOrdersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderServiceClient.prototype.listBuybackOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/refb.refurbed.refurbed.v1.BuybackOrderService/ListBuybackOrders',
      request,
      metadata || {},
      methodDescriptor_BuybackOrderService_ListBuybackOrders,
      callback);
};


/**
 * @param {!proto.refb.refurbed.refurbed.v1.ListBuybackOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.refb.refurbed.refurbed.v1.ListBuybackOrdersResponse>}
 *     Promise that resolves to the response
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderServicePromiseClient.prototype.listBuybackOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/refb.refurbed.refurbed.v1.BuybackOrderService/ListBuybackOrders',
      request,
      metadata || {},
      methodDescriptor_BuybackOrderService_ListBuybackOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.refb.refurbed.refurbed.v1.GetBuybackOrderRequest,
 *   !proto.refb.refurbed.refurbed.v1.GetBuybackOrderResponse>}
 */
const methodDescriptor_BuybackOrderService_GetBuybackOrder = new grpc.web.MethodDescriptor(
  '/refb.refurbed.refurbed.v1.BuybackOrderService/GetBuybackOrder',
  grpc.web.MethodType.UNARY,
  proto.refb.refurbed.refurbed.v1.GetBuybackOrderRequest,
  proto.refb.refurbed.refurbed.v1.GetBuybackOrderResponse,
  /**
   * @param {!proto.refb.refurbed.refurbed.v1.GetBuybackOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.refb.refurbed.refurbed.v1.GetBuybackOrderResponse.deserializeBinary
);


/**
 * @param {!proto.refb.refurbed.refurbed.v1.GetBuybackOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.refb.refurbed.refurbed.v1.GetBuybackOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.refb.refurbed.refurbed.v1.GetBuybackOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderServiceClient.prototype.getBuybackOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/refb.refurbed.refurbed.v1.BuybackOrderService/GetBuybackOrder',
      request,
      metadata || {},
      methodDescriptor_BuybackOrderService_GetBuybackOrder,
      callback);
};


/**
 * @param {!proto.refb.refurbed.refurbed.v1.GetBuybackOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.refb.refurbed.refurbed.v1.GetBuybackOrderResponse>}
 *     Promise that resolves to the response
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderServicePromiseClient.prototype.getBuybackOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/refb.refurbed.refurbed.v1.BuybackOrderService/GetBuybackOrder',
      request,
      metadata || {},
      methodDescriptor_BuybackOrderService_GetBuybackOrder);
};


module.exports = proto.refb.refurbed.refurbed.v1;

