// source: refb/refurbed/core/v1/enums/country_code.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.refb.refurbed.core.v1.CountryCodeEnum', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.CountryCodeEnum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.core.v1.CountryCodeEnum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.CountryCodeEnum.displayName = 'proto.refb.refurbed.core.v1.CountryCodeEnum';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.CountryCodeEnum.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.CountryCodeEnum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.CountryCodeEnum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.CountryCodeEnum.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.CountryCodeEnum}
 */
proto.refb.refurbed.core.v1.CountryCodeEnum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.CountryCodeEnum;
  return proto.refb.refurbed.core.v1.CountryCodeEnum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.CountryCodeEnum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.CountryCodeEnum}
 */
proto.refb.refurbed.core.v1.CountryCodeEnum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.CountryCodeEnum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.CountryCodeEnum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.CountryCodeEnum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.CountryCodeEnum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode = {
  UNSPECIFIED: 0,
  AF: 4,
  AL: 8,
  AQ: 10,
  DZ: 12,
  AS: 16,
  AD: 20,
  AO: 24,
  AG: 28,
  AZ: 31,
  AR: 32,
  AU: 36,
  AT: 40,
  BS: 44,
  BH: 48,
  BD: 50,
  AM: 51,
  BB: 52,
  BE: 56,
  BM: 60,
  BT: 64,
  BO: 68,
  BA: 70,
  BW: 72,
  BV: 74,
  BR: 76,
  BZ: 84,
  IO: 86,
  SB: 90,
  VG: 92,
  BN: 96,
  BG: 100,
  MM: 104,
  BI: 108,
  BY: 112,
  KH: 116,
  CM: 120,
  CA: 124,
  CV: 132,
  KY: 136,
  CF: 140,
  LK: 144,
  TD: 148,
  CL: 152,
  CN: 156,
  TW: 158,
  CX: 162,
  CC: 166,
  CO: 170,
  KM: 174,
  YT: 175,
  CG: 178,
  CD: 180,
  CK: 184,
  CR: 188,
  HR: 191,
  CU: 192,
  CY: 196,
  CZ: 203,
  BJ: 204,
  DK: 208,
  DM: 212,
  DO: 214,
  EC: 218,
  SV: 222,
  GQ: 226,
  ET: 231,
  ER: 232,
  EE: 233,
  FO: 234,
  FK: 238,
  GS: 239,
  FJ: 242,
  FI: 246,
  AX: 248,
  FR: 250,
  GF: 254,
  PF: 258,
  TF: 260,
  DJ: 262,
  GA: 266,
  GE: 268,
  GM: 270,
  PS: 275,
  DE: 276,
  GH: 288,
  GI: 292,
  KI: 296,
  GR: 300,
  GL: 304,
  GD: 308,
  GP: 312,
  GU: 316,
  GT: 320,
  GN: 324,
  GY: 328,
  HT: 332,
  HM: 334,
  VA: 336,
  HN: 340,
  HK: 344,
  HU: 348,
  IS: 352,
  IN: 356,
  ID: 360,
  IR: 364,
  IQ: 368,
  IE: 372,
  IL: 376,
  IT: 380,
  CI: 384,
  JM: 388,
  JP: 392,
  KZ: 398,
  JO: 400,
  KE: 404,
  KP: 408,
  KR: 410,
  KW: 414,
  KG: 417,
  LA: 418,
  LB: 422,
  LS: 426,
  LV: 428,
  LR: 430,
  LY: 434,
  LI: 438,
  LT: 440,
  LU: 442,
  MO: 446,
  MG: 450,
  MW: 454,
  MY: 458,
  MV: 462,
  ML: 466,
  MT: 470,
  MQ: 474,
  MR: 478,
  MU: 480,
  MX: 484,
  MC: 492,
  MN: 496,
  MD: 498,
  ME: 499,
  MS: 500,
  MA: 504,
  MZ: 508,
  OM: 512,
  NA: 516,
  NR: 520,
  NP: 524,
  NL: 528,
  CW: 531,
  AW: 533,
  SX: 534,
  BQ: 535,
  NC: 540,
  VU: 548,
  NZ: 554,
  NI: 558,
  NE: 562,
  NG: 566,
  NU: 570,
  NF: 574,
  NO: 578,
  MP: 580,
  UM: 581,
  FM: 583,
  MH: 584,
  PW: 585,
  PK: 586,
  PA: 591,
  PG: 598,
  PY: 600,
  PE: 604,
  PH: 608,
  PN: 612,
  PL: 616,
  PT: 620,
  GW: 624,
  TL: 626,
  PR: 630,
  QA: 634,
  RE: 638,
  RO: 642,
  RU: 643,
  RW: 646,
  BL: 652,
  SH: 654,
  KN: 659,
  AI: 660,
  LC: 662,
  MF: 663,
  PM: 666,
  VC: 670,
  SM: 674,
  ST: 678,
  SA: 682,
  SN: 686,
  RS: 688,
  SC: 690,
  SL: 694,
  SG: 702,
  SK: 703,
  VN: 704,
  SI: 705,
  SO: 706,
  ZA: 710,
  ZW: 716,
  ES: 724,
  SS: 728,
  SD: 729,
  EH: 732,
  SR: 740,
  SJ: 744,
  SZ: 748,
  SE: 752,
  CH: 756,
  SY: 760,
  TJ: 762,
  TH: 764,
  TG: 768,
  TK: 772,
  TO: 776,
  TT: 780,
  AE: 784,
  TN: 788,
  TR: 792,
  TM: 795,
  TC: 796,
  TV: 798,
  UG: 800,
  UA: 804,
  MK: 807,
  EG: 818,
  GB: 826,
  GG: 831,
  JE: 832,
  IM: 833,
  TZ: 834,
  US: 840,
  VI: 850,
  BF: 854,
  UY: 858,
  UZ: 860,
  VE: 862,
  WF: 876,
  WS: 882,
  YE: 887,
  ZM: 894
};

goog.object.extend(exports, proto.refb.refurbed.core.v1);
