
import { authModule } from '@refb/shared/storeModules/auth.module';
import { authService } from '../../services/auth.service';
import router from '../../router';

const auth = authModule({ router, authService })({
  namespaced: true,

  actions: {
    logoutWithErrorMessage({ commit, dispatch }, message) {
      commit('loginError', {
        message: [message],
        errorCode: 400,
      });

      dispatch('logout');
    },
  },
});

export default auth;
