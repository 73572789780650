import { buildTree, removeEmptyChildren } from '@refb/shared/services/ticketTopicTree';

export const getTicketTopics = state => state.ticketTopics || [];

export const getTicketTopicsTree = state => (translate) => {
  const { ticketTopics } = state;

  const tree = buildTree(ticketTopics, translate);

  return removeEmptyChildren(tree);
};
