import merge from 'lodash/merge';
import { HttpService } from '@refb/shared/services/http.service';
import { API_URL, API_VERSION } from '@/config';

export const apiService = new HttpService(`${API_URL}/${API_VERSION}`, {
  convertKeys: true,
});

export const mergeResponses = (response) => {
  if (Array.isArray(response)) {
    return response.reduce((prev, res) => merge(prev, mergeResponses(res)), {});
  }

  return response;
};

export const validateResponse = response => (response.errors && response.errors.length !== 0);

export class HttpError extends Error {
  #errors = []

  constructor(err) {
    const errors = Array.isArray(err) ?
      err :
      [err];

    super(errors.join());

    this.name = 'HttpError';
    this.errors.concat(errors);
  }

  getErrors() {
    return this.errors;
  }
}
