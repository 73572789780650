export const REASONS = {
  TICKET_TOPIC_CHANGE: 'ticket_topic_change',
  REFURBED_TOPIC: 'refurbed_topic',
  CUSTOMER_COMMUNICATION_ISSUES: 'customer_communication_issues',
  PLATFORM_ISSUES: 'platform_issues',
  ATO_HELP: 'ato_help',
  SHIPPING_HELP: 'shipping_help',
  OTHER: 'other',
};

export const REASONS_LABEL = {
  [REASONS.TICKET_TOPIC_CHANGE]: 'Ticket topic change request',
  [REASONS.REFURBED_TOPIC]:
    'Help with topic refurbed is responsible for (e.g. Panzerglass, Insurance, Klarna, PayPal)',
  [REASONS.CUSTOMER_COMMUNICATION_ISSUES]:
    "Help in communication with the customer (e.g. customer doesn't cooperate)",
  [REASONS.PLATFORM_ISSUES]: "Help with technical issues (e.g. refund button doesn't work)",
  [REASONS.ATO_HELP]: 'Help with Account Take Over (to be chosen only by ATO partner)',
  [REASONS.SHIPPING_HELP]: 'Help with Shipping Agreement',
  [REASONS.OTHER]: 'Other',
};

function getReasonLabel(reason) {
  return REASONS_LABEL[reason] || reason;
}

export {
  getReasonLabel,
};
