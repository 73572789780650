// source: refb/refurbed/refurbed/v1/resources/buyback_order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var refb_refurbed_core_v1_enums_buyback_order_item_state_pb = require('../../../../../refb/refurbed/core/v1/enums/buyback_order_item_state_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_enums_buyback_order_item_state_pb);
var refb_refurbed_core_v1_enums_currency_code_pb = require('../../../../../refb/refurbed/core/v1/enums/currency_code_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_enums_currency_code_pb);
var refb_refurbed_refurbed_v1_resources_address_pb = require('../../../../../refb/refurbed/refurbed/v1/resources/address_pb.js');
goog.object.extend(proto, refb_refurbed_refurbed_v1_resources_address_pb);
var refb_refurbed_refurbed_v1_resources_buyback_bid_pb = require('../../../../../refb/refurbed/refurbed/v1/resources/buyback_bid_pb.js');
goog.object.extend(proto, refb_refurbed_refurbed_v1_resources_buyback_bid_pb);
goog.exportSymbol('proto.refb.refurbed.refurbed.v1.BuybackOrder', null, global);
goog.exportSymbol('proto.refb.refurbed.refurbed.v1.BuybackOrderItem', null, global);
goog.exportSymbol('proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory', null, global);
goog.exportSymbol('proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition', null, global);
goog.exportSymbol('proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.refurbed.v1.BuybackOrder.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.refurbed.v1.BuybackOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.refurbed.v1.BuybackOrder.displayName = 'proto.refb.refurbed.refurbed.v1.BuybackOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.displayName = 'proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.refurbed.v1.BuybackOrderItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.refurbed.v1.BuybackOrderItem.displayName = 'proto.refb.refurbed.refurbed.v1.BuybackOrderItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.displayName = 'proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.displayName = 'proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.refurbed.v1.BuybackOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    returnAddress: (f = msg.getReturnAddress()) && refb_refurbed_refurbed_v1_resources_address_pb.Address.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    orderItemsList: jspb.Message.toObjectList(msg.getOrderItemsList(),
    proto.refb.refurbed.refurbed.v1.BuybackOrderItem.toObject, includeInstance),
    customerEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    invoiceAddress: (f = msg.getInvoiceAddress()) && refb_refurbed_refurbed_v1_resources_address_pb.Address.toObject(includeInstance, f),
    customerId: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.refurbed.v1.BuybackOrder;
  return proto.refb.refurbed.refurbed.v1.BuybackOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var value = new refb_refurbed_refurbed_v1_resources_address_pb.Address;
      reader.readMessage(value,refb_refurbed_refurbed_v1_resources_address_pb.Address.deserializeBinaryFromReader);
      msg.setReturnAddress(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = new proto.refb.refurbed.refurbed.v1.BuybackOrderItem;
      reader.readMessage(value,proto.refb.refurbed.refurbed.v1.BuybackOrderItem.deserializeBinaryFromReader);
      msg.addOrderItems(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerEmail(value);
      break;
    case 7:
      var value = new refb_refurbed_refurbed_v1_resources_address_pb.Address;
      reader.readMessage(value,refb_refurbed_refurbed_v1_resources_address_pb.Address.deserializeBinaryFromReader);
      msg.setInvoiceAddress(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.refurbed.v1.BuybackOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReturnAddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      refb_refurbed_refurbed_v1_resources_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOrderItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.refb.refurbed.refurbed.v1.BuybackOrderItem.serializeBinaryToWriter
    );
  }
  f = message.getCustomerEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInvoiceAddress();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      refb_refurbed_refurbed_v1_resources_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Address return_address = 3;
 * @return {?proto.refb.refurbed.refurbed.v1.Address}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.getReturnAddress = function() {
  return /** @type{?proto.refb.refurbed.refurbed.v1.Address} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_refurbed_v1_resources_address_pb.Address, 3));
};


/**
 * @param {?proto.refb.refurbed.refurbed.v1.Address|undefined} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.setReturnAddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.clearReturnAddress = function() {
  return this.setReturnAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.hasReturnAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated BuybackOrderItem order_items = 5;
 * @return {!Array<!proto.refb.refurbed.refurbed.v1.BuybackOrderItem>}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.getOrderItemsList = function() {
  return /** @type{!Array<!proto.refb.refurbed.refurbed.v1.BuybackOrderItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.refb.refurbed.refurbed.v1.BuybackOrderItem, 5));
};


/**
 * @param {!Array<!proto.refb.refurbed.refurbed.v1.BuybackOrderItem>} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.setOrderItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.addOrderItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.refb.refurbed.refurbed.v1.BuybackOrderItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.clearOrderItemsList = function() {
  return this.setOrderItemsList([]);
};


/**
 * optional string customer_email = 6;
 * @return {string}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.getCustomerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.setCustomerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional Address invoice_address = 7;
 * @return {?proto.refb.refurbed.refurbed.v1.Address}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.getInvoiceAddress = function() {
  return /** @type{?proto.refb.refurbed.refurbed.v1.Address} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_refurbed_v1_resources_address_pb.Address, 7));
};


/**
 * @param {?proto.refb.refurbed.refurbed.v1.Address|undefined} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.setInvoiceAddress = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.clearInvoiceAddress = function() {
  return this.setInvoiceAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.hasInvoiceAddress = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 customer_id = 8;
 * @return {number}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrder} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrder.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto.refb.refurbed.refurbed.v1.BuybackOrder.toObject(includeInstance, f),
    hasTickets: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    merchantMigrationStarted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo;
  return proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.refb.refurbed.refurbed.v1.BuybackOrder;
      reader.readMessage(value,proto.refb.refurbed.refurbed.v1.BuybackOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasTickets(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMerchantMigrationStarted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.refb.refurbed.refurbed.v1.BuybackOrder.serializeBinaryToWriter
    );
  }
  f = message.getHasTickets();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMerchantMigrationStarted();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional BuybackOrder order = 1;
 * @return {?proto.refb.refurbed.refurbed.v1.BuybackOrder}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.getOrder = function() {
  return /** @type{?proto.refb.refurbed.refurbed.v1.BuybackOrder} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.refurbed.v1.BuybackOrder, 1));
};


/**
 * @param {?proto.refb.refurbed.refurbed.v1.BuybackOrder|undefined} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool has_tickets = 2;
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.getHasTickets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.setHasTickets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool merchant_migration_started = 3;
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.getMerchantMigrationStarted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderWithRelatedInfo.prototype.setMerchantMigrationStarted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.refurbed.v1.BuybackOrderItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    state: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    price: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bid: (f = msg.getBid()) && refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary.toObject(includeInstance, f),
    shippingTrackingUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    shippingTrackingNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    returnTrackingUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    returnTrackingNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    customerPriceOffered: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.refurbed.v1.BuybackOrderItem;
  return proto.refb.refurbed.refurbed.v1.BuybackOrderItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} */ (reader.readEnum());
      msg.setCurrencyCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 5:
      var value = new refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary;
      reader.readMessage(value,refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary.deserializeBinaryFromReader);
      msg.setBid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShippingTrackingUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShippingTrackingNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnTrackingUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnTrackingNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerPriceOffered(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.refurbed.v1.BuybackOrderItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCustomerPriceOffered();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState state = 2;
 * @return {!proto.refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getState = function() {
  return /** @type {!proto.refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode currency_code = 3;
 * @return {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getCurrencyCode = function() {
  return /** @type {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string price = 4;
 * @return {string}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional BuybackMarketBidSummary bid = 5;
 * @return {?proto.refb.refurbed.refurbed.v1.BuybackMarketBidSummary}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getBid = function() {
  return /** @type{?proto.refb.refurbed.refurbed.v1.BuybackMarketBidSummary} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary, 5));
};


/**
 * @param {?proto.refb.refurbed.refurbed.v1.BuybackMarketBidSummary|undefined} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setBid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.clearBid = function() {
  return this.setBid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.hasBid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string shipping_tracking_url = 6;
 * @return {string}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getShippingTrackingUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setShippingTrackingUrl = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.clearShippingTrackingUrl = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.hasShippingTrackingUrl = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string shipping_tracking_number = 7;
 * @return {string}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getShippingTrackingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setShippingTrackingNumber = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.clearShippingTrackingNumber = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.hasShippingTrackingNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string return_tracking_url = 8;
 * @return {string}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getReturnTrackingUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setReturnTrackingUrl = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.clearReturnTrackingUrl = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.hasReturnTrackingUrl = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string return_tracking_number = 9;
 * @return {string}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getReturnTrackingNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setReturnTrackingNumber = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.clearReturnTrackingNumber = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.hasReturnTrackingNumber = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string customer_price_offered = 10;
 * @return {string}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.getCustomerPriceOffered = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItem} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItem.prototype.setCustomerPriceOffered = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderItemId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stateTransitionsList: jspb.Message.toObjectList(msg.getStateTransitionsList(),
    proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.toObject, includeInstance),
    secondOfferDetails: (f = msg.getSecondOfferDetails()) && refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory;
  return proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderItemId(value);
      break;
    case 2:
      var value = new proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition;
      reader.readMessage(value,proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.deserializeBinaryFromReader);
      msg.addStateTransitions(value);
      break;
    case 3:
      var value = new refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary;
      reader.readMessage(value,refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary.deserializeBinaryFromReader);
      msg.setSecondOfferDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderItemId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStateTransitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.serializeBinaryToWriter
    );
  }
  f = message.getSecondOfferDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, 0),
    at: (f = msg.getAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition;
  return proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState state = 1;
 * @return {!proto.refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.prototype.getState = function() {
  return /** @type {!proto.refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.BuybackOrderItemStateEnum.BuybackOrderItemState} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.prototype.getAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.prototype.setAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.prototype.clearAt = function() {
  return this.setAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition.prototype.hasAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 order_item_id = 1;
 * @return {number}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.getOrderItemId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.setOrderItemId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated StateTransition state_transitions = 2;
 * @return {!Array<!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition>}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.getStateTransitionsList = function() {
  return /** @type{!Array<!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition, 2));
};


/**
 * @param {!Array<!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition>} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.setStateTransitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.addStateTransitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.StateTransition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.clearStateTransitionsList = function() {
  return this.setStateTransitionsList([]);
};


/**
 * optional BuybackMarketBidSummary second_offer_details = 3;
 * @return {?proto.refb.refurbed.refurbed.v1.BuybackMarketBidSummary}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.getSecondOfferDetails = function() {
  return /** @type{?proto.refb.refurbed.refurbed.v1.BuybackMarketBidSummary} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_refurbed_v1_resources_buyback_bid_pb.BuybackMarketBidSummary, 3));
};


/**
 * @param {?proto.refb.refurbed.refurbed.v1.BuybackMarketBidSummary|undefined} value
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory} returns this
*/
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.setSecondOfferDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory} returns this
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.clearSecondOfferDetails = function() {
  return this.setSecondOfferDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.refurbed.v1.BuybackOrderItemHistory.prototype.hasSecondOfferDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.refb.refurbed.refurbed.v1);
