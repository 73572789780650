import { render, staticRenderFns } from "./login_protection.svg?vue&type=template&id=36ab4240"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_css-loader@5.2.7_handlebars@4.7.8_lodash@4.17.21_oxjiv4wsthniqiaz2n2da3zmjq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports