// source: refb/refurbed/core/v1/enums/currency_code.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.refb.refurbed.core.v1.CurrencyCodeEnum', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.CurrencyCodeEnum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.core.v1.CurrencyCodeEnum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.CurrencyCodeEnum.displayName = 'proto.refb.refurbed.core.v1.CurrencyCodeEnum';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.CurrencyCodeEnum.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.CurrencyCodeEnum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.CurrencyCodeEnum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.CurrencyCodeEnum.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.CurrencyCodeEnum}
 */
proto.refb.refurbed.core.v1.CurrencyCodeEnum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.CurrencyCodeEnum;
  return proto.refb.refurbed.core.v1.CurrencyCodeEnum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.CurrencyCodeEnum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.CurrencyCodeEnum}
 */
proto.refb.refurbed.core.v1.CurrencyCodeEnum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.CurrencyCodeEnum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.CurrencyCodeEnum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.CurrencyCodeEnum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.CurrencyCodeEnum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode = {
  UNSPECIFIED: 0,
  ALL: 8,
  DZD: 12,
  ARS: 32,
  AUD: 36,
  BSD: 44,
  BHD: 48,
  BDT: 50,
  AMD: 51,
  BBD: 52,
  BMD: 60,
  BTN: 64,
  BOB: 68,
  BWP: 72,
  BZD: 84,
  SBD: 90,
  BND: 96,
  MMK: 104,
  BIF: 108,
  KHR: 116,
  CAD: 124,
  CVE: 132,
  KYD: 136,
  LKR: 144,
  CLP: 152,
  CNY: 156,
  COP: 170,
  KMF: 174,
  CRC: 188,
  HRK: 191,
  CUP: 192,
  CZK: 203,
  DKK: 208,
  DOP: 214,
  SVC: 222,
  ETB: 230,
  ERN: 232,
  FKP: 238,
  FJD: 242,
  DJF: 262,
  GMD: 270,
  GIP: 292,
  GTQ: 320,
  GNF: 324,
  GYD: 328,
  HTG: 332,
  HNL: 340,
  HKD: 344,
  HUF: 348,
  ISK: 352,
  INR: 356,
  IDR: 360,
  IRR: 364,
  IQD: 368,
  ILS: 376,
  JMD: 388,
  JPY: 392,
  KZT: 398,
  JOD: 400,
  KES: 404,
  KPW: 408,
  KRW: 410,
  KWD: 414,
  KGS: 417,
  LAK: 418,
  LBP: 422,
  LSL: 426,
  LRD: 430,
  LYD: 434,
  LTL: 440,
  MOP: 446,
  MWK: 454,
  MYR: 458,
  MVR: 462,
  MRO: 478,
  MUR: 480,
  MXN: 484,
  MNT: 496,
  MDL: 498,
  MAD: 504,
  OMR: 512,
  NAD: 516,
  NPR: 524,
  ANG: 532,
  AWG: 533,
  VUV: 548,
  NZD: 554,
  NIO: 558,
  NGN: 566,
  NOK: 578,
  PKR: 586,
  PAB: 590,
  PGK: 598,
  PYG: 600,
  PEN: 604,
  PHP: 608,
  QAR: 634,
  RUB: 643,
  RWF: 646,
  SHP: 654,
  STD: 678,
  SAR: 682,
  SCR: 690,
  SLL: 694,
  SGD: 702,
  VND: 704,
  SOS: 706,
  ZAR: 710,
  SSP: 728,
  SZL: 748,
  SEK: 752,
  CHF: 756,
  SYP: 760,
  THB: 764,
  TOP: 776,
  TTD: 780,
  AED: 784,
  TND: 788,
  UGX: 800,
  MKD: 807,
  EGP: 818,
  GBP: 826,
  TZS: 834,
  USD: 840,
  UYU: 858,
  UZS: 860,
  WST: 882,
  YER: 886,
  TWD: 901,
  CUC: 931,
  ZWL: 932,
  TMT: 934,
  GHS: 936,
  VEF: 937,
  SDG: 938,
  UYI: 940,
  RSD: 941,
  MZN: 943,
  AZN: 944,
  RON: 946,
  CHE: 947,
  CHW: 948,
  TRY: 949,
  XAF: 950,
  XCD: 951,
  XOF: 952,
  XPF: 953,
  XBA: 955,
  XBB: 956,
  XBC: 957,
  XBD: 958,
  XAU: 959,
  XDR: 960,
  XAG: 961,
  XPT: 962,
  XPD: 964,
  XUA: 965,
  ZMW: 967,
  SRD: 968,
  MGA: 969,
  COU: 970,
  AFN: 971,
  TJS: 972,
  AOA: 973,
  BYR: 974,
  BGN: 975,
  CDF: 976,
  BAM: 977,
  EUR: 978,
  MXV: 979,
  UAH: 980,
  GEL: 981,
  BOV: 984,
  PLN: 985,
  BRL: 986,
  CLF: 990,
  XSU: 994,
  USN: 997
};

goog.object.extend(exports, proto.refb.refurbed.core.v1);
