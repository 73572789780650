import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';

export function convertObjectKeys(conversionMethod, params) {
  if (params === undefined) return undefined;

  if (Array.isArray(params)) {
    return params.map(item => convertObjectKeys(conversionMethod, item));
  }

  if (!isPlainObject(params)) return params;

  return Object.entries(params).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [conversionMethod(key)]: convertObjectKeys(conversionMethod, value),
    }),
    {},
  );
}

export function objectKeysToSnakeCase(params) {
  return convertObjectKeys(snakeCase, params);
}

export function objectKeysToCamelCase(params) {
  return convertObjectKeys(camelCase, params);
}
