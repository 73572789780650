import { OrderService } from '@/services/OrderService';
import { getEnumKeyFromValue, formatEnumKeyToSentence } from '@/utils/grpc';
import { BuybackOrderService } from '@/services/refurbed/BuybackOrderService';
import { BuybackOrderService as MerchantBuybackOrderService } from '@/services/merchant/BuybackOrderService';

import { PRODUCT_TYPE, USER_ROLE } from '@/constants';
import { formatDateTime } from '@/utils/date';
import { BuybackBidGradingEnum } from '~grpc/core/v1/enums/buyback_bid_grading_pb';
import { CountryCodeEnum } from '~grpc/core/v1/enums/country_code_pb';
import { AddressEntityEnum } from '~grpc/merchant/v1/enums/address_entity_pb';
import { BuybackOrderItemStateEnum } from '~grpc/core/v1/enums/buyback_order_item_state_pb';
import { CurrencyCodeEnum } from '~grpc/core/v1/enums/currency_code_pb';

export const ORDER_SERVICES = {
  ORDER_SERVICE: 'order_service',
  BUYBACK_ORDER_SERVICE: 'buyback_order_service',
};

class OrderServiceBridge {
  constructor(service, role = null) {
    switch (service) {
      case ORDER_SERVICES.ORDER_SERVICE:
        this.service = new OrderService();
        break;
      case ORDER_SERVICES.BUYBACK_ORDER_SERVICE:
        switch (role) {
          case USER_ROLE.ADMIN:
            this.service = new BuybackOrderService();
            break;
          case USER_ROLE.MERCHANT:
            this.service = new MerchantBuybackOrderService();
            break;
          default:
            throw new Error('OrderServiceBridge::constructor [error] - unknown role');
        }
        break;
      default:
        throw new Error('OrderServiceBridge::constructor [error] - unknown service');
    }
  }

  async getOrder(orderId) {
    const getProductGrading = (gradingEnum, value) => getEnumKeyFromValue(gradingEnum, value);
    const getProductNameAndGrading = (item, gradingEnum) => `${item.bid.productName} | ${getProductGrading(gradingEnum, item.bid.grading)}`;

    if (this.service instanceof OrderService) {
      const data = await this.service.getOrder(orderId);
      return data;
    }

    const invoiceAddrCountry = order => getEnumKeyFromValue(
      CountryCodeEnum.CountryCode, order.invoiceAddress?.countryCode,
    );
    const invoiceAddrMale = order => (AddressEntityEnum.AddressEntity.COMPANY === order.invoiceAddress?.entity ? null : 'Private');

    const shippingAddrCountry = order => getEnumKeyFromValue(
      CountryCodeEnum.CountryCode, order.returnAddress?.countryCode,
    );
    const shippingAddrMale = order => (AddressEntityEnum.AddressEntity.COMPANY === order.returnAddress?.entity ? null : 'Private');

    const merchantCountryByItem = itemCountryCode => getEnumKeyFromValue(
      CountryCodeEnum.CountryCode, itemCountryCode,
    );

    const currencyCode = itemCurrencyCode => getEnumKeyFromValue(
      CurrencyCodeEnum.CurrencyCode, itemCurrencyCode,
    );

    const buybackOrderResponse = (order, orderItemsHistoryList, orderWithRelatedInfo) => (
      {
        ...order,
        orderItemsHistoryList,
        user: {
          email: order.customerEmail,
        },
        invoiceAddr: {
          ...order.invoiceAddress,
          phone: order.invoiceAddress.phoneNumber || '',
          country: invoiceAddrCountry(order),
          male: invoiceAddrMale(order),
        },
        shippingAddr: {
          ...order.returnAddress,
          phone: order.returnAddress.phoneNumber || '',
          country: shippingAddrCountry(order),
          male: shippingAddrMale(order),
        },
        presentmentCurrency: currencyCode(order.orderItemsList[0].currencyCode),
        orderItemsList: order.orderItemsList.map(item => ({
          ...item,
          totalCharged: item.price,
          itemType: PRODUCT_TYPE.OFFER,
          nameWithPublicGrading: getProductNameAndGrading(item,
            BuybackBidGradingEnum.BuybackBidGrading),
          name: item.bid.productName,
          grading: getProductGrading(BuybackBidGradingEnum.BuybackBidGrading, item.bid.grading),

          merchantId: item.bid.merchantId,
          merchantName: item.bid.merchantName,
          merchantCountry: merchantCountryByItem(item.bid.marketCode),
          warranty: '',

          merchantMigrationStarted: orderWithRelatedInfo?.merchantMigrationStarted,
        })),
      }
    );

    if (this.service instanceof BuybackOrderService) {
      const { order, orderItemsHistoryList, orderWithRelatedInfo } =
        await this.service.getOrder(orderId);
      return buybackOrderResponse(order, orderItemsHistoryList, orderWithRelatedInfo);
    }
    if (this.service instanceof MerchantBuybackOrderService) {
      const { order, orderItemsHistoryList, orderWithRelatedInfo } =
        await this.service.getOrder(orderId);
      return buybackOrderResponse(order, orderItemsHistoryList, orderWithRelatedInfo);
    }

    throw new Error('OrderServiceBridge::getOrder [error] - unknown service');
  }

  async getOrdersList(params) {
    if (this.service instanceof OrderService) {
      const data = await this.service.getOrders(params);
      return data;
    }

    const filters = {};
    if (params.order) {
      filters.id = {
        any_of: `${params.order}`,
      };
    }
    if (params.user_email) {
      filters.customerEmail = {
        any_of: `${params.user_email}`,
      };
    }

    const pagination = {
      limit: 10,
    };

    if (params?.startingAfter) {
      pagination.startingAfter = params.startingAfter;
    }

    if (params?.endingBefore) {
      pagination.endingBefore = params.endingBefore;
    }

    const getProductNameAndGrading = (item,
      gradingEnum) => `${item.bid.productName} | ${getEnumKeyFromValue(gradingEnum, item.bid.grading)}`;

    const getInvoiceName = (order) => {
      const { invoiceAddress } = order;

      if (!invoiceAddress) return '';
      return `${invoiceAddress.firstName || ''} ${invoiceAddress.familyName || ''}`.trim();
    };

    const ordersListResponse = ordersList => ordersList
      .map(({ order, hasTickets, merchantMigrationStarted }) => ({
        orderId: order.id,
        orderItemId: order.orderItemsList[0].id,
        userEmail: order.customerEmail,
        orderAt: formatDateTime(order.createdAt.seconds),

        orderStateId: order.orderItemsList[0].state,
        orderState: formatEnumKeyToSentence(getEnumKeyFromValue(
          BuybackOrderItemStateEnum.BuybackOrderItemState,
          order.orderItemsList[0].state,
        )),

        productName: getProductNameAndGrading(order.orderItemsList[0],
          BuybackBidGradingEnum.BuybackBidGrading),

        invoiceName: getInvoiceName(order),
        userId: order.customerId,
        hasTickets,
        merchantMigrationStarted,
      }));

    if (this.service instanceof BuybackOrderService) {
      const { ordersList } = await this.service.listBuybackOrders(
        {
          filters,
          pagination,
        },
      );

      if (!ordersList.length) {
        return [];
      }

      return ordersListResponse(ordersList);
    }
    if (this.service instanceof MerchantBuybackOrderService) {
      const { ordersList } = await this.service.listBuybackOrders(
        {
          filters,
          pagination,
        },
      );

      if (!ordersList.length) {
        return [];
      }

      return ordersListResponse(ordersList);
    }

    throw new Error('OrderServiceBridge::getOrdersList [error] - unknown service');
  }

  async getTicketTopics(orderItemId) {
    if (this.service instanceof OrderService) {
      return this.service.getTicketTopics(orderItemId);
    }
    if (this.service instanceof BuybackOrderService) {
      return this.service.getTicketTopics(orderItemId);
    }
    if (this.service instanceof MerchantBuybackOrderService) {
      return this.service.getTicketTopics(orderItemId);
    }

    throw new Error('OrderServiceBridge::getTicketTopics [error] - unknown service');
  }

  async getProducts(order, orderItemId) {
    if (this.service instanceof OrderService) {
      if (orderItemId) {
        const product = this.service.getOrderProductById(order, orderItemId);
        return product ? [product] : [];
      }
      return this.service.getOrderProducts(order);
    }
    if (this.service instanceof BuybackOrderService) {
      const products = await this.service.getProducts(order);
      return products || [];
    }
    if (this.service instanceof MerchantBuybackOrderService) {
      const products = await this.service.getProducts(order);
      return products || [];
    }

    throw new Error('OrderServiceBridge::getProducts [error] - unknown service');
  }

  async createTicket(params) {
    if (this.service instanceof OrderService) {
      return this.service.createTicket(params);
    }
    if (this.service instanceof BuybackOrderService) {
      return this.service.createTicket(params);
    }
    if (this.service instanceof MerchantBuybackOrderService) {
      return this.service.createTicket(params);
    }

    throw new Error('OrderServiceBridge::createTicket [error] - unknown service');
  }

  async addAttachmentToTicket(params) {
    if (this.service instanceof OrderService) {
      return this.service.addAttachmentToTicket(params);
    }
    if (this.service instanceof BuybackOrderService) {
      return this.service.addAttachmentToTicket(params);
    }
    if (this.service instanceof MerchantBuybackOrderService) {
      return this.service.addAttachmentToTicket(params);
    }

    throw new Error('OrderServiceBridge::addAttachmentToTicket [error] - unknown service');
  }
}

export { OrderServiceBridge };
