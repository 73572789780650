export const InputFile = () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.multiple = true;
  input.accept = '';

  let files = [];
  let formData = new FormData();
  let sizeLimit = 0;
  let filesAboveSizeLimit = [];

  let id = 0;
  let filesById = {};

  const setFilesOnChange = (e) => {
    filesAboveSizeLimit = [];

    const fileList = e.target.files;

    Array.from(fileList).forEach((i) => {
      if (sizeLimit && i.size > sizeLimit) {
        filesAboveSizeLimit.push(i);
      } else {
        id += 1;
        filesById[id] = {
          file: i,
          id,
        };
        files = Object.values(filesById);
      }
    });
  };
  const setFormData = () => {
    files.forEach((i) => {
      // formData.append('chatAttachment', files[i]);
      formData.append('file', i.file);
    });
  };
  const removeFile = (fileId) => {
    delete filesById[fileId];
    files = Object.values(filesById);
  };
  // const removeFile = fileIndex => files.splice(fileIndex, 1);
  const clearInput = () => {
    input.value = '';
  };

  return Object.freeze({
    setSizeLimit(sizeLimitBytes) {
      sizeLimit = sizeLimitBytes;
    },
    setAccept(accept) {
      input.accept = accept;
    },
    addFiles(callback) {
      input.click();
      input.onchange = (e) => {
        setFilesOnChange(e);
        setFormData();
        clearInput();

        callback({ files, formData, filesAboveSizeLimit });
      };
    },
    removeFile(fileIndex, callback) {
      removeFile(fileIndex);
      setFormData();

      callback({ files, formData });
    },
    reset() {
      filesById = {};
      files = [];
      formData = new FormData();
      filesAboveSizeLimit = [];
      clearInput();
    },
  });
};
