import {
  SET_PAGE_VISIBLE,
  SET_MERCHANT_LIST,
  SET_ASSIGNEE_LIST,
  SET_DOMAIN_LIST,
  SET_LAST_REFRESH_DATE_MERCHANT_LIST,
  SET_LAST_REFRESH_DATE_ASSIGNEE_LIST,
  SET_LAST_REFRESH_DATE_DOMAIN_LIST,
} from '@/store/mutationTypes';
import {
  getMerchants, getAssignees, getDomains,
} from '@/api';
import { cacheExpired } from '@/utils/date';
import {
  API_CACHE_TIME_DOMAINS,
  API_CACHE_TIME_MERCHANTS,
  API_CACHE_TIME_ASSIGNEES,
} from '@/constants';

export default {
  namespaced: true,
  state: {
    pageVisible: null,
    merchantList: [],
    assigneeList: [],
    domainList: [],
    merchantListLastRefresh: null,
    assigneeListLastRefresh: null,
    domainListLastRefresh: null,
  },
  getters: {
    assigneeListOrdered: ({ assigneeList }) => {
      if (assigneeList && Array.isArray(assigneeList)) {
        return assigneeList.map(({ email, id }) => ({ text: email, value: id }));
      }
      return [];
    },
  },
  actions: {
    resetGlobalState({ commit }) {
      commit(SET_MERCHANT_LIST, []);
      commit(SET_ASSIGNEE_LIST, []);
      commit(SET_DOMAIN_LIST, []);
      commit({
        type: SET_LAST_REFRESH_DATE_MERCHANT_LIST,
        date: null,
      });
      commit({
        type: SET_LAST_REFRESH_DATE_ASSIGNEE_LIST,
        date: null,
      });
      commit({
        type: SET_LAST_REFRESH_DATE_DOMAIN_LIST,
        date: null,
      });
    },
    async loadMerchants({ commit, state }) {
      if (cacheExpired(state.merchantListLastRefresh, API_CACHE_TIME_MERCHANTS)) {
        try {
          const { data } = await getMerchants();
          commit(SET_MERCHANT_LIST, data);

          commit({
            type: SET_LAST_REFRESH_DATE_MERCHANT_LIST,
            date: new Date(),
          });
        } catch (error) {
          throw new Error(`Error loading merchants: ${error}`);
        }
      } else {
        commit(SET_MERCHANT_LIST, state.merchantList);
      }
    },
    async loadAssignees({ commit, state }) {
      if (cacheExpired(state.assigneeListLastRefresh, API_CACHE_TIME_ASSIGNEES)) {
        try {
          const { data } = await getAssignees();
          commit(SET_ASSIGNEE_LIST, data);

          commit({
            type: SET_LAST_REFRESH_DATE_ASSIGNEE_LIST,
            date: new Date(),
          });
        } catch (error) {
          throw new Error(`Error loading assignee: ${error}`);
        }
      } else {
        commit(SET_ASSIGNEE_LIST, state.assigneeList);
      }
    },
    async loadDomains({ commit, state }) {
      if (cacheExpired(state.domainListLastRefresh, API_CACHE_TIME_DOMAINS)) {
        try {
          const { data } = await getDomains();
          commit(SET_DOMAIN_LIST, data);

          commit({
            type: SET_LAST_REFRESH_DATE_DOMAIN_LIST,
            date: new Date(),
          });
        } catch (error) {
          throw new Error(`Error loading domains: ${error}`);
        }
      } else {
        commit(SET_DOMAIN_LIST, state.domainList);
      }
    },
  },
  mutations: {
    [SET_PAGE_VISIBLE](state, status) {
      state.pageVisible = status;
    },
    [SET_MERCHANT_LIST](state, data) {
      state.merchantList = data;
    },
    [SET_ASSIGNEE_LIST](state, data) {
      state.assigneeList = data;
    },
    [SET_DOMAIN_LIST](state, data) {
      state.domainList = data;
    },
    [SET_LAST_REFRESH_DATE_MERCHANT_LIST](state, { date }) {
      state.merchantListLastRefresh = date;
    },
    [SET_LAST_REFRESH_DATE_ASSIGNEE_LIST](state, { date }) {
      state.assigneeListLastRefresh = date;
    },
    [SET_LAST_REFRESH_DATE_DOMAIN_LIST](state, { date }) {
      state.domainListLastRefresh = date;
    },
  },
};
