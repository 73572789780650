import * as Sentry from '@sentry/browser';
import { SET_TICKET_TOPICS } from '../../../mutationTypes';
import { getTicketTopics } from '../../../../api';

const loadTicketTopics = async ({ commit }) => {
  try {
    const ticketTopics = await getTicketTopics();
    commit(SET_TICKET_TOPICS, ticketTopics.data);
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default loadTicketTopics;
