import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import vuexI18n from 'vuex-i18n';
import filters from '@refb/shared/filters';
// import 'vue-material-design-icons/styles.css';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { isProd, isStaging, getEnv } from '@refb/shared/services/env';
import App from './App.vue';
import router from './router';
import store from './store/index';
import './assets/sass/main.scss';
import { sessionInit } from './services/auth.service';
import { plugin as i18nPlugin } from './services/i18n.service';

if (isProd() || isStaging()) {
  Sentry.init({
    dsn: 'https://1a624533bdfb408a9b917f7d4a5b34c6@sentry.refurbed.io/2',
    environment: getEnv(),
    integrations: [new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: true,
    })],
    ignoreErrors: [
      'Error: ResizeObserver loop limit exceeded',
      'ResizeObserver loop limit exceeded',
    ],
  });
}

Vue.use(BootstrapVue);
Vue.use(vuexI18n.plugin, store);
Vue.use(i18nPlugin, Vue.i18n);
Vue.use(filters);
sessionInit(store);

Vue.config.productionTip = false;
Vue.prototype.$filters = Vue.options.filters;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
