import { ENV } from '@refb/shared/constants';
import { getEnv } from '@refb/shared/services/env';
import {
  FEATURE_FLAG_EXAMPLE,
  FEATURE_FLAG_INVOICE_TYPE,
  FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS,
  FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS,
  FEATURE_FLAG_SHOW_BB_SIDE_BAR,
  FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS,
  FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID,
  FEATURE_FLAG_SHOW_DISCOUNTS,
  FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC,
  FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE,
} from '@/constants';

const featureFlags = {
  [ENV.DEVELOPMENT]: {
    [FEATURE_FLAG_EXAMPLE]: true,
    [FEATURE_FLAG_INVOICE_TYPE]: true,
    [FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS]: true,
    [FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS]: true,
    [FEATURE_FLAG_SHOW_BB_SIDE_BAR]: true,
    [FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS]: true,
    [FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID]: true,
    [FEATURE_FLAG_SHOW_DISCOUNTS]: true,
    [FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC]: true,
    [FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE]: true,
  },
  [ENV.LOCAL]: {
    [FEATURE_FLAG_EXAMPLE]: true,
    [FEATURE_FLAG_INVOICE_TYPE]: true,
    [FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS]: true,
    [FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS]: true,
    [FEATURE_FLAG_SHOW_BB_SIDE_BAR]: true,
    [FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS]: true,
    [FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID]: true,
    [FEATURE_FLAG_SHOW_DISCOUNTS]: true,
    [FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC]: true,
    [FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE]: true,
  },
  [ENV.STAGING]: {
    [FEATURE_FLAG_EXAMPLE]: true,
    [FEATURE_FLAG_INVOICE_TYPE]: true,
    [FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS]: true,
    [FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS]: true,
    [FEATURE_FLAG_SHOW_BB_SIDE_BAR]: true,
    [FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS]: true,
    [FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID]: true,
    [FEATURE_FLAG_SHOW_DISCOUNTS]: true,
    [FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC]: true,
    [FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE]: true,
  },
  [ENV.PREVIEW]: {
    [FEATURE_FLAG_EXAMPLE]: true,
    [FEATURE_FLAG_INVOICE_TYPE]: true,
    [FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS]: true,
    [FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS]: true,
    [FEATURE_FLAG_SHOW_BB_SIDE_BAR]: true,
    [FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS]: true,
    [FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID]: true,
    [FEATURE_FLAG_SHOW_DISCOUNTS]: true,
    [FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC]: true,
    [FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE]: true,
  },
  [ENV.QA]: {
    [FEATURE_FLAG_EXAMPLE]: true,
    [FEATURE_FLAG_INVOICE_TYPE]: true,
    [FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS]: true,
    [FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS]: true,
    [FEATURE_FLAG_SHOW_BB_SIDE_BAR]: true,
    [FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS]: true,
    [FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID]: true,
    [FEATURE_FLAG_SHOW_DISCOUNTS]: true,
    [FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC]: true,
    [FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE]: true,
  },
  [ENV.PRODUCTION]: {
    [FEATURE_FLAG_EXAMPLE]: false,
    [FEATURE_FLAG_INVOICE_TYPE]: true,
    [FEATURE_FLAG_SHOW_COUNTRY_ON_TICKET_DETAILS]: true,
    [FEATURE_FLAG_SHOW_PLACE_ORDER_DETAILS]: true,
    [FEATURE_FLAG_SHOW_BB_SIDE_BAR]: true,
    [FEATURE_FLAG_SHOW_BB_TICKET_COLUMNS]: true,
    [FEATURE_FLAG_SEARCH_BY_ORDER_ITEM_ID]: true,
    [FEATURE_FLAG_SHOW_DISCOUNTS]: true,
    [FEATURE_FLAG_SHOW_HELP_REQUEST_TOPIC]: true,
    [FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE]: true,
  },
};

// USAGE: isFeatureFlag([FEATURE_FLAG_EXAMPLE]) -> true/false
export const isFeatureFlag = featureName => featureFlags[getEnv()]?.[featureName] ?? false;
