import isMatch from 'lodash/isMatch';
import { isSSORequestUrl } from './auth.service';
import { tokenService } from './token.service';

const trailingSlashRE = /\/?$/;

export function routeGuard(to, from, next) {
  const isPublic = to.matched.some(route => route.meta.public);
  const onlyLoggedOut = to.matched.some(route => route.meta.onlyLoggedOut);
  const loggedIn = !!tokenService.getToken();
  const isSSOLoginFlow = isSSORequestUrl(to.fullPath);

  if (!isPublic && !loggedIn) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath },
      params: { logout: true },
    });
  }

  if (loggedIn && isSSOLoginFlow) {
    return next({
      name: 'logged-in-sso',
      query: { redirect: to.fullPath },
      params: { ssoForLoggedIn: true },
    });
  }

  // Prohibit logged in users to access the login page
  if (loggedIn && onlyLoggedOut) {
    return next('/');
  }

  return next();
}

export function queryIncludes(current, target) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in target) {
    if (!(key in current)) {
      return false;
    }
  }
  return true;
}

export function isIncludedRoute(current, target) {
  return (
    current.path.replace(trailingSlashRE, '/').indexOf(
      target.path.replace(trailingSlashRE, '/'),
    ) === 0 &&
    (!target.hash || current.hash === target.hash) &&
    queryIncludes(current.query, target.query)
  );
}

export function isExactRoute(routeA, routeB) {
  if (!routeA || !routeB) {
    return false;
  }

  if (routeA === routeB) {
    return true;
  } if (routeA.path && routeB.path) {
    return (
      routeA.path.replace(trailingSlashRE, '') === routeB.path.replace(trailingSlashRE, '') &&
      isMatch(routeA.params, routeB.params) &&
      isMatch(routeA.query, routeB.query)
    );
  }

  return false;
}
