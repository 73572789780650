import Cookies from 'js-cookie';

class GrpcAuthInterceptor {
  // eslint-disable-next-line class-methods-use-this
  async intercept(request, invoker) {
    const token = Cookies.get('token');

    if (token) {
      const reqMetadata = request.getMetadata();
      reqMetadata.Authorization = `Bearer ${token}`;
    }

    const response = await invoker(request);

    return response;
  }
}

export default GrpcAuthInterceptor;
