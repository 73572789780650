import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import { TICKET_TOPIC, PRODUCT_TYPE } from '../constants';

const TicketTopic = (name, description, value) => Object.freeze({
  name,
  description,
  value,
});

export const getTicketTopicTranslationKey = (fullTopicId) => {
  const topicMembers = fullTopicId.split('.');
  const lastMember = topicMembers[topicMembers.length - 1];
  return lastMember;
};

export const allTicketTopics = [
  TicketTopic('ticket_topic_delivery.other', 'ticket_topic_delivery_desc.other', TICKET_TOPIC.DELIVERY),
  TicketTopic('ticket_topic_delivery_status.other', 'ticket_topic_delivery_status_desc.other', TICKET_TOPIC.DELIVERY_STATUS),
  TicketTopic('ticket_topic_return.other', 'ticket_topic_return_desc.other', TICKET_TOPIC.RETURN),
  TicketTopic('ticket_topic_return_condition.other', 'ticket_topic_return_condition_desc.other', TICKET_TOPIC.RETURN_CONDITION),
  TicketTopic('ticket_topic_return_defect.other', 'ticket_topic_return_defect_desc.other', TICKET_TOPIC.RETURN_DEFECT),
  TicketTopic('ticket_topic_return_defect_battery.other', 'ticket_topic_return_defect_battery_desc.other', TICKET_TOPIC.RETURN_DEFECT_BATTERY),
  TicketTopic('ticket_topic_return_revocation.other', 'ticket_topic_return_revocation_desc.other', TICKET_TOPIC.RETURN_REVOCATION),
  TicketTopic('ticket_topic_warranty.other', 'ticket_topic_warranty_desc.other', TICKET_TOPIC.WARRANTY),
  TicketTopic('ticket_topic_payment.other', 'ticket_topic_payment_desc.other', TICKET_TOPIC.PAYMENT),
  TicketTopic('ticket_topic_payment_invoice.other', 'ticket_topic_payment_invoice_desc.other', TICKET_TOPIC.PAYMENT_INVOICE),
  TicketTopic('ticket_topic_product.other', 'ticket_topic_product_desc.other', TICKET_TOPIC.PRODUCT),
  TicketTopic('ticket_topic_other.other', '', TICKET_TOPIC.OTHER),
];
export const getOrderTicketTopicsValues = ({
  orderIsOlderThan30Days,
}) => {
  let topics = [];

  if (orderIsOlderThan30Days) {
    topics = [
      TICKET_TOPIC.WARRANTY,
      TICKET_TOPIC.PAYMENT,
      TICKET_TOPIC.PAYMENT_INVOICE,
      TICKET_TOPIC.PRODUCT,
      TICKET_TOPIC.OTHER,
    ];
  } else {
    topics = [
      TICKET_TOPIC.DELIVERY,
      TICKET_TOPIC.DELIVERY_STATUS,
      TICKET_TOPIC.RETURN_CONDITION,
      TICKET_TOPIC.RETURN_DEFECT,
      TICKET_TOPIC.RETURN_DEFECT_BATTERY,
      TICKET_TOPIC.RETURN_REVOCATION,
      TICKET_TOPIC.PAYMENT,
      TICKET_TOPIC.PAYMENT_INVOICE,
      TICKET_TOPIC.PRODUCT,
      TICKET_TOPIC.OTHER,
    ];
  }

  return topics;
};
export const getTicketTopicsValuesByProductType = (productType) => {
  switch (productType) {
    case PRODUCT_TYPE.OFFER: {
      return [
        TICKET_TOPIC.DELIVERY,
        TICKET_TOPIC.DELIVERY_STATUS,
        TICKET_TOPIC.RETURN_CONDITION,
        TICKET_TOPIC.RETURN_DEFECT,
        TICKET_TOPIC.RETURN_DEFECT_BATTERY,
        TICKET_TOPIC.RETURN_REVOCATION,
        TICKET_TOPIC.WARRANTY,
        TICKET_TOPIC.PAYMENT,
        TICKET_TOPIC.PAYMENT_INVOICE,
        TICKET_TOPIC.PRODUCT,
        TICKET_TOPIC.OTHER,
      ];
    }
    case PRODUCT_TYPE.ADDON: {
      return [
        TICKET_TOPIC.DELIVERY,
        TICKET_TOPIC.DELIVERY_STATUS,
        TICKET_TOPIC.RETURN_DEFECT,
        TICKET_TOPIC.RETURN_REVOCATION,
        TICKET_TOPIC.WARRANTY,
        TICKET_TOPIC.PAYMENT,
        TICKET_TOPIC.PAYMENT_INVOICE,
        TICKET_TOPIC.PRODUCT,
        TICKET_TOPIC.OTHER,
      ];
    }
    default: {
      return [];
    }
  }
};
export const getSpecificTicketTopics = ({
  orderIsOlderThan30Days,
  productType,
}) => {
  let topics = allTicketTopics;
  const filterTopics = (topicsValues = []) => {
    topics = topics.filter(i => topicsValues.includes(i.value));
  };

  if (isBoolean(orderIsOlderThan30Days)) {
    filterTopics(getOrderTicketTopicsValues({
      orderIsOlderThan30Days,
    }));
  }

  if (isString(productType) && productType) {
    filterTopics(getTicketTopicsValuesByProductType(
      productType,
    ));
  }

  return topics;
};
