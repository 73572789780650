<template>
  <router-view v-if="isReady" />
</template>

<script>
import {
  mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';
import { USER_ROLE } from './constants';
import { VisibilityService } from './services/VisibilityService';
import { SET_PAGE_VISIBLE } from './store/mutationTypes';

export default {
  name: 'ManagementInterface',

  data() {
    return {
      isLoading: false,
      visibilityService: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['loggedIn', 'isSSOLoginFlow']),
    ...mapState('user', ['role']),

    isReady() {
      if (this.$route.name === 'login') {
        return true;
      }
      if (this.isSSOLoginFlow) {
        return false;
      }
      return this.isLoading === false;
    },
  },

  watch: {
    async loggedIn(to, from) {
      if (to === true && from === false) {
        await this.whoami();
      }
      if (to === true) {
        this.loadTicketTopics();
      }
    },
    async role(to) {
      if (to === USER_ROLE.ADMIN || to === USER_ROLE.MERCHANT) {
        await this.setRoutes();
        await this.registerStoreModules();
        this.isLoading = false;

        const { redirect } = this.$router.history.current.query;

        if (redirect) {
          this.$router
            .push(redirect || '')
            .catch(error => error);
        }
      } else {
        this.suspendSession();
      }
    },
  },

  async created() {
    const handleVisibilityChange = ({ hidden }) => {
      this[SET_PAGE_VISIBLE](!hidden);
    };

    this.visibilityService = new VisibilityService(handleVisibilityChange);
    this[SET_PAGE_VISIBLE](true);

    if (this.loggedIn === true) {
      await this.whoami();
      this.loadTicketTopics();
    }
    this.suspendSession();
  },

  beforeMount() {
    if (this.loggedIn === false) {
      this.isLoading = true;
    }
  },

  beforeDestroy() {
    this.visibilityService.cleanup();
  },

  methods: {
    ...mapMutations('global', [SET_PAGE_VISIBLE]),
    ...mapActions('user', ['whoami']),
    ...mapActions('auth', ['logoutWithErrorMessage']),
    ...mapActions('tickets', ['loadTicketTopics']),
    async setRoutes() {
      let routeModule;

      if (this.role === USER_ROLE.ADMIN) {
        routeModule = await import(/* webpackChunkName: "adm_routes" */ './routes/admin.routes');
      } else if (this.role === USER_ROLE.MERCHANT) {
        routeModule = await import(/* webpackChunkName: "merch_routes" */ './routes/merchant.routes');
      }

      this.$router.addRoutes(routeModule.default);
    },

    async registerStoreModules() {
      let registerModules;

      if (this.role === USER_ROLE.ADMIN) {
        ({ registerModules } = await import(/* webpackChunkName: "adm_app" */ './store/adminModules'));
      } else if (this.role === USER_ROLE.MERCHANT) {
        ({ registerModules } = await import(/* webpackChunkName: "merch_store" */ './store/merchModules'));
      }

      try {
        registerModules();
      } catch (e) {
        console.error(e);
      }
    },

    suspendSession() {
      if (this.role === USER_ROLE.CUSTOMER) {
        this.logoutWithErrorMessage('Access denied');
      }
    },
  },
};
</script>
