import { getEnv } from '@refb/shared/services/env';
import { ENV } from '@refb/shared/constants';

class Logger {
  constructor(environment) {
    this.environment = environment;
    this.info('LOGGER ENV', environment);
  }

  isLoggerEnabled() {
    return this.environment === ENV.LOCAL || this.environment === ENV.DEVELOPMENT;
  }

  info(...args) {
    if (this.isLoggerEnabled()) {
      console.log(...args);
    }
  }

  debug(...args) {
    if (this.isLoggerEnabled()) {
      console.trace(...args);
    }
  }

  error(...args) {
    if (this.isLoggerEnabled()) {
      console.error(...args);
    }
  }
}

export default new Logger(getEnv());
