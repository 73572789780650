
// TODO: API provides an expiration date, that should be considered
//       during the login process too.

const TOKEN_KEY = 'accessToken';
// const TOKEN_EXPIRE_KEY = 'tokenExpire';

function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

function saveToken(accessToken) {
  const date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));

  document.cookie = `token=${accessToken};expires=${date.toUTCString()};path=/;`;

  return localStorage.setItem(TOKEN_KEY, accessToken);
}

function removeToken() {
  document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  localStorage.removeItem(TOKEN_KEY);
}


export const tokenService = {
  getToken,
  saveToken,
  removeToken,
};
