import capitalize from 'lodash/capitalize';
import {
  SET_MESSAGE_TEMPLATE,
  SET_MESSAGE_TEMPLATE_CONTENT,
  SET_MESSAGE_TEMPLATES_LANGUAGE,
} from '@/store/mutationTypes';

export default {
  namespaced: true,

  state() {
    return {
      messageTemplatesLanguage: null,
      messageTemplate: null,
      messageTemplateContent: '',
    };
  },

  getters: {

  },

  actions: {
    setMessageTemplatesLanguage({ commit }, language) {
      commit(SET_MESSAGE_TEMPLATES_LANGUAGE, language);
    },
    setMessageTemplate({ commit }, messageTemplate) {
      commit(SET_MESSAGE_TEMPLATE, messageTemplate);
    },
    setMessageTemplateContent({ commit }, payload) {
      const language = capitalize(payload.messageTemplatesLanguage);

      try {
        const content = payload.messageTemplate[`content${language}`];

        commit(SET_MESSAGE_TEMPLATE_CONTENT, content);
      } catch {
        commit(SET_MESSAGE_TEMPLATE_CONTENT, '');
      }
    },
  },

  mutations: {
    [SET_MESSAGE_TEMPLATES_LANGUAGE](state, language) {
      state.messageTemplatesLanguage = language;
    },
    [SET_MESSAGE_TEMPLATE](state, messageTemplate) {
      state.messageTemplate = messageTemplate;
    },
    [SET_MESSAGE_TEMPLATE_CONTENT](state, messageTemplateContent) {
      state.messageTemplateContent = messageTemplateContent;
    },
  },
};
