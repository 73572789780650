import merge from 'lodash/merge';
import axios, { CancelToken } from 'axios';
import qs from 'qs';
import { tokenService } from './token.service';
import { objectKeysToSnakeCase, objectKeysToCamelCase } from './object';

export function prepareURI(route) {
  if (!/[?&]/.test(route)) {
    return encodeURI(route.replace(/\/*$/, '/'));
  }

  return route;
}

function requestInterceptor(config) {
  return {
    ...config,
    params: objectKeysToSnakeCase(config.params),
    data: objectKeysToSnakeCase(config.data),
  };
}

function responseInterceptor(response) {
  return {
    ...response,
    data: objectKeysToCamelCase(response.data),
  };
}

export class HttpService {
  #interceptors = null

  #axios = null

  #axiosCancelToken = null

  constructor(baseUrl, {
    convertKeys = false,
    uniformResponse = false,
  }) {
    const service = axios.create({});

    this.interceptors = {};
    this.uniformResponse = uniformResponse;
    this.convertKeys = convertKeys;

    service.defaults.baseURL = baseUrl;

    if (this.convertKeys) {
      service.interceptors.request.use(requestInterceptor);
    }

    service.interceptors.response.use(
      response => this.handleSuccess(response),
      error => Promise.reject(this.handleError(error)),
    );

    this.axios = service;
    this.axiosCancelToken = CancelToken;
  }

  requestInterceptor(config) {
    return this.convertKeys ? requestInterceptor(config) : config;
  }

  formatResponse(response) {
    return this.convertKeys ?
      responseInterceptor(response) :
      response;
  }

  handleSuccess(response) {
    const responseBody = this.uniformResponse ?
      { data: response.data, error: null } :
      response;

    return this.formatResponse(responseBody);
  }

  handleError(error) {
    const responseBody = this.uniformResponse ?
      { data: null, error } :
      error;

    return this.formatResponse(responseBody);
  }


  setUrl(url) {
    this.axios.defaults.baseURL = url;
  }

  getDefaults() {
    return this.axios.defaults;
  }

  setHeader() {
    const token = tokenService.getToken();
    if (token) {
      this.getDefaults().headers.common.Authorization = `Bearer ${tokenService.getToken()}`;
    }
  }

  removeHeader() {
    this.axios.defaults.headers.common = {};
  }

  get(resource, params, { cancelToken } = {}) {
    return this.axios.get(prepareURI(resource), {
      params,
      paramsSerializer: _params => qs.stringify(_params, { arrayFormat: 'repeat' }),
      cancelToken,
    });
  }

  post(resource, data) {
    return this.axios.post(prepareURI(resource), data);
  }

  put(resource, data) {
    return this.axios.put(prepareURI(resource), data);
  }

  delete(resource) {
    return this.axios.delete(prepareURI(resource));
  }

  setRequestInterceptor({ interceptor = config => config }) {
    this.axios.interceptors.request.use(interceptor);
  }

  mountErrorStatusInterceptor({
    interceptor = response => response,
    errorHandler,
  }, status = 401) {
    this.interceptors[status] = this.axios.interceptors.response.use(interceptor, errorHandler);
  }

  unmountErrorStatusInterceptor(status = 401) {
    this.axios.interceptors.response.eject(this.interceptors[status]);
  }

  /**
   * Custom API request
   *
   * @param {*} config
   */
  request(config) {
    this.axios.request(config);
  }
}

export const mergeResponses = (response) => {
  if (Array.isArray(response)) {
    return response.reduce((prev, res) => merge(prev, mergeResponses(res)), {});
  }

  return response;
};

export const validateResponse = response => (response.errors && response.errors.length !== 0);

export class HttpError extends Error {
  #errors = []

  constructor(err) {
    const errors = Array.isArray(err) ?
      err :
      [err];

    super(errors.join());

    this.name = 'HttpError';
    this.errors.concat(errors);
  }

  getErrors() {
    return this.errors;
  }
}
