export const formatDateTime = (
  seconds,
  locale = 'en-GB',
  options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  },
) => new Intl.DateTimeFormat(locale, options).format(new Date(seconds * 1000));

export const subSeconds = (date, secondsToSubtract) => {
  const newDate = new Date(date);
  newDate.setSeconds(date.getSeconds() - secondsToSubtract);
  return newDate;
};

export const cacheExpired = (lastRefreshTime, cacheTimeConstant) => {
  const now = new Date();
  const comparedDate = subSeconds(now, cacheTimeConstant);
  return lastRefreshTime === null || comparedDate > lastRefreshTime;
};
