import get from 'lodash/get';

const isUrl = url => Boolean(url.match(/^https?:\/\//));

let windowApiBaseUrl = get(window, 'managementAppOpts.apiBaseURL', '');
windowApiBaseUrl = isUrl(windowApiBaseUrl) ? windowApiBaseUrl : '';

let windowGrpcApiBaseUrl = get(window, 'managementAppOpts.grpcApiBaseURL', '');
windowGrpcApiBaseUrl = isUrl(windowGrpcApiBaseUrl) ? windowGrpcApiBaseUrl : '';

// export const API_URL = windowApiBaseUrl || process.env.VUE_APP_API_URL || 'http://localhost:8080';
export const API_URL = windowApiBaseUrl || process.env.VUE_APP_API_URL || 'https://api-preview.refurbed.io';
export const API_VERSION = process.env.VUE_APP_API_VERSION || 'v1';
export const API_GRPC_URL = windowGrpcApiBaseUrl || process.env.VUE_APP_API_GRPC_URL || 'https://api-preview.refurbed.com';

console.log('Config::managementAppOpts [windowApiBaseUrl]', windowApiBaseUrl);
console.log('Config::managementAppOpts [windowGrpcApiBaseUrl]', windowGrpcApiBaseUrl);
