const EVENT_NAME = 'visibilitychange';

class VisibilityService {
  constructor(visibilityChangeCallback) {
    this.visibilityChangeCallback = visibilityChangeCallback;
    this.initialize();
  }

  initialize() {
    this.visibilityChangeHandler = this.visibilityChange.bind(this);
    document.addEventListener(EVENT_NAME, this.visibilityChangeHandler, false);
  }

  cleanup() {
    document.removeEventListener(EVENT_NAME, this.visibilityChangeHandler);
  }

  visibilityChange() {
    this.visibilityChangeCallback({ hidden: document.hidden });
  }
}

export { VisibilityService };
