const requestStatus = Object.freeze({
  isLoading: false,
  isSuccess: false,
  isFailure: false,
});
export const getRequestStatus = (status = '') => {
  if (status && !Object.keys(requestStatus).includes(status)) {
    throw new Error('Invalid status value!');
  }

  if (!status) {
    return {
      ...requestStatus,
    };
  }

  return {
    ...requestStatus,
    [status]: true,
  };
};
