// source: refb/refurbed/core/v1/services/auth_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var refb_refurbed_core_v1_enums_auth_pb = require('../../../../../refb/refurbed/core/v1/enums/auth_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_enums_auth_pb);
goog.exportSymbol('proto.refb.refurbed.core.v1.GenerateAccessKeyRequest', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.AuthenticationCase', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.GenerateAccessKeyResponse', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.SendOneTimePasswordRequest', null, global);
goog.exportSymbol('proto.refb.refurbed.core.v1.SendOneTimePasswordResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.oneofGroups_);
};
goog.inherits(proto.refb.refurbed.core.v1.GenerateAccessKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.displayName = 'proto.refb.refurbed.core.v1.GenerateAccessKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.displayName = 'proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.displayName = 'proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.core.v1.GenerateAccessKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.displayName = 'proto.refb.refurbed.core.v1.GenerateAccessKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.core.v1.SendOneTimePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.displayName = 'proto.refb.refurbed.core.v1.SendOneTimePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.core.v1.SendOneTimePasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.displayName = 'proto.refb.refurbed.core.v1.SendOneTimePasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.displayName = 'proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.displayName = 'proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.AuthenticationCase = {
  AUTHENTICATION_NOT_SET: 0,
  PASS: 4,
  OTP: 5
};

/**
 * @return {proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.AuthenticationCase}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.getAuthenticationCase = function() {
  return /** @type {proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.AuthenticationCase} */(jspb.Message.computeOneofCase(this, proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pass: (f = msg.getPass()) && proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.toObject(includeInstance, f),
    otp: (f = msg.getOtp()) && proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.GenerateAccessKeyRequest;
  return proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = new proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication;
      reader.readMessage(value,proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.deserializeBinaryFromReader);
      msg.setPass(value);
      break;
    case 5:
      var value = new proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication;
      reader.readMessage(value,proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.deserializeBinaryFromReader);
      msg.setOtp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPass();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.serializeBinaryToWriter
    );
  }
  f = message.getOtp();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    token: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication;
  return proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addScopes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string scopes = 4;
 * @return {!Array<string>}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.getScopesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.setScopesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.addScopes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oneTimePassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    token: jspb.Message.getFieldWithDefault(msg, 3, ""),
    scopesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication;
  return proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOneTimePassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addScopes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOneTimePassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string one_time_password = 2;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.getOneTimePassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.setOneTimePassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string scopes = 4;
 * @return {!Array<string>}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.getScopesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.setScopesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.addScopes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PasswordAuthentication pass = 4;
 * @return {?proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.getPass = function() {
  return /** @type{?proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication, 4));
};


/**
 * @param {?proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.PasswordAuthentication|undefined} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} returns this
*/
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.setPass = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.clearPass = function() {
  return this.setPass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.hasPass = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OneTimePasswordAuthentication otp = 5;
 * @return {?proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.getOtp = function() {
  return /** @type{?proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication, 5));
};


/**
 * @param {?proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.OneTimePasswordAuthentication|undefined} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} returns this
*/
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.setOtp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyRequest} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.clearOtp = function() {
  return this.setOtp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyRequest.prototype.hasOtp = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requiresSecondaryAuthentication: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyResponse}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.GenerateAccessKeyResponse;
  return proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyResponse}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessKey(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequiresSecondaryAuthentication(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.GenerateAccessKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequiresSecondaryAuthentication();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string access_key = 1;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.prototype.getAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyResponse} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool requires_secondary_authentication = 2;
 * @return {boolean}
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.prototype.getRequiresSecondaryAuthentication = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.refb.refurbed.core.v1.GenerateAccessKeyResponse} returns this
 */
proto.refb.refurbed.core.v1.GenerateAccessKeyResponse.prototype.setRequiresSecondaryAuthentication = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.SendOneTimePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.SendOneTimePasswordRequest}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.SendOneTimePasswordRequest;
  return proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.SendOneTimePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.SendOneTimePasswordRequest}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.SendOneTimePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.SendOneTimePasswordRequest} returns this
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.SendOneTimePasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.SendOneTimePasswordResponse}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.SendOneTimePasswordResponse;
  return proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.SendOneTimePasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.SendOneTimePasswordResponse}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.SendOneTimePasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.SendOneTimePasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest;
  return proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string request_url = 1;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.prototype.getRequestUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest} returns this
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionRequest.prototype.setRequestUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    assertionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assertion: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse;
  return proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.refb.refurbed.core.v1.SSOAssertionTypeEnum.SSOAssertionType} */ (reader.readEnum());
      msg.setAssertionType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssertion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssertionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAssertion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional SSOAssertionTypeEnum.SSOAssertionType assertion_type = 1;
 * @return {!proto.refb.refurbed.core.v1.SSOAssertionTypeEnum.SSOAssertionType}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.prototype.getAssertionType = function() {
  return /** @type {!proto.refb.refurbed.core.v1.SSOAssertionTypeEnum.SSOAssertionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.SSOAssertionTypeEnum.SSOAssertionType} value
 * @return {!proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse} returns this
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.prototype.setAssertionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string assertion = 2;
 * @return {string}
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.prototype.getAssertion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse} returns this
 */
proto.refb.refurbed.core.v1.GenerateSSOAssertionResponse.prototype.setAssertion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.refb.refurbed.core.v1);
