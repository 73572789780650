import { apiService } from './services/api.service';
import { merchantMessageTemplates, getMerchantTemplate } from './utils/merchantMessageTemplates';
import { AUDIENCE } from './constants';

// User requests
export const user = {
  whoami: () => apiService.get('users/id/me'),
};

export const getUsers = (params = {}) => apiService.get('users', params);
export const getOrders = (params = {}) => apiService.get('orders', params);
export const getSearchedOrders = (params = {}) => apiService.get('orders/search', params);
export const getTicketTopics = () => apiService.get('/ticket-topics/');
export const getTicketTopicsByOrderItem = orderItemId => apiService.get(`/orders/items/id/${orderItemId}/ticket-topics/`);
export const getTicketTopicsByBuybackOrderItem = orderItemId => apiService.get(`/buyback-orders/items/id/${orderItemId}/ticket-topics/`);

// Ticket requests
export const ticket = {
  getTickets: (params = {}) => apiService.get('tickets', params),
  getStats: (params = {}) => apiService.get('tickets/statistics', params),
  getHelpRequestReasons: () => apiService.get('tickets/help-request-reasons'),
};

export const byTicketId = ticketId => (res => ({
  getDetails: ({ cancelToken }) => apiService.get(res, {}, { cancelToken }),
  putUpdates: changes => apiService.put(res, changes),
  postAction: endpoint => apiService.post(`${res}/${endpoint}`),
  deleteAction: endpoint => apiService.delete(`${res}/${endpoint}`),
  postMessage: (messageObj, containsReturnLink) => {
    const returnLinkQueryParam = containsReturnLink ? '/?contains_link=true' : '';

    return apiService.post(`${res}/messages${returnLinkQueryParam}`, messageObj);
  },
  postAttachment: function postAttachment({ audience = AUDIENCE.CUSTOMER, attachment }) {
    return apiService.post(`${res}/attachments/?audience=${audience}&`, attachment);
  },
  getAssigned: () => apiService.get(`${res}/assignee`),
  getLogs: ({ cancelToken }) => apiService.get(`${res}/logs`, {}, { cancelToken }),
}))(`tickets/id/${ticketId}`);

export const getDomains = () => apiService.get('rti/domains');
export const getAssignees = () => apiService.get('rti/assignees');
export const getMerchants = () => apiService.get('rti/merchants');

// Order details
export const order = {};

export const byOrderId = orderId => (res => ({
  getTickets: filter => apiService.get(`${res}/tickets`, filter),
  getDetails: filter => apiService.get(res, filter),
}))(`orders/id/${orderId}`);

export const postCreateTicket = ({
  productId, topicValue, messageText, audience,
}) => {
  const url = `orders/item/${productId}/tickets/`;
  const data = {
    topic: topicValue,
    message: {
      message: messageText,
      audience,
    },
  };

  return apiService.post(url, data);
};

export const postBuybackCreateTicket = ({
  productId, topicValue, messageText, audience,
}) => {
  const url = `buyback-orders/item/${productId}/tickets/`;
  const data = {
    topic: topicValue,
    message: {
      message: messageText,
      audience,
    },
  };

  return apiService.post(url, data);
};

export const getMessageTemplates = (params = {}) => apiService.get('chat-templates', {
  tree: true,
  ...params,
});
export const getMerchantMessageTemplates = (language) => {
  // TODO: Implement API call, but before that we should use `language` to filter the correct data
  const response = {
    data: merchantMessageTemplates,
    language,
  };
  return new Promise(resolve => resolve(response));
};
export const getMessageTemplate = ({ templateName }) => apiService.get(`chat-templates/name/${templateName}`);
export const getMerchantMessageTemplate = ({ templateName }) => {
  const response = {
    data: getMerchantTemplate(templateName),
  };

  return new Promise(resolve => resolve(response));
};
