import { QUERY_PARAMETER_KEY, ORDER_SEARCH_QUERY_PARAM_KEY } from '@/constants';
import { getRequestStatus } from '@refb/shared/services/requestStatus';
import {
  SET_ORDER_SEARCH_ORDERS,
  SET_ORDER_SEARCH_GET_ORDERS_REQUEST_STATUS,
  SET_ORDER_SEARCH_LIMIT,
  SET_ORDER_SEARCH_OFFSET,
  SET_ORDER_SEARCH_PAGINATION_STARTING_AFTER,
  SET_ORDER_SEARCH_PAGINATION_ENDING_BEFORE,
  SET_ORDER_SEARCH_HAS_MORE_ORDERS,
  SET_ORDER_SEARCH_TYPE,
  SET_ORDER_SEARCH_USER_ID,
  SET_ORDER_SEARCH_INVOICE_NAME,
  SET_ORDER_SEARCH_USER_EMAIL,
  SET_ORDER_SEARCH_ORDER_ID,
  SET_ORDER_SEARCH_ORDER_ITEM_ID,
} from '@/store/mutationTypes';
import { ORDER_TYPE } from '@/utils/order';
import {
  OrderServiceBridge,
  ORDER_SERVICES,
} from '@/services/OrderServiceBridge';

export default {
  namespaced: true,

  state: {
    orders: [],
    getOrdersRequestStatus: getRequestStatus(),
    limit: 8,
    offset: 0,
    hasMoreOrders: false,
    userId: 0,
    invoiceName: '',
    userEmail: '',
    orderId: 0,
    orderItemId: 0,
    type: ORDER_TYPE.MARKETPLACE,
    pagination: {
      startingAfter: null,
      endingBefore: null,
    },
  },

  getters: {
    getGetOrdersQueryParams(state) {
      return {
        [QUERY_PARAMETER_KEY.LIMIT]: state.limit || undefined,
        [QUERY_PARAMETER_KEY.OFFSET]: state.offset || undefined,
        [QUERY_PARAMETER_KEY.STARTING_AFTER]: state.pagination.startingAfter || undefined,
        [QUERY_PARAMETER_KEY.ENDING_BEFORE]: state.pagination.endingBefore || undefined,
        [ORDER_SEARCH_QUERY_PARAM_KEY.USER_ID]: state.userId || undefined,
        [ORDER_SEARCH_QUERY_PARAM_KEY.INVOICE_NAME]: state.invoiceName || undefined,
        [ORDER_SEARCH_QUERY_PARAM_KEY.USER_EMAIL]: state.userEmail || undefined,
        [ORDER_SEARCH_QUERY_PARAM_KEY.ORDER_ID]: state.orderId || undefined,
        [ORDER_SEARCH_QUERY_PARAM_KEY.ORDER_ITEM_ID]: state.orderItemId || undefined,
      };
    },
    isNextDisabled(state) {
      return !state.hasMoreOrders;
    },
    isPreviousDisabled(state) {
      return !state.offset;
    },
  },

  actions: {
    setOrders({ commit }, orders) {
      commit(SET_ORDER_SEARCH_ORDERS, orders);
    },
    setGetOrdersRequestStatus({ commit }, status) {
      commit(SET_ORDER_SEARCH_GET_ORDERS_REQUEST_STATUS, getRequestStatus(status));
    },
    setLimit({ commit }, limit) {
      commit(SET_ORDER_SEARCH_LIMIT, limit);
    },
    setOffset({ commit }, offset) {
      commit(SET_ORDER_SEARCH_OFFSET, offset);
    },
    setPaginationStartingAfter({ commit }, startingAfter) {
      commit(SET_ORDER_SEARCH_PAGINATION_STARTING_AFTER, startingAfter);
    },
    setPaginationEndingBefore({ commit }, endingBefore) {
      commit(SET_ORDER_SEARCH_PAGINATION_ENDING_BEFORE, endingBefore);
    },
    setOrderSearchType({ commit }, type) {
      commit(SET_ORDER_SEARCH_TYPE, type);
    },
    setOrderSearchUserId({ commit }, userId) {
      commit(SET_ORDER_SEARCH_USER_ID, userId);
    },
    setOrderSearchInvoiceName({ commit }, invoiceName) {
      commit(SET_ORDER_SEARCH_INVOICE_NAME, invoiceName);
    },
    setOrderSearchUserEmail({ commit }, userEmail) {
      commit(SET_ORDER_SEARCH_USER_EMAIL, userEmail);
    },
    setOrderSearchOrderId({ commit }, orderId) {
      commit(SET_ORDER_SEARCH_ORDER_ID, orderId);
    },
    setOrderSearchOrderItemId({ commit }, orderItemId) {
      commit(SET_ORDER_SEARCH_ORDER_ITEM_ID, orderItemId);
    },
    increaseOffset({ state, dispatch }) {
      dispatch('setOffset', state.offset + state.limit);
    },
    decreaseOffset({ state, dispatch }) {
      if (state.offset >= state.limit) {
        dispatch('setOffset', state.offset - state.limit);
      } else {
        dispatch('setOffset', 0);
      }
    },
    resetOffset({ dispatch }) {
      dispatch('setOffset', 0);
    },
    setHasMoreOrders({ commit }, hasMoreOrders) {
      commit(SET_ORDER_SEARCH_HAS_MORE_ORDERS, hasMoreOrders);
    },
    async getNextOrders({ state, dispatch, getters }, { isInitialGet, role }) {
      dispatch('setGetOrdersRequestStatus', 'isLoading');

      if (isInitialGet) {
        dispatch('resetOffset');
        dispatch('setPaginationStartingAfter', null);
      } else {
        dispatch('increaseOffset');

        const lastOrder = state.orders[state.orders.length - 1];
        dispatch('setPaginationStartingAfter', lastOrder.orderId);
      }
      dispatch('setPaginationEndingBefore', null);

      try {
        const services = {
          [ORDER_TYPE.MARKETPLACE]: ORDER_SERVICES.ORDER_SERVICE,
          [ORDER_TYPE.BUYBACK]: ORDER_SERVICES.BUYBACK_ORDER_SERVICE,
        };
        const service = services[state.type] || services[ORDER_TYPE.MARKETPLACE];

        const orderService = new OrderServiceBridge(service, role);

        const params = getters.getGetOrdersQueryParams;
        const response = await orderService.getOrdersList(params);

        if (!response.length) {
          if (!isInitialGet) {
            dispatch('decreaseOffset');
          } else {
            dispatch('setOrders', []);
          }

          dispatch('setHasMoreOrders', false);
          dispatch('setPaginationStartingAfter', null);
        } else {
          dispatch('setOrders', response);
          dispatch('setHasMoreOrders', true);
        }

        dispatch('setGetOrdersRequestStatus', 'isSuccess');
      } catch (error) {
        dispatch('setGetOrdersRequestStatus', 'isFailure');
      }
    },
    async getPreviousOrders({ state, getters, dispatch }, { role }) {
      dispatch('setGetOrdersRequestStatus', 'isLoading');
      dispatch('decreaseOffset');
      dispatch('setPaginationStartingAfter', null);

      const firstOrder = state.orders[0];
      dispatch('setPaginationEndingBefore', firstOrder.orderId);

      if (!state.hasMoreOrders) {
        dispatch('setHasMoreOrders', true);
      }

      try {
        const services = {
          [ORDER_TYPE.MARKETPLACE]: ORDER_SERVICES.ORDER_SERVICE,
          [ORDER_TYPE.BUYBACK]: ORDER_SERVICES.BUYBACK_ORDER_SERVICE,
        };
        const service = services[state.type] || services[ORDER_TYPE.MARKETPLACE];

        const orderService = new OrderServiceBridge(service, role);

        const params = getters.getGetOrdersQueryParams;
        const response = await orderService.getOrdersList(params);

        if (!response.length) {
          dispatch('setPaginationEndingBefore', null);
        }

        dispatch('setOrders', response);
        dispatch('setGetOrdersRequestStatus', 'isSuccess');
      } catch (error) {
        dispatch('setGetOrdersRequestStatus', 'isFailure');
      }
    },
    resetOrderSearch({ dispatch }) {
      dispatch('setOrders', []);
      dispatch('setGetOrdersRequestStatus', '');
      dispatch('resetOffset');
      dispatch('setHasMoreOrders', false);
      dispatch('setOrderSearchUserId', 0);
      dispatch('setOrderSearchInvoiceName', '');
      dispatch('setOrderSearchUserEmail', '');
      dispatch('setOrderSearchOrderId', 0);
      dispatch('setPaginationStartingAfter', null);
      dispatch('setPaginationEndingBefore', null);
    },
  },

  mutations: {
    [SET_ORDER_SEARCH_ORDERS](state, orders) {
      state.orders = orders;
    },
    [SET_ORDER_SEARCH_GET_ORDERS_REQUEST_STATUS](state, requestStatus) {
      state.getOrdersRequestStatus = requestStatus;
    },
    [SET_ORDER_SEARCH_LIMIT](state, limit) {
      state.limit = limit;
    },
    [SET_ORDER_SEARCH_OFFSET](state, offset) {
      state.offset = offset;
    },
    [SET_ORDER_SEARCH_PAGINATION_STARTING_AFTER](state, startingAfter) {
      state.pagination.startingAfter = startingAfter;
    },
    [SET_ORDER_SEARCH_PAGINATION_ENDING_BEFORE](state, endingBefore) {
      state.pagination.endingBefore = endingBefore;
    },
    [SET_ORDER_SEARCH_HAS_MORE_ORDERS](state, hasMoreOrders) {
      state.hasMoreOrders = hasMoreOrders;
    },
    [SET_ORDER_SEARCH_USER_ID](state, userId) {
      state.userId = userId;
    },
    [SET_ORDER_SEARCH_TYPE](state, type) {
      state.type = type;
    },
    [SET_ORDER_SEARCH_INVOICE_NAME](state, invoiceName) {
      state.invoiceName = invoiceName;
    },
    [SET_ORDER_SEARCH_USER_EMAIL](state, userEmail) {
      state.userEmail = userEmail;
    },
    [SET_ORDER_SEARCH_ORDER_ID](state, orderId) {
      state.orderId = orderId;
    },
    [SET_ORDER_SEARCH_ORDER_ITEM_ID](state, orderItemId) {
      state.orderItemId = orderItemId;
    },
  },
};
