import { get } from 'lodash';
import union from 'lodash/union';
import difference from 'lodash/difference';
import {
  TICKET_STATUS,
  PANIC_MODE,
  TICKETS_TREESELECT_FILTER_CONTEXT,
  TICKETS_TREESELECT_FILTER_UPDATE_TYPE,
} from '@/constants';
import {
  SET_TICKETS_TREESELECT_FILTER_EXPEDITE,
  SET_TICKETS_TREESELECT_FILTER_UNANSWERED,
  SET_TICKETS_TREESELECT_FILTER_UNASSIGNED,
  SET_TICKETS_TREESELECT_FILTER_DOMAINS,
  SET_TICKETS_TREESELECT_FILTER_TOPICS,
  SET_TICKETS_TREESELECT_FILTER_MERCHANTS,
  SET_TICKETS_TREESELECT_FILTER_ASSIGNED_TO,
  SET_TICKETS_TREESELECT_FILTER_VALUE,
  SET_TICKETS_TREESELECT_FILTER_SELECTED_OPTIONS_BY_CONTEXT,
} from '@/store/mutationTypes';

export const treeselectFilterState = () => Object.keys(TICKETS_TREESELECT_FILTER_CONTEXT)
  .reduce((acc, cur) => {
    acc[TICKETS_TREESELECT_FILTER_CONTEXT[cur]] = {
      value: [],
      filters: {
        expedite: null,
        unanswered: null,
        unassigned: null,
        domains: [],
        topics: [],
        merchants: [],
        users: [],
      },
    };
    return acc;
  }, {});

export default {
  state: {
    treeselectFilterSelectedOptionsByContext: treeselectFilterState(),
  },

  getters: {
    treeselectFilterQueryParams(state, getters) {
      const {
        expedite,
        unanswered,
        unassigned,
        domains,
        topics,
        merchants,
        users,
      } = state.treeselectFilterSelectedOptionsByContext[getters.treeselectFilterContext].filters;
      const params = {};

      if (domains && domains.length > 0) params.domain = [...domains];
      if (topics && topics.length > 0) params.topic = [...topics];
      if (merchants && merchants.length > 0) params.merchant = [...merchants];
      if (users && users.length > 0) params.assigned_to = [...users];
      if (expedite) params.expedite = expedite;
      if (unanswered) params.unanswered = unanswered;
      if (unassigned) params.unassigned = unassigned;

      return params;
    },
    treeselectFilterContext(state) {
      if (state.selectedStatus === TICKET_STATUS.OVERDUE) {
        if (state.overdue) {
          return TICKETS_TREESELECT_FILTER_CONTEXT.OVERDUE_NEW;
        }
        return TICKETS_TREESELECT_FILTER_CONTEXT.OVERDUE_OTHER;
      }

      if (state.selectedStatus === TICKET_STATUS.PANICKED) {
        if (state.panicMode === PANIC_MODE.NEW) {
          return TICKETS_TREESELECT_FILTER_CONTEXT.PANICKED_NEW;
        } if (state.panicMode === PANIC_MODE.ANSWER) {
          return TICKETS_TREESELECT_FILTER_CONTEXT.PANICKED_ANSWER;
        } if (state.panicMode === PANIC_MODE.TIMEOUT) {
          return TICKETS_TREESELECT_FILTER_CONTEXT.PANICKED_TIMEOUT;
        }
      }

      return state.selectedStatus;
    },
    numberOfAppliedTreeselectFilters(state, getters) {
      const context = getters.treeselectFilterContext;
      const value = get(state.treeselectFilterSelectedOptionsByContext, `${context}.value`, []);

      return value.length;
    },
  },

  actions: {
    setTreeselectFilterDomains({ state, commit, getters }, { type, domains }) {
      const { SELECT, DESELECT } = TICKETS_TREESELECT_FILTER_UPDATE_TYPE;
      const currentDomains =
        state.treeselectFilterSelectedOptionsByContext[getters.treeselectFilterContext]
          .filters.domains;
      let selectedDomains;

      switch (type) {
        case SELECT: {
          selectedDomains = union(currentDomains, domains);
          commit(SET_TICKETS_TREESELECT_FILTER_DOMAINS, {
            context: getters.treeselectFilterContext,
            domains: selectedDomains,
          });

          break;
        }
        case DESELECT: {
          selectedDomains = difference(currentDomains, domains);
          commit(SET_TICKETS_TREESELECT_FILTER_DOMAINS, {
            context: getters.treeselectFilterContext,
            domains: selectedDomains,
          });

          break;
        }
        default: {
          break;
        }
      }
    },
    setTreeselectFilterTopics({ state, commit, getters }, { type, topics }) {
      const { SELECT, DESELECT } = TICKETS_TREESELECT_FILTER_UPDATE_TYPE;
      const currentTopics =
        state.treeselectFilterSelectedOptionsByContext[getters.treeselectFilterContext]
          .filters.topics;
      let selectedTopics;

      switch (type) {
        case SELECT: {
          selectedTopics = union(currentTopics, topics);
          commit(SET_TICKETS_TREESELECT_FILTER_TOPICS, {
            context: getters.treeselectFilterContext,
            topics: selectedTopics,
          });

          break;
        }
        case DESELECT: {
          selectedTopics = difference(currentTopics, topics);
          commit(SET_TICKETS_TREESELECT_FILTER_TOPICS, {
            context: getters.treeselectFilterContext,
            topics: selectedTopics,
          });

          break;
        }
        default: {
          break;
        }
      }
    },
    setTreeselectFilterMerchants({ state, commit, getters }, { type, merchants }) {
      const { SELECT, DESELECT } = TICKETS_TREESELECT_FILTER_UPDATE_TYPE;
      const currentMerchants =
        state.treeselectFilterSelectedOptionsByContext[getters.treeselectFilterContext]
          .filters.merchants;
      let selectedMerchants;

      switch (type) {
        case SELECT: {
          selectedMerchants = union(currentMerchants, merchants);
          commit(SET_TICKETS_TREESELECT_FILTER_MERCHANTS, {
            context: getters.treeselectFilterContext,
            merchants: selectedMerchants,
          });

          break;
        }
        case DESELECT: {
          selectedMerchants = difference(currentMerchants, merchants);
          commit(SET_TICKETS_TREESELECT_FILTER_MERCHANTS, {
            context: getters.treeselectFilterContext,
            merchants: selectedMerchants,
          });

          break;
        }
        default: {
          break;
        }
      }
    },
    setTreeselectFilterAssignedTo({ state, commit, getters }, { type, users }) {
      const { SELECT, DESELECT } = TICKETS_TREESELECT_FILTER_UPDATE_TYPE;
      const currentUsers =
        state.treeselectFilterSelectedOptionsByContext[getters.treeselectFilterContext]
          .filters.users;
      let selectedUsers;

      switch (type) {
        case SELECT: {
          selectedUsers = union(currentUsers, users);
          commit(SET_TICKETS_TREESELECT_FILTER_ASSIGNED_TO, {
            context: getters.treeselectFilterContext,
            users: selectedUsers,
          });

          break;
        }
        case DESELECT: {
          selectedUsers = difference(currentUsers, users);
          commit(SET_TICKETS_TREESELECT_FILTER_ASSIGNED_TO, {
            context: getters.treeselectFilterContext,
            users: selectedUsers,
          });

          break;
        }
        default: {
          break;
        }
      }
    },
    setTreeselectFilterExpedite({ commit, getters }, { type }) {
      const { SELECT, DESELECT } = TICKETS_TREESELECT_FILTER_UPDATE_TYPE;
      const context = getters.treeselectFilterContext;

      switch (type) {
        case SELECT: {
          commit(SET_TICKETS_TREESELECT_FILTER_EXPEDITE, { context, expedite: true });

          break;
        }
        case DESELECT: {
          commit(SET_TICKETS_TREESELECT_FILTER_EXPEDITE, { context, expedite: null });

          break;
        }
        default: {
          break;
        }
      }
    },
    setTreeselectFilterUnanswered({ commit, getters }, { type }) {
      const { SELECT, DESELECT } = TICKETS_TREESELECT_FILTER_UPDATE_TYPE;
      const context = getters.treeselectFilterContext;

      switch (type) {
        case SELECT: {
          commit(SET_TICKETS_TREESELECT_FILTER_UNANSWERED, { context, unanswered: true });

          break;
        }
        case DESELECT: {
          commit(SET_TICKETS_TREESELECT_FILTER_UNANSWERED, { context, unanswered: null });

          break;
        }
        default: {
          break;
        }
      }
    },
    setTreeselectFilterUnassigned({ commit, getters }, { type }) {
      const { SELECT, DESELECT } = TICKETS_TREESELECT_FILTER_UPDATE_TYPE;
      const context = getters.treeselectFilterContext;

      switch (type) {
        case SELECT: {
          commit(SET_TICKETS_TREESELECT_FILTER_UNASSIGNED, { context, unassigned: true });

          break;
        }
        case DESELECT: {
          commit(SET_TICKETS_TREESELECT_FILTER_UNASSIGNED, { context, unassigned: null });

          break;
        }
        default: {
          break;
        }
      }
    },
    setTreeselectFilterValue({ commit, getters }, { value }) {
      commit(SET_TICKETS_TREESELECT_FILTER_VALUE, {
        context: getters.treeselectFilterContext,
        value,
      });
    },
    setTreeselectFilterSelectedOptionsByContext(
      { commit },
      { treeselectFilterSelectedOptionsByContext },
    ) {
      commit(
        SET_TICKETS_TREESELECT_FILTER_SELECTED_OPTIONS_BY_CONTEXT,
        { treeselectFilterSelectedOptionsByContext },
      );
    },
  },

  mutations: {
    [SET_TICKETS_TREESELECT_FILTER_EXPEDITE](state, { context, expedite }) {
      state.treeselectFilterSelectedOptionsByContext[context].filters.expedite = expedite;
    },
    [SET_TICKETS_TREESELECT_FILTER_UNANSWERED](state, { context, unanswered }) {
      state.treeselectFilterSelectedOptionsByContext[context].filters.unanswered = unanswered;
    },
    [SET_TICKETS_TREESELECT_FILTER_UNASSIGNED](state, { context, unassigned }) {
      state.treeselectFilterSelectedOptionsByContext[context].filters.unassigned = unassigned;
    },
    [SET_TICKETS_TREESELECT_FILTER_DOMAINS](state, { context, domains }) {
      state.treeselectFilterSelectedOptionsByContext[context].filters.domains = [...domains];
    },
    [SET_TICKETS_TREESELECT_FILTER_TOPICS](state, { context, topics }) {
      state.treeselectFilterSelectedOptionsByContext[context].filters.topics = [...topics];
    },
    [SET_TICKETS_TREESELECT_FILTER_MERCHANTS](state, { context, merchants }) {
      state.treeselectFilterSelectedOptionsByContext[context].filters.merchants = [...merchants];
    },
    [SET_TICKETS_TREESELECT_FILTER_ASSIGNED_TO](state, { context, users }) {
      state.treeselectFilterSelectedOptionsByContext[context].filters.users = [...users];
    },
    [SET_TICKETS_TREESELECT_FILTER_VALUE](state, { context, value }) {
      state.treeselectFilterSelectedOptionsByContext[context].value = [...value];
    },
    [SET_TICKETS_TREESELECT_FILTER_SELECTED_OPTIONS_BY_CONTEXT](
      state,
      { treeselectFilterSelectedOptionsByContext },
    ) {
      state.treeselectFilterSelectedOptionsByContext = {
        ...treeselectFilterSelectedOptionsByContext,
      };
    },
  },
};
