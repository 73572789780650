import { GenerateSSOAssertionRequest } from '~grpc/core/v1/services/auth_service_pb';
import { tokenService } from './token.service';

export class AuthError extends Error {
  constructor(statusCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = statusCode;
  }
}

export function isSSORequestUrl(url) {
  const params = new URLSearchParams(url);
  return params.has('sso');
}

export class AuthService {
  #apiService = null

  #grpcAuthApiClient = null

  #eventBus = null

  constructor(apiService, eventBus, grpcAuthApiClient) {
    this.apiService = apiService;
    this.grpcAuthApiClient = grpcAuthApiClient;
    this.eventBus = eventBus;
  }

  async login(email, password, scopes = []) {
    try {
      const response = await this.apiService.post('auth/login', {
        email,
        password,
        scopes,
      });

      const { token, requiresSecondaryAuthentication } = response.data;

      if (!requiresSecondaryAuthentication) {
        tokenService.saveToken(token);
        this.mountAuthErrorInterceptor();
      }
      this.apiService.setHeader();

      return { token, requiresSecondaryAuthentication };
    } catch (error) {
      throw new AuthError(error.response.status, error.response.data.errors);
    }
  }

  async loginTwoFactor(email, tmpToken, otpCode, scopes = []) {
    try {
      const response = await this.apiService.post('auth/login', {
        email,
        one_time_password: otpCode,
        token: tmpToken,
        scopes,
      });

      const { token, requiresSecondaryAuthentication } = response.data;

      if (!requiresSecondaryAuthentication) {
        tokenService.saveToken(token);
      }
      this.apiService.setHeader();

      this.mountAuthErrorInterceptor();

      return { token, requiresSecondaryAuthentication };
    } catch (error) {
      if (error.response.status === 401) {
        throw new AuthError(error.response.status, ['Authentication code is invalid']);
      }
      throw new AuthError(error.response.status, error.response.data.errors);
    }
  }

  async requestOTP(email) {
    try {
      return (await this.apiService.post('auth/send-one-time-password', {
        email,
      }));
    } catch (error) {
      throw new Error('auth.service::requestOTP [error]', error);
    }
  }

  async generateSSOAssertion(ssoRequestUrl) {
    try {
      const request = new GenerateSSOAssertionRequest();
      request.setRequestUrl(ssoRequestUrl);
      const response = await this.grpcAuthApiClient.generateSSOAssertion(request);
      const data = response.toObject();
      return data;
    } catch (error) {
      throw new Error('auth.service::generateSSOAssertion [error]', error);
    }
  }

  mountAuthErrorInterceptor() {
    this.apiService.mountErrorStatusInterceptor({
      errorHandler: async (err) => {
        if (err.request.status === 401) {
          this.eventBus.$emit('api:AuthError', err);
        }

        return Promise.reject(err);
      },
    }, 401);
  }

  logout() {
    tokenService.removeToken();
    this.apiService.removeHeader();
    this.apiService.unmountErrorStatusInterceptor(401);
  }

  static isAuthenticated() {
    return !!tokenService.getToken();
  }
}
