import get from 'lodash/get';
import {
  TICKET_TYPE,
} from '@/constants';

export const ORDER_TYPE = {
  MARKETPLACE: 'marketplace',
  BUYBACK: 'buyback',
};

export function ticketOrderType(ticket, forceMarketplaceAsDefault = false) {
  if (forceMarketplaceAsDefault) {
    return get(ticket, 'ticketOrderType', TICKET_TYPE.MARKETPLACE);
  }

  return get(ticket, 'ticketOrderType');
}

export function isBuybackTicket(ticket) {
  return ticketOrderType(ticket) === TICKET_TYPE.BUYBACK;
}
