import {
  byOrderId, getTicketTopicsByOrderItem, postCreateTicket, byTicketId, getSearchedOrders,
} from '@/api';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

class OrderService {
  getOrder = async (orderId) => {
    try {
      const response = await byOrderId(orderId).getDetails({
        ungrouped: true,
      });

      return response.data;
    } catch (error) {
      throw new Error('OrderService::getOrder [error]', error);
    }
  };

  getTicketTopics = async (orderItemId) => {
    try {
      const response = await getTicketTopicsByOrderItem(orderItemId);

      return response.data;
    } catch (error) {
      throw new Error('OrderService::getTicketTopics [error]', error);
    }
  }

  createTicket = async (params) => {
    try {
      const response = await postCreateTicket(params);

      return response.data;
    } catch (error) {
      throw new Error('OrderService::createTicket [error]', error);
    }
  }

  addAttachmentToTicket = async ({ ticketId, attachment, audience }) => {
    try {
      const response = await byTicketId(ticketId).postAttachment({
        attachment,
        audience,
      });

      return response.data;
    } catch (error) {
      throw new Error('OrderService::addAttachmentToTicket [error]', error);
    }
  }

  getOrders = async (params) => {
    try {
      const response = await getSearchedOrders(params);

      return response.data;
    } catch (error) {
      throw new Error('OrderService::getOrders [error]', error);
    }
  }

  static getOrderItems = order => get(order, 'items', []);

  static getOrderAddons = orderItems => orderItems.reduce((acc, cur) => {
    if (isArray(cur.addons)) {
      acc.push(...cur.addons);
    }

    return acc;
  }, []);

  getOrderProductById = (order, id) => {
    const orderItems = OrderService.getOrderItems(order);

    let product = orderItems.find(i => i.id === id);

    if (product) return product;

    product = OrderService.getOrderAddons(orderItems).find(i => i.id === id);

    if (product) return product;

    return null;
  };

  getOrderProducts = (order) => {
    const orderItems = OrderService.getOrderItems(order);
    const addons = OrderService.getOrderAddons(orderItems);

    return [
      ...orderItems,
      ...addons,
    ];
  };
}

export { OrderService };
