// source: refb/refurbed/merchant/v1/resources/buyback_bid.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var refb_refurbed_core_v1_enums_buyback_bid_grading_pb = require('../../../../../refb/refurbed/core/v1/enums/buyback_bid_grading_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_enums_buyback_bid_grading_pb);
var refb_refurbed_core_v1_enums_country_code_pb = require('../../../../../refb/refurbed/core/v1/enums/country_code_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_enums_country_code_pb);
var refb_refurbed_core_v1_enums_currency_code_pb = require('../../../../../refb/refurbed/core/v1/enums/currency_code_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_enums_currency_code_pb);
var refb_refurbed_merchant_v1_common_buyback_attribute_pb = require('../../../../../refb/refurbed/merchant/v1/common/buyback_attribute_pb.js');
goog.object.extend(proto, refb_refurbed_merchant_v1_common_buyback_attribute_pb);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.BuybackBid', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.BuybackMarketBid', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.BuybackBid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.merchant.v1.BuybackBid.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.BuybackBid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.BuybackBid.displayName = 'proto.refb.refurbed.merchant.v1.BuybackBid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.BuybackMarketBid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.BuybackMarketBid.displayName = 'proto.refb.refurbed.merchant.v1.BuybackMarketBid';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.displayName = 'proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.merchant.v1.BuybackBid.repeatedFields_ = [4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.BuybackBid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.BuybackBid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackBid.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute.toObject, includeInstance),
    stock: jspb.Message.getFieldWithDefault(msg, 5, 0),
    grading: jspb.Message.getFieldWithDefault(msg, 6, 0),
    marketBidsList: jspb.Message.toObjectList(msg.getMarketBidsList(),
    proto.refb.refurbed.merchant.v1.BuybackMarketBid.toObject, includeInstance),
    sku: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.BuybackBid;
  return proto.refb.refurbed.merchant.v1.BuybackBid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackBid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = new refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute;
      reader.readMessage(value,refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStock(value);
      break;
    case 6:
      var value = /** @type {!proto.refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading} */ (reader.readEnum());
      msg.setGrading(value);
      break;
    case 7:
      var value = new proto.refb.refurbed.merchant.v1.BuybackMarketBid;
      reader.readMessage(value,proto.refb.refurbed.merchant.v1.BuybackMarketBid.deserializeBinaryFromReader);
      msg.addMarketBids(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.BuybackBid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackBid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackBid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getGrading();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getMarketBidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.refb.refurbed.merchant.v1.BuybackMarketBid.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 product_id = 2;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.getProductId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.setProductId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated BuybackAttribute attributes = 4;
 * @return {!Array<!proto.refb.refurbed.merchant.v1.BuybackAttribute>}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.refb.refurbed.merchant.v1.BuybackAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute, 4));
};


/**
 * @param {!Array<!proto.refb.refurbed.merchant.v1.BuybackAttribute>} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
*/
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.refb.refurbed.merchant.v1.BuybackAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional uint32 stock = 5;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.getStock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.setStock = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.clearStock = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.hasStock = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading grading = 6;
 * @return {!proto.refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.getGrading = function() {
  return /** @type {!proto.refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.setGrading = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated BuybackMarketBid market_bids = 7;
 * @return {!Array<!proto.refb.refurbed.merchant.v1.BuybackMarketBid>}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.getMarketBidsList = function() {
  return /** @type{!Array<!proto.refb.refurbed.merchant.v1.BuybackMarketBid>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.refb.refurbed.merchant.v1.BuybackMarketBid, 7));
};


/**
 * @param {!Array<!proto.refb.refurbed.merchant.v1.BuybackMarketBid>} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
*/
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.setMarketBidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.refb.refurbed.merchant.v1.BuybackMarketBid=} opt_value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.addMarketBids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.refb.refurbed.merchant.v1.BuybackMarketBid, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.clearMarketBidsList = function() {
  return this.setMarketBidsList([]);
};


/**
 * optional string sku = 8;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.setSku = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.clearSku = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.BuybackBid.prototype.hasSku = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.BuybackMarketBid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.toObject = function(includeInstance, msg) {
  var f, obj = {
    bidId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    marketCode: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    price: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bidSku: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.BuybackMarketBid;
  return proto.refb.refurbed.merchant.v1.BuybackMarketBid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBidId(value);
      break;
    case 2:
      var value = /** @type {!proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode} */ (reader.readEnum());
      msg.setMarketCode(value);
      break;
    case 3:
      var value = /** @type {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} */ (reader.readEnum());
      msg.setCurrencyCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidSku(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.BuybackMarketBid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBidId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMarketCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 bid_id = 1;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.getBidId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.setBidId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional refb.refurbed.core.v1.CountryCodeEnum.CountryCode market_code = 2;
 * @return {!proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.getMarketCode = function() {
  return /** @type {!proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.setMarketCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode currency_code = 3;
 * @return {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.getCurrencyCode = function() {
  return /** @type {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string price = 4;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bid_sku = 5;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.getBidSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.setBidSku = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBid} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.clearBidSku = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBid.prototype.hasBidSku = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    productId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute.toObject, includeInstance),
    marketCode: jspb.Message.getFieldWithDefault(msg, 5, 0),
    grading: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    price: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bidId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    bidSku: jspb.Message.getFieldWithDefault(msg, 10, ""),
    merchantId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    merchantName: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary;
  return proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 4:
      var value = new refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute;
      reader.readMessage(value,refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    case 5:
      var value = /** @type {!proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode} */ (reader.readEnum());
      msg.setMarketCode(value);
      break;
    case 6:
      var value = /** @type {!proto.refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading} */ (reader.readEnum());
      msg.setGrading(value);
      break;
    case 7:
      var value = /** @type {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} */ (reader.readEnum());
      msg.setCurrencyCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBidId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidSku(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute.serializeBinaryToWriter
    );
  }
  f = message.getMarketCode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getGrading();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBidId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMerchantId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getMerchantName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 product_id = 2;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getProductId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setProductId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string product_name = 3;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated BuybackAttribute attributes = 4;
 * @return {!Array<!proto.refb.refurbed.merchant.v1.BuybackAttribute>}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.refb.refurbed.merchant.v1.BuybackAttribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, refb_refurbed_merchant_v1_common_buyback_attribute_pb.BuybackAttribute, 4));
};


/**
 * @param {!Array<!proto.refb.refurbed.merchant.v1.BuybackAttribute>} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
*/
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.refb.refurbed.merchant.v1.BuybackAttribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};


/**
 * optional refb.refurbed.core.v1.CountryCodeEnum.CountryCode market_code = 5;
 * @return {!proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getMarketCode = function() {
  return /** @type {!proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.CountryCodeEnum.CountryCode} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setMarketCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading grading = 6;
 * @return {!proto.refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getGrading = function() {
  return /** @type {!proto.refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.BuybackBidGradingEnum.BuybackBidGrading} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setGrading = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode currency_code = 7;
 * @return {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getCurrencyCode = function() {
  return /** @type {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.CurrencyCodeEnum.CurrencyCode} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string price = 8;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 bid_id = 9;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getBidId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setBidId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string bid_sku = 10;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getBidSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setBidSku = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.clearBidSku = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.hasBidSku = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 merchant_id = 11;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string merchant_name = 12;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.getMerchantName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackMarketBidSummary.prototype.setMerchantName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


goog.object.extend(exports, proto.refb.refurbed.merchant.v1);
