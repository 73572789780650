// source: refb/refurbed/merchant/v1/services/buyback_order_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var refb_descriptor_pb = require('../../../../../refb/descriptor_pb.js');
goog.object.extend(proto, refb_descriptor_pb);
var refb_refurbed_core_v1_enums_sort_order_pb = require('../../../../../refb/refurbed/core/v1/enums/sort_order_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_enums_sort_order_pb);
var refb_refurbed_core_v1_filters_buyback_attribute_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/buyback_attribute_filter_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_filters_buyback_attribute_filter_pb);
var refb_refurbed_core_v1_filters_buyback_order_item_state_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/buyback_order_item_state_filter_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_filters_buyback_order_item_state_filter_pb);
var refb_refurbed_core_v1_filters_int64_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/int64_filter_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_filters_int64_filter_pb);
var refb_refurbed_core_v1_filters_numeric_range_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/numeric_range_filter_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_filters_numeric_range_filter_pb);
var refb_refurbed_core_v1_filters_string_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/string_filter_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_filters_string_filter_pb);
var refb_refurbed_core_v1_filters_string_set_contains_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/string_set_contains_filter_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_filters_string_set_contains_filter_pb);
var refb_refurbed_core_v1_filters_text_search_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/text_search_filter_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_filters_text_search_filter_pb);
var refb_refurbed_core_v1_filters_timestamp_range_filter_pb = require('../../../../../refb/refurbed/core/v1/filters/timestamp_range_filter_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_filters_timestamp_range_filter_pb);
var refb_refurbed_core_v1_paginations_int64_pagination_pb = require('../../../../../refb/refurbed/core/v1/paginations/int64_pagination_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_paginations_int64_pagination_pb);
var refb_refurbed_merchant_v1_resources_buyback_order_pb = require('../../../../../refb/refurbed/merchant/v1/resources/buyback_order_pb.js');
goog.object.extend(proto, refb_refurbed_merchant_v1_resources_buyback_order_pb);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.By', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.displayName = 'proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.displayName = 'proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.displayName = 'proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.displayName = 'proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.displayName = 'proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.displayName = 'proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && refb_refurbed_core_v1_paginations_int64_pagination_pb.Int64Pagination.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest;
  return proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new refb_refurbed_core_v1_paginations_int64_pagination_pb.Int64Pagination;
      reader.readMessage(value,refb_refurbed_core_v1_paginations_int64_pagination_pb.Int64Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort;
      reader.readMessage(value,proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter;
      reader.readMessage(value,proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      refb_refurbed_core_v1_paginations_int64_pagination_pb.Int64Pagination.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: jspb.Message.getFieldWithDefault(msg, 1, 0),
    by: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort;
  return proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.refb.refurbed.core.v1.SortOrderEnum.SortOrder} */ (reader.readEnum());
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.By} */ (reader.readEnum());
      msg.setBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBy();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.By = {
  ID: 0,
  STATE: 1,
  PRODUCT_NAME: 2,
  CREATED_AT: 4,
  SKU: 5
};

/**
 * optional refb.refurbed.core.v1.SortOrderEnum.SortOrder order = 1;
 * @return {!proto.refb.refurbed.core.v1.SortOrderEnum.SortOrder}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.prototype.getOrder = function() {
  return /** @type {!proto.refb.refurbed.core.v1.SortOrderEnum.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.SortOrderEnum.SortOrder} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.prototype.setOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional By by = 2;
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.By}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.prototype.getBy = function() {
  return /** @type {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.By} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.By} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort.prototype.setBy = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && refb_refurbed_core_v1_filters_int64_filter_pb.Int64Filter.toObject(includeInstance, f),
    state: (f = msg.getState()) && refb_refurbed_core_v1_filters_buyback_order_item_state_filter_pb.BuybackOrderItemStateFilter.toObject(includeInstance, f),
    productName: (f = msg.getProductName()) && refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter.toObject(includeInstance, f),
    productAttribute: (f = msg.getProductAttribute()) && refb_refurbed_core_v1_filters_buyback_attribute_filter_pb.BuybackAttributeFilter.toObject(includeInstance, f),
    price: (f = msg.getPrice()) && refb_refurbed_core_v1_filters_numeric_range_filter_pb.NumericRangeFilter.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && refb_refurbed_core_v1_filters_timestamp_range_filter_pb.TimestampRangeFilter.toObject(includeInstance, f),
    shippingTrackingNumber: (f = msg.getShippingTrackingNumber()) && refb_refurbed_core_v1_filters_string_filter_pb.StringFilter.toObject(includeInstance, f),
    customerEmail: (f = msg.getCustomerEmail()) && refb_refurbed_core_v1_filters_string_filter_pb.StringFilter.toObject(includeInstance, f),
    sku: (f = msg.getSku()) && refb_refurbed_core_v1_filters_string_set_contains_filter_pb.StringSetContainsFilter.toObject(includeInstance, f),
    customerInvoiceName: (f = msg.getCustomerInvoiceName()) && refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter;
  return proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new refb_refurbed_core_v1_filters_int64_filter_pb.Int64Filter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_int64_filter_pb.Int64Filter.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new refb_refurbed_core_v1_filters_buyback_order_item_state_filter_pb.BuybackOrderItemStateFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_buyback_order_item_state_filter_pb.BuybackOrderItemStateFilter.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 3:
      var value = new refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter.deserializeBinaryFromReader);
      msg.setProductName(value);
      break;
    case 4:
      var value = new refb_refurbed_core_v1_filters_buyback_attribute_filter_pb.BuybackAttributeFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_buyback_attribute_filter_pb.BuybackAttributeFilter.deserializeBinaryFromReader);
      msg.setProductAttribute(value);
      break;
    case 5:
      var value = new refb_refurbed_core_v1_filters_numeric_range_filter_pb.NumericRangeFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_numeric_range_filter_pb.NumericRangeFilter.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 6:
      var value = new refb_refurbed_core_v1_filters_timestamp_range_filter_pb.TimestampRangeFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_timestamp_range_filter_pb.TimestampRangeFilter.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = new refb_refurbed_core_v1_filters_string_filter_pb.StringFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_string_filter_pb.StringFilter.deserializeBinaryFromReader);
      msg.setShippingTrackingNumber(value);
      break;
    case 8:
      var value = new refb_refurbed_core_v1_filters_string_filter_pb.StringFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_string_filter_pb.StringFilter.deserializeBinaryFromReader);
      msg.setCustomerEmail(value);
      break;
    case 9:
      var value = new refb_refurbed_core_v1_filters_string_set_contains_filter_pb.StringSetContainsFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_string_set_contains_filter_pb.StringSetContainsFilter.deserializeBinaryFromReader);
      msg.setSku(value);
      break;
    case 10:
      var value = new refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter;
      reader.readMessage(value,refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter.deserializeBinaryFromReader);
      msg.setCustomerInvoiceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      refb_refurbed_core_v1_filters_int64_filter_pb.Int64Filter.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      refb_refurbed_core_v1_filters_buyback_order_item_state_filter_pb.BuybackOrderItemStateFilter.serializeBinaryToWriter
    );
  }
  f = message.getProductName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter.serializeBinaryToWriter
    );
  }
  f = message.getProductAttribute();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      refb_refurbed_core_v1_filters_buyback_attribute_filter_pb.BuybackAttributeFilter.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      refb_refurbed_core_v1_filters_numeric_range_filter_pb.NumericRangeFilter.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      refb_refurbed_core_v1_filters_timestamp_range_filter_pb.TimestampRangeFilter.serializeBinaryToWriter
    );
  }
  f = message.getShippingTrackingNumber();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      refb_refurbed_core_v1_filters_string_filter_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getCustomerEmail();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      refb_refurbed_core_v1_filters_string_filter_pb.StringFilter.serializeBinaryToWriter
    );
  }
  f = message.getSku();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      refb_refurbed_core_v1_filters_string_set_contains_filter_pb.StringSetContainsFilter.serializeBinaryToWriter
    );
  }
  f = message.getCustomerInvoiceName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional refb.refurbed.core.v1.Int64Filter id = 1;
 * @return {?proto.refb.refurbed.core.v1.Int64Filter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getId = function() {
  return /** @type{?proto.refb.refurbed.core.v1.Int64Filter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_int64_filter_pb.Int64Filter, 1));
};


/**
 * @param {?proto.refb.refurbed.core.v1.Int64Filter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional refb.refurbed.core.v1.BuybackOrderItemStateFilter state = 2;
 * @return {?proto.refb.refurbed.core.v1.BuybackOrderItemStateFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getState = function() {
  return /** @type{?proto.refb.refurbed.core.v1.BuybackOrderItemStateFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_buyback_order_item_state_filter_pb.BuybackOrderItemStateFilter, 2));
};


/**
 * @param {?proto.refb.refurbed.core.v1.BuybackOrderItemStateFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasState = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional refb.refurbed.core.v1.TextSearchFilter product_name = 3;
 * @return {?proto.refb.refurbed.core.v1.TextSearchFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getProductName = function() {
  return /** @type{?proto.refb.refurbed.core.v1.TextSearchFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter, 3));
};


/**
 * @param {?proto.refb.refurbed.core.v1.TextSearchFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setProductName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearProductName = function() {
  return this.setProductName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasProductName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional refb.refurbed.core.v1.BuybackAttributeFilter product_attribute = 4;
 * @return {?proto.refb.refurbed.core.v1.BuybackAttributeFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getProductAttribute = function() {
  return /** @type{?proto.refb.refurbed.core.v1.BuybackAttributeFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_buyback_attribute_filter_pb.BuybackAttributeFilter, 4));
};


/**
 * @param {?proto.refb.refurbed.core.v1.BuybackAttributeFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setProductAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearProductAttribute = function() {
  return this.setProductAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasProductAttribute = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional refb.refurbed.core.v1.NumericRangeFilter price = 5;
 * @return {?proto.refb.refurbed.core.v1.NumericRangeFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getPrice = function() {
  return /** @type{?proto.refb.refurbed.core.v1.NumericRangeFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_numeric_range_filter_pb.NumericRangeFilter, 5));
};


/**
 * @param {?proto.refb.refurbed.core.v1.NumericRangeFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional refb.refurbed.core.v1.TimestampRangeFilter created_at = 6;
 * @return {?proto.refb.refurbed.core.v1.TimestampRangeFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getCreatedAt = function() {
  return /** @type{?proto.refb.refurbed.core.v1.TimestampRangeFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_timestamp_range_filter_pb.TimestampRangeFilter, 6));
};


/**
 * @param {?proto.refb.refurbed.core.v1.TimestampRangeFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional refb.refurbed.core.v1.StringFilter shipping_tracking_number = 7;
 * @return {?proto.refb.refurbed.core.v1.StringFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getShippingTrackingNumber = function() {
  return /** @type{?proto.refb.refurbed.core.v1.StringFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_string_filter_pb.StringFilter, 7));
};


/**
 * @param {?proto.refb.refurbed.core.v1.StringFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setShippingTrackingNumber = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearShippingTrackingNumber = function() {
  return this.setShippingTrackingNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasShippingTrackingNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional refb.refurbed.core.v1.StringFilter customer_email = 8;
 * @return {?proto.refb.refurbed.core.v1.StringFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getCustomerEmail = function() {
  return /** @type{?proto.refb.refurbed.core.v1.StringFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_string_filter_pb.StringFilter, 8));
};


/**
 * @param {?proto.refb.refurbed.core.v1.StringFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setCustomerEmail = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearCustomerEmail = function() {
  return this.setCustomerEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasCustomerEmail = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional refb.refurbed.core.v1.StringSetContainsFilter sku = 9;
 * @return {?proto.refb.refurbed.core.v1.StringSetContainsFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getSku = function() {
  return /** @type{?proto.refb.refurbed.core.v1.StringSetContainsFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_string_set_contains_filter_pb.StringSetContainsFilter, 9));
};


/**
 * @param {?proto.refb.refurbed.core.v1.StringSetContainsFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setSku = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearSku = function() {
  return this.setSku(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasSku = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional refb.refurbed.core.v1.TextSearchFilter customer_invoice_name = 10;
 * @return {?proto.refb.refurbed.core.v1.TextSearchFilter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.getCustomerInvoiceName = function() {
  return /** @type{?proto.refb.refurbed.core.v1.TextSearchFilter} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_filters_text_search_filter_pb.TextSearchFilter, 10));
};


/**
 * @param {?proto.refb.refurbed.core.v1.TextSearchFilter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.setCustomerInvoiceName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.clearCustomerInvoiceName = function() {
  return this.setCustomerInvoiceName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter.prototype.hasCustomerInvoiceName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional refb.refurbed.core.v1.Int64Pagination pagination = 1;
 * @return {?proto.refb.refurbed.core.v1.Int64Pagination}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.getPagination = function() {
  return /** @type{?proto.refb.refurbed.core.v1.Int64Pagination} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_core_v1_paginations_int64_pagination_pb.Int64Pagination, 1));
};


/**
 * @param {?proto.refb.refurbed.core.v1.Int64Pagination|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Sort sort = 2;
 * @return {?proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.getSort = function() {
  return /** @type{?proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort, 2));
};


/**
 * @param {?proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Sort|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filter filter = 3;
 * @return {?proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.getFilter = function() {
  return /** @type{?proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter, 3));
};


/**
 * @param {?proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.Filter|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo.toObject, includeInstance),
    hasMore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse;
  return proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo;
      reader.readMessage(value,refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo.serializeBinaryToWriter
    );
  }
  f = message.getHasMore();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated BuybackOrderWithRelatedInfo orders = 1;
 * @return {!Array<!proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo>}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo, 1));
};


/**
 * @param {!Array<!proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo>} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse} returns this
*/
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};


/**
 * optional bool has_more = 2;
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse} returns this
 */
proto.refb.refurbed.merchant.v1.ListBuybackOrdersResponse.prototype.setHasMore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest;
  return proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest} returns this
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrder.toObject(includeInstance, f),
    orderItemsHistoryList: jspb.Message.toObjectList(msg.getOrderItemsHistoryList(),
    refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderItemHistory.toObject, includeInstance),
    orderWithRelatedInfo: (f = msg.getOrderWithRelatedInfo()) && refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse;
  return proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrder;
      reader.readMessage(value,refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 2:
      var value = new refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderItemHistory;
      reader.readMessage(value,refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderItemHistory.deserializeBinaryFromReader);
      msg.addOrderItemsHistory(value);
      break;
    case 3:
      var value = new refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo;
      reader.readMessage(value,refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo.deserializeBinaryFromReader);
      msg.setOrderWithRelatedInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrder.serializeBinaryToWriter
    );
  }
  f = message.getOrderItemsHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderItemHistory.serializeBinaryToWriter
    );
  }
  f = message.getOrderWithRelatedInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional BuybackOrder order = 1;
 * @return {?proto.refb.refurbed.merchant.v1.BuybackOrder}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.refb.refurbed.merchant.v1.BuybackOrder} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrder, 1));
};


/**
 * @param {?proto.refb.refurbed.merchant.v1.BuybackOrder|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} returns this
*/
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} returns this
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated BuybackOrderItemHistory order_items_history = 2;
 * @return {!Array<!proto.refb.refurbed.merchant.v1.BuybackOrderItemHistory>}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.getOrderItemsHistoryList = function() {
  return /** @type{!Array<!proto.refb.refurbed.merchant.v1.BuybackOrderItemHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderItemHistory, 2));
};


/**
 * @param {!Array<!proto.refb.refurbed.merchant.v1.BuybackOrderItemHistory>} value
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} returns this
*/
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.setOrderItemsHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.refb.refurbed.merchant.v1.BuybackOrderItemHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.refb.refurbed.merchant.v1.BuybackOrderItemHistory}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.addOrderItemsHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.refb.refurbed.merchant.v1.BuybackOrderItemHistory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} returns this
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.clearOrderItemsHistoryList = function() {
  return this.setOrderItemsHistoryList([]);
};


/**
 * optional BuybackOrderWithRelatedInfo order_with_related_info = 3;
 * @return {?proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.getOrderWithRelatedInfo = function() {
  return /** @type{?proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo} */ (
    jspb.Message.getWrapperField(this, refb_refurbed_merchant_v1_resources_buyback_order_pb.BuybackOrderWithRelatedInfo, 3));
};


/**
 * @param {?proto.refb.refurbed.merchant.v1.BuybackOrderWithRelatedInfo|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} returns this
*/
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.setOrderWithRelatedInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse} returns this
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.clearOrderWithRelatedInfo = function() {
  return this.setOrderWithRelatedInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.GetBuybackOrderResponse.prototype.hasOrderWithRelatedInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.refb.refurbed.merchant.v1);
