import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { ORDER_STATE } from '@/constants';
import { daysInMilliseconds } from '@/services/datetime';

export const isOrderOlderThan30Days = (createdAt) => {
  const now = Date.now();
  const pCreatedAt = new Date(createdAt);

  return (now - pCreatedAt) > daysInMilliseconds(30);
};
export const getOrderStateValue = (chargedTotal, refundedTotal, orderState) => {
  if (refundedTotal >= chargedTotal) {
    return ORDER_STATE.REFUNDED;
  } if (refundedTotal > 0) {
    return ORDER_STATE.PARTIALLY_REFUNDED;
  } if (orderState === ORDER_STATE.RELEASED) {
    return ORDER_STATE.RELEASED;
  }

  return ORDER_STATE.PLACED;
};
export const getOrderItems = order => get(order, 'items', []);
export const getOrderAddons = orderItems => orderItems.reduce((acc, cur) => {
  if (isArray(cur.addons)) {
    acc.push(...cur.addons);
  }

  return acc;
}, []);
export const getOrderProductById = (order, id) => {
  const orderItems = getOrderItems(order);

  let product = orderItems.find(i => i.id === id);

  if (product) return product;

  product = getOrderAddons(orderItems).find(i => i.id === id);

  if (product) return product;

  return null;
};
export const getOrderProducts = (order) => {
  const orderItems = getOrderItems(order);
  const addons = getOrderAddons(orderItems);

  return [
    ...orderItems,
    ...addons,
  ];
};

export const getRefundByOrder = (order, orderId) => {
  const orderProduct = getOrderProductById(order, orderId);

  if (!orderProduct) return null;

  return {
    amount: orderProduct.refunded || '',
    dateTime: orderProduct.lastRefundAt || '',
  };
};

export const getAddonBrand = addon => addon.type || '';

export const getAddonMerchant = (addon) => {
  const brand = getAddonBrand(addon);

  if (brand === 'thirty-five-up') {
    return get(addon, 'addonDetails.data.vendorName', '');
  }

  if (brand === 'in-house-product') {
    return 'refurbed';
  }

  if (brand === 'refurbed-plus') {
    return 'Refurbed Plus GmbH';
  }

  return get(addon, 'merchantName', '');
};
