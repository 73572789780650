export const ENV = Object.freeze({
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  PREVIEW: 'preview',
  STAGING: 'staging',
  QA: 'qa',
  PRODUCTION: 'production',
  TESTING: 'testing',
});
export const LOADING_FLAG = Object.freeze({
  INITIAL: 'initial',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
});
export const UserRoles = Object.freeze({
  Refurbed: 'refurbed',
  Admin: 'refurbed',
  Merchant: 'merchant',
  Customer: 'customer',
});
export const API_LIMIT_MAX = 100;
export const CHAT_FILE_SIZE_LIMIT_BYTES = 10000000;
export const MESSAGE_AUTHOR = Object.freeze({
  REFURBED: 'refurbed',
  ADMIN: 'refurbed',
  MERCHANT: 'merchant',
  CUSTOMER: 'customer',
});

export const AddonTypes = {
  CASEABLE: 'caseable',
  INHOUSE: 'in-house-product',
  REFURBED_PLUS: 'refurbed-plus',
  THIRTY_FIVE_UP: 'thirty-five-up',
};

export const AltPV = {
  NORMAL: 'normal',
  ALTERNATIVE: 'alternative',
};

export const AddonsQuantity = {
  INCREASE: 'increase',
  DECREASE: 'decrease',
};

export const ADDON_CARDS_PER_ROW = 4;

export const ExtraAddons = {
  PLUS: 'refurbedPlus',
  MS365: 'ms365',
};
