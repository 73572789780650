import * as Sentry from '@sentry/browser';
import { user as userRequests } from '../../api';
import { SET_USER_DATA } from '../mutationTypes';

const user = {
  namespaced: true,

  state: {
    id: null,
    email: null,
    role: null,
    language: 'en',
  },

  getters: {
    userId(state) {
      return state.id;
    },
  },

  actions: {
    async whoami({ commit, dispatch }) {
      try {
        const { data, errors } = await userRequests.whoami();

        if (errors) {
          dispatch('/auth/logout', null, { root: true });
          return;
        }

        commit(SET_USER_DATA, data);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  },

  mutations: {
    [SET_USER_DATA](state, _user) {
      state.role = _user.type;
      state.email = _user.email;
      state.language = _user.language;
      state.id = _user.id;
    },
  },
};

export default user;
