import { isFeatureFlag } from '@/services/featureFlags';
import {
  FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE,
} from '@/constants';

import ticketStatus from '~platform/i18n/localizations/management-app/en/en.management_ticket_enum.yaml';
import ticketTopics from '~platform/i18n/localizations/customer-app/en/en.customer_ticket_topic.yaml';

let ticketTranslations = {
  ...ticketStatus,
};

if (isFeatureFlag(FEATURE_FLAG_NEW_TOPIC_CHANGE_STRUCTURE)) {
  ticketTranslations = {
    ...ticketTranslations,
    ...ticketTopics,
  };
}

export default {
  ...ticketTranslations,
};
