import camelCase from 'lodash/camelCase';

/**
 * Convert all the keys that contain underscores to camelCase (using lodash)
 * @param {Object} obj
 * @returns {Object}
 * @example
 * const obj = { foo_bar: 'baz' };
 * const camelCaseObj = convertKeys(obj);
 * console.log(camelCaseObj); // { fooBar: 'baz' }
 *
 */
const convertKeys = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    const newKey = camelCase(key);
    newObj[newKey] = obj[key];
  });
  return newObj;
};

const availableLanguages = ['de',
  'da',
  'en',
  'it',
  'nl',
  'sv'];

const childrenDefaultProps = {
  available_languages: availableLanguages,
  audience: 'refurbed',
  published: false,
  children: [],
};

const messageTemplates = [
  {
    name: 'Generic',
    children: [
      {
        id: 1,
        name: 'generic.i_need_refurbed_help',
        ...childrenDefaultProps,
      },
      {
        id: 2,
        name: 'generic.forward_to_klarna',
        ...childrenDefaultProps,
      }, {
        id: 3,
        name: 'generic.invoice',
        ...childrenDefaultProps,
      },
      {
        id: 4,
        name: 'generic.exchange_device',
        ...childrenDefaultProps,
      }, {
        id: 5,
        name: 'generic.parcel_successfully_delivered',
        ...childrenDefaultProps,
      },
      {
        id: 6,
        name: 'generic.refund_confirmation',
        ...childrenDefaultProps,
      },
      {
        id: 7,
        name: 'generic.repaired_device_shipment_confirmation',
        ...childrenDefaultProps,
      },
      {
        id: 8,
        name: 'generic.return_receipt_repair/exchange',
        ...childrenDefaultProps,
      },
      {
        id: 9,
        name: 'generic.return_instructions_ask_for_pictures',
        ...childrenDefaultProps,
      },
      {
        id: 10,
        name: 'generic.return_receipt_for_refund',
        ...childrenDefaultProps,
      }, {
        id: 11,
        name: 'generic.shipment_tracking',
        ...childrenDefaultProps,
      }, {
        id: 12,
        name: 'generic.conversation_outside_rti',
        ...childrenDefaultProps,
      }, {
        id: 13,
        name: 'generic.warranty_exclusion',
        ...childrenDefaultProps,
      }, {
        id: 14,
        name: 'generic.warranty_return',
        ...childrenDefaultProps,
      },
    ],
  },
];


const templates = [
  {
    id: 1,
    name: 'generic.i_need_refurbed_help',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXXX,
    \r\nVielen Dank für Deine Anfrage.
    \r\nLeider können wir hier nicht weiterhelfen, da das Thema XXXXXX direkt von refurbed bearbeitet wird.
    \r\nWir haben die Kolleg:innen umgehend intern um Unterstützung gebeten. Ein:e Mitarbeiter:in von refurbed wird sich so bald als möglich hier bei Dir melden.
    \r\nVielen Dank für Deine Geduld!
    \r\nXXXXSignatur!XXXX`,
    content_da: `Kære XXXX!
    \r\nMange tak for din anmodning.
    \r\n Desværre kan vi ikke hjælpe her, da dette specifikke emne håndteres direkte af refurbed.
    \r\nVi har dog internt spurgt vores kolleger om støtte. En refurbed-medarbejder vender tilbage til dig her så hurtigt som muligt.
    \r\nMange tak for din tålmodighed!
    \r\nMed venlig hilsen,
    \r\nXXXXSignatur!XXXX`,
    content_en: `Dear XXXX!
    \r\nThank you very much for your request.
    \r\nUnfortunately, we can't help here, as this specific topic is handled directly by refurbed.
    \r\nWe have asked our colleagues internally for support. A refurbed employee will get back to you here as soon as possible.
    \r\nMany thanks for your patience!
    \r\nXXXXSignature!XXXX
    `,
    content_it: `Gentile XXXX,
    \r\nLa ringrazio molto per il messaggio.
    \r\nSfortunatamente, noi come venditore del Suo prodotto non possiamo aiutarLa. Tali richieste vengono gestite direttamente da refurbed.
    \r\nAbbiamo immediatamente chiesto supporto ai/alle nostri/e colleghi/e di refurbed, i quali La contatteranno il prima possibile.
    \r\nLa ringraziamo per la pazienza.
    \r\nXXXXFirma!XXXX
    `,
    content_nl: `Beste XXXX,
    \r\nBedankt dat je contact met ons hebt opgenomen.
    \r\nHelaas kunnen wij jou niet helpen, aangezien dit specifieke onderwerp rechtstreeks door refurbed wordt behandeld.
    \r\nWe hebben onze collega's om ondersteuning gevraagd, een medewerker van refurbed zal zo snel mogelijk bij jou terugkomen.
    \r\nBedankt voor je geduld,
    \r\nXXXXHandtekeningXXXX
    `,
    content_sv: `Hej XXXX!
    \r\nTack så mycket för din förfrågan!
    \r\nTyvärr kan vi inte hjälpa dig vidare eftersom detta specifika ämne hanteras direkt av refurbed.
    \r\nVi har bett våra kollegor internt om hjälp och en medarbetare från refurbed kommer att återkomma till dig här så snart som möjligt.
    \r\nStort tack för ditt tålamod!
    \r\nXXXXSignatur!XXXX`,
    available_languages: availableLanguages,
  },
  {
    id: 2,
    name: 'generic.forward_to_klarna',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo,
    \r\nvielen Dank für Deine Anfrage.
    \r\nAus Datenschutzgründen können wir leider nicht für Dich mit Klarna kommunizieren. Wir bitten um Dein Verständnis. Bitte wende die Dich für weitere Details direkt an den Klarna Kundenservice.
    \r\nUnter dem folgenden Link erhältst Du alle weiteren Informationen und Kontaktdaten dazu.
    \r\nDeutschland: https://www.klarna.com/de/kundenservice/
    \r\nÖsterreich: https://www.klarna.com/at/kundenservice/
    \r\nSolltet es nach dieser Kontaktaufnahme zu Herausforderungen kommen, gib uns bitte hier nochmals Bescheid, damit wir die Kolleg:innen von refurbed um Unterstützung bitten können.
    \r\nVielen Dank!
    \r\nEinen schönen Tag wünschen wir Dir.
    \r\nLiebe Grüße`,
    content_da: `Hej XXX!
    \r\nTak for din forespørgsel.
    \r\nAf hensyn til databeskyttelse en vi desværre ikke kontakte Klarna på dine vegne. Vi beder venligst om din forståelse i denne sag. Kontakt venligst Klarna kundeservice direkte for yderligere oplysninger.
    \r\nVia følgende link kan du finde alle yderligere oplysninger og kontaktoplysninger: https://www.klarna.com/dk/kundeservice/
    \r\nHvis du oplever nogen udfordringer efter at have kontaktet Klarna, bedes du kontakte os igen her, så vi kan bede vores refurbed-kolleger om hjælp.
    \r\nMange tak for dit samarbejde!
    \r\nVi ønsker dig en dejlig dag.
    \r\nMed venlig hilsen`,
    content_en: `Hello XXX!
    \r\nThank you for your request.
    \r\nUnfortunately, for data protection reasons, we cannot communicate with Klarna on your behalf. We kindly ask for your understanding in this matter. Please contact the Klarna customer service directly for further details.
    \r\nVia the following link, you will find all further information and contact details:
    \r\nhttps://www.klarna.com/ie/customer-service/
    \r\nIf you encounter any challenges after contacting Klarna, please contact us again here so that we can ask our refurbed colleagues for their assistance.
    \r\nMany thanks for your cooperation!
    \r\nWe wish you a lovely day.
    \r\nAll the best
    `,
    content_it: `Gentile XXX,
    \r\ngrazie per averci contattati.
    \r\nPurtroppo, per motivi di privacy, non possiamo comunicare con Klarna per conto Suo, in quanto non abbiamo accesso ai Suoi dati di pagamento. Pertanto, La preghiamo gentilmente di contattare direttamente il servizio clienti di Klarna per ulteriori dettagli.
    \r\nCliccando sul link sottostante, troverà i dettagli di contatto di Klarna:
    \r\nhttps://www.klarna.com/it/servizio-clienti/
    \r\nSe dopo aver contattato Klarna dovesse riscontrare ancora dei problemi con il Suo pagamento, La preghiamo di mettersi nuovamente in contatto con noi, in modo da poter chiedere aiuto ai nostri colleghi e alle nostre colleghe di refurbed.
    \r\nLa ringraziamo anticipatamente per la collaborazione.
    \r\nLe auguriamo una buona giornata!
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXX,
    \r\nBedankt voor je aanvraag. Helaas mogen wij vanwege databescherming niet namens jou met Klarna communiceren. Hopelijk heb je hier begrip voor. Voor meer informatie kun je rechtstreeks contact opnemen met de klantenservice van Klarna.
    \r\nVia de volgende link vind je alle verdere informatie en contactgegevens.
    \r\nhttps://www.klarna.com/nl/klantenservice/
    \r\nMocht je na het contact met Klarna op problemen stuiten, neem dan hier nogmaals contact met ons op, zodat wij onze refurbed collega's om assistentie kunnen vragen.
    \r\nHartelijk dank voor je medewerking.
    \r\nWij wensen je een fijne dag,
    \r\nMet vriendelijke groet
    `,
    content_sv: `Hej XXX!
    \r\nTack för din förfrågan.
    \r\nAv dataskyddsskäl kan vi tyvärr inte kommunicera med Klarna för din räkning. Vänligen kontakta Klarnas kundtjänst direkt för ytterligare information.
    \r\nVia följande länk hittar du all ytterligare information och kontaktuppgifter: https://www.klarna.com/se/kundservice/
    \r\nOm du stöter på några problem efter att ha kontaktat Klarna, vänligen kontakta oss igen här så att vi kan be våra refurbed-kollegor om hjälp.
    \r\nStort tack för din förståelse!
    \r\nVi önskar dig en härlig dag.
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  }, {
    id: 3,
    name: 'generic.invoice',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo,
    \r\nVielen Dank für deine Nachricht.
    \r\nGerne haben wir Deine Rechnung im Anhang hochgeladen.
    \r\nBitte denk daran, den Betrag – sofern noch nicht bezahlt – über die von dir bei der Bestellung ausgewählte Zahlungsmethode zu begleichen. Bitte überweise keinesfalls auf das in der Rechnung angegebene Konto.
    \r\nSolltest Du weitere Fragen oder Anliegen haben, melde Dich gerne wieder bei uns.
    \r\nAnsonsten wünschen wir Dir einen schönen Tag. :-)
    \r\nLiebe Grüße`,
    content_da: `Hej XXX
    \r\nTak for din besked.
    \r\nDin faktura er vedhæftet som bilag til denne besked.
    \r\nHusk venligst at betale beløbet - hvis det ikke allerede er betalt - via den betalingsmetode, du valgte, da du afgav din bestilling. Vi beder dig venligst om ikke overføre pengene til den konto, der er angivet i fakturaen.
    \r\nHvis du har yderligere spørgsmål eller bekymringer, er du velkommen til at kontakte os igen.
    \r\nI mellemtiden ønsker vi dig en dejlig dag :-)
    \r\nMed venlig hilsen`,
    content_en: `Hello XXX!
    \r\nThank you for your message.
    \r\nPlease find your invoice attached to this message.
    \r\nPlease remember to pay the amount - if not already paid - via the payment method you selected when placing your order. Please do not transfer the money to the account specified in the invoice.
    \r\nIf you have any further questions or concerns, please feel free to contact us again.
    \r\nIn the meantime, we wish a lovely day. :-)
    \r\nAll the best
    `,
    content_it: `Gentile XXX,
    \r\ngrazie per averci contattato.
    \r\nIn allegato può trovare la fattura per il Suo acquisto su refurbed.
    \r\nSe dovesse avere ulteriori domande, non esiti a contattarci nuovamente.
    \r\nNel frattempo, Le auguriamo una buona giornata 😊
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXX
    \r\nBedankt voor je bericht.
    \r\nJe kunt jouw factuur in de bijlage vinden.
    \r\nVergeet niet het bedrag - indien nog niet betaald – over te maken via de betaalmethode die je bij het plaatsen van de bestelling hebt gekozen. Maak het geld niet over naar de rekening die op de factuur staat.
    \r\nMocht je nog vragen hebben, neem dan gerust weer contact met ons op.
    \r\nIn de tussentijd wensen wij je een fijne dag :-)
    \r\nMet vriendelijke groet
    `,
    content_sv: `Hej XXX!
    \r\nTack för ditt meddelande.
    \r\nDin faktura bifogas i detta meddelande.
    \r\nKom ihåg att betala beloppet - om det inte redan är betalt - via den betalningsmetod som du valde när du gjorde din beställning. Vänligen överför inte pengarna till det konto som anges i fakturan.
    \r\nOm du har ytterligare frågor eller funderingar är du välkommen att kontakta oss igen.
    \r\nUnder tiden önskar vi dig en härlig dag :-).
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  }, {
    id: 4,
    name: 'generic.exchange_device',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXX,
    \r\nvielen Dank für Deine Geduld.
    \r\nLeider müssen wir Dir mitteilen, dass Dein Gerät nicht repariert werden kann.
    \r\nEin gleichwertiges Austauschgerät befindet sich daher auf dem Weg zu Dir. Unter dem folgenden Link kannst Du die Sendung verfolgen:
    \r\nBitte prüfe das Gerät direkt bei der Zustellung und gib uns bitte Bescheid, ob es einwandfrei funktioniert und alles wieder zu Deiner Zufriedenheit ist.
    \r\nWir wünschen Dir ansonsten viel Spaß mit Deinem neuen refurbed XXX.
    \r\nSolltest Du noch weitere Fragen haben, sind wir jederzeit gerne für Dich da.
    \r\nLiebe Grüße`,
    content_da: `Hej XXX!
    \r\nMange tak for din tålmodighed.
    \r\nVi må desværre meddele, at din enhed ikke kan repareres.
    \r\nDerfor har vi udskiftet din enhed og sendt en anden enhed med de samme specifikationer som den returnerede enhed. Du kan spore forsendelsen med følgende link:
    \r\nVi vil venligst bede dig om at tjekke enheden umiddelbart efter leveringen og sende os en tilbagemelding om den udskiftede enhed.
    \r\nUnder alle omstændigheder bedes du straks give os besked, hvis der er noget ved enheden, der ikke fungerer som forventet.
    \r\nVi ønsker dig en god dag!
    \r\nHvis du har flere spørgsmål, er du velkommen til at kontakte os.
    \r\nMed venlig hilsen`,
    content_en: `Hello XXX!
    \r\nThank you very much for your patience.
    \r\nUnfortunately, we have to inform your device cannot be repaired.
    \r\nTherefore, we have sent an exchange device with the same features as the returned product. You can track the shipment with the following link:
    \r\nWe would kindly ask you to check the device directly on delivery and provide us with feedback of the replaced device.
    \r\nIn any case, please let us know immediately if something about the device does not work as expected.
    \r\nWe wish you a nice day!
    \r\nIf you have any further questions, please do not hesitate to contact us.
    \r\nAll the best
    `,
    content_it: `Gentile XXX,
    \r\ngrazie mille per la Sua pazienza.
    \r\nPurtroppo, dobbiamo informarLa che il Suo dispositivo non è riparabile.
    \r\nPertanto, abbiamo provveduto a spedirLe un prodotto sostitutivo con le medesime caratteristiche del prodotto da Lei acquistato. Può monitorare la spedizione cliccando sul seguente link:
    \r\nUna volta ricevuto il prodotto, La preghiamo gentilmente di testarlo quanto prima. In caso di malfunzionamenti non esiti a ricontattarci.
    \r\nLe auguriamo una buona giornata!
    \r\nSe dovesse avere ulteriori domande, non esiti a ricontattarci.
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXX,
    \r\nHartelijk dank voor je geduld.
    \r\nHelaas moeten we je meedelen dat jouw toestel niet gerepareerd kan worden.
    \r\nDaarom hebben we jou een vervangingstoestel gestuurd met dezelfde functies als het geretourneerde product. Je kunt de verzending volgen via de volgende link:
    \r\nWij verzoeken je vriendelijk het apparaat direct bij levering te controleren en ons feedback te geven over het vervangende product.
    \r\nLaat het ons in ieder geval direct weten als er iets aan het apparaat niet naar verwachting is.
    \r\nWij wensen je een fijne dag!
    \r\nMocht je nog vragen hebben, aarzel dan niet om contact met ons op te nemen.
    \r\nMet vriendelijke groet
    `,
    content_sv: `Hej XXX!
    \r\nTack så mycket för ditt tålamod.
    \r\nTyvärr måste vi meddela att din enhet inte kan repareras.
    \r\nDärför har vi skickat en utbytesenhet med samma funktioner som den returnerade produkten. Du kan spåra försändelsen med följande länk:
    \r\nVi vill be dig om att kontrollera enheten direkt vid leverans och återkomma till oss med feedback om den utbytta enheten.
    \r\nOm enheten mot förmodan inte fungerar enligt förväntan, meddela oss omedelbart.
    \r\nOm du har några ytterligare frågor, tveka inte på att höra av dig till oss.
    \r\nVi önskar dig en härlig dag!
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  }, {
    id: 5,
    name: 'generic.parcel_successfully_delivered',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXXXX,
    \r\ndanke für Deine Nachricht.
    \r\nLaut der Sendungsverfolgung Deines Paketes ist dieses in der Zwischenzeit an Dich zugestellt worden. Könntest du uns das bitte kurz bestätigen?
    \r\nVielen Dank!
    \r\nAnsonsten wünschen wir Dir einen schönen Tag. :-)
    \r\nLiebe Grüße`,
    content_da: `Hej XXXXX!
    \r\nVi håber, at alt er vel hos dig
    \r\nIfølge sporingen af forsendelsen er enheden blevet leveret til din adresse. Kan du venligst bekræfte dette?
    \r\nMange tak!
    \r\nVi ønsker dig en dejlig dag :-)
    \r\nMed venlig hilsen`,
    content_en: `Hello XXXXX!
    \r\nWe hope are e-mail finds you well.
    \r\nAccording to the shipment tracking, the product has been delivered to your address. Could you confirm this, please?
    \r\nMany thanks!
    \r\nWe wish you will a lovely day. :-)
    \r\nAll the best
    `,
    content_it: `Gentile XXXXX,
    \r\ngrazie per il Suo messaggio.
    \r\nSecondo il tracciamento spedizione il pacco risulta consegnato. Potrebbe gentilmente confermarne la ricezione?
    \r\nLa ringraziamo anticipatamente.
    \r\nNel frattempo, Le auguriamo una buona giornata :-)
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXXXX,
    \r\nVolgens de tracking van jouw pakket, is het product op je adres afgeleverd. Kunt je dit alstublieft bevestigen?
    \r\nBedankt!
    \r\nWij wensen je een fijne dag :-)
    \r\nMet vriendelijke groet
    `,
    content_sv: `Hej XXXXX!
    \r\nVi hoppas att du blir glad av detta e-mail.
    \r\nEnligt spårningslänken av försändelsen har din produkt levererats till din adress. Kan du bekräfta detta?
    \r\nTack så mycket!
    \r\nVi önskar dig en trevlig dag :-)
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  },
  {
    id: 6,
    name: 'generic.refund_confirmation',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXXX,
    \r\ndanke für deine Geduld.
    \r\nWir haben Dir soeben Deinen Kaufbetrag auf das von Dir bei der ursprünglichen Bestellung gewählte Zahlungsmittel erstattet.
    \r\nDer Zahlungseingang sollte innerhalb der nächsten 2-5 Werktage bei Dir ersichtlich sein. Gib uns bitte Bescheid, wenn Du das Geld innerhalb von 10 Tagen ab heute nicht erhalten hast.
    \r\nAnsonsten wünschen wir Dir einen schönen Tag und alles Liebe.
    \r\nLiebe Grüße`,
    content_da: `Hej XXXX!
    \r\nTak for din tålmodighed.
    \r\nVi har netop afsendt tilbagebetalingen tilbage til den betalingsmetode, du valgte, da du afgav din oprindelige bestilling. Du bør kunne se betalingen inden for de næste 8 hverdage. Giv os venligst besked, hvis du ikke har modtaget pengene efter 10 hverdage.
    \r\nDu er velkommen til at kontakte os, hvis du har spørgsmål
    \r\nVi ønsker dig en god dag!
    \r\nMed venlig hilsen`,
    content_en: `Hello XXXX!
    \r\nThank you for your patience.
    \r\nWe have just issued the refund to the payment method you chose when you placed your original order. You should see the payment within the next 8 business days. Please let us know if you have not received the money after 10 business days.
    \r\nDo not hesitate to contact us, if you have any question
    \r\nWe wish you a nice day!
    \r\nAll the best
    `,
    content_it: `Gentile XXXX,
    \r\ngrazie per la sua pazienza.
    \r\nCon la presente confermiamo che è stato effettuato il rimborso dell’importo d’acquisto del suo ordine. L’importo verrà riaccreditato sullo stesso metodo di pagamento utilizzato in fase d’acquisto.
    \r\nLa somma Le verrà riaccreditata entro i prossimi 10 giorni lavorativi. Se non dovesse ricevere il rimborso entro 10 giorni lavorativi, La preghiamo gentilmente di mettersi nuovamente in contatto con noi.
    \r\nLe auguriamo una buona giornata :-)
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXXX,
    \r\nBedankt voor je geduld.
    \r\nWe hebben zojuist de terugbetaling uitgevoerd naar de betaalmethode die je hebt gekozen toen je de oorspronkelijke bestelling plaatste.
    \r\nJe zou de terugbetaling binnen de komende 8 werkdagen moeten zien. Laat het ons weten als je het geld na 10 werkdagen nog niet ontvangen hebt.
    \r\nAarzel niet om contact met ons op te nemen als je nog verdere vragen hebt.
    \r\nWij wensen je een fijne dag!
    \r\nMet vriendelijke groet
    `,
    content_sv: `Hej XXXX!
    \r\nTack för ditt tålamod.
    \r\nVi har precis gjort en återbetalning till den betalningsmetod du valde när du gjorde din ursprungliga beställning. Du bör se betalningen inom de kommande 8 arbetsdagarna. Om du inte tagit emot betalningen inom 10 arbetsdagar, meddela oss gärna om detta.
    \r\nTveka inte heller på att kontakta oss om du har några frågor.
    \r\nVi önskar dig en trevlig dag!
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  },
  {
    id: 7,
    name: 'generic.repaired_device_shipment_confirmation',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXXX,
    \r\nvielen Dank für Deine Geduld.
    \r\nDein refurbed XXX wurde erfolgreich repariert und ist soeben wieder an Dich zurückgesendet worden. Unter dem folgenden Link kannst Du die Lieferung verfolgen: XXXXXXXXXX
    \r\nAn Deinem Gerät wurden die folgenden Defekte festgestellt und repariert: XXXXX
    \r\nSobald Du das Gerät einem ausführlichen Test unterziehen konntest, würden wir uns über ein kurzes Feedback, ob alles wieder so funktioniert wie Du es gewohnt bist freuen.
    \r\nWenn Du in der Zwischenzeit noch weitere Fragen hast, sind wir jederzeit gerne für Dich da.
    \r\nLiebe Grüße`,
    content_da: `Hej XXXX!
    \r\nTak for din tålmodighed.
    \r\nDin enhed er blevet repareret og er netop blevet sendt afsted tilbage til dig. Du kan spore leveringen med følgende link: XXXXXXXXXXXX
    \r\nVi har fundet og repareret følgende fejl: XXXXX
    \r\nSå snart du har haft mulighed for at teste enheden, vil vi sætte pris på kort tilbagemelding om det reparerede produkt.
    \r\nHvis du har yderligere spørgsmål, er vi altid glade for at hjælpe dig.
    \r\nMed venlig hilsen`,
    content_en: `Hello XXXX!
    \r\nThank you for your patience.
    \r\nYour refurbed XXX has been fixed and just been returned to you. You can track the delivery with the following link: XXXXXXXXXX
    \r\nWe found and repaired the following defects: XXXXX
    \r\nAs soon as you have been able to test the device, we would appreciate short feedback about the repaired product.
    \r\nIf you have any further questions, we are always happy to help.
    \r\nAll the best
    `,
    content_it: `Gentile XXXX,
    \r\nLa ringraziamo per la pazienza.
    \r\nIl dispositivo è stato riparato e Le verrà recapitato nei prossimi giorni. Può monitorare la consegna cliccando sul seguente link: XXXXXXXXXX
    \r\nIl nostro reparto tecnico ha effettuato la riparazione delle seguenti componenti: XXXXX
    \r\nLe chiediamo gentilmente di testare il dispositivo e di contattarci nuovamente, nel caso in cui dovesse riscontrare dei malfunzionamenti.
    \r\nRimaniamo a Sua disposizione per eventuali domande.
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXXX,
    \r\nBedankt voor je geduld.
    \r\nJouw refurbed XXXname van productXXX is gerepareerd en zojuist naar jou teruggestuurd. Je kunt de levering volgen via de volgende link: XXXXXXXXXX
    \r\nWe hebben de volgende defecten gevonden en gerepareerd: XXXXX
    \r\nZodra je het apparaat thuis hebt kunnen testen, stellen wij een korte feedback over het gerepareerde product op prijs.
    \r\nMocht je nog vragen hebben, dan helpen wij je graag verder.
    \r\nMet vriendelijke groet
    `,
    content_sv: `Hej XXXX!
    \r\nTack för ditt tålamod.
    \r\nDin enhet har reparerats och har precis returnerats till dig. Du kan spåra leveransen med följande länk: XXXXXXXXXX
    \r\nVi hittade och reparerade följande fel: XXXXX
    \r\nSå snart du har fått möjlighet att testa enheten skulle vi uppskatta en kort återkoppling om den reparerade produkten.
    \r\nOm du har några ytterligare frågor hjälper vi dig gärna också!
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  },
  {
    id: 8,
    name: 'generic.return_receipt_repair/exchange',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXXX,
    \r\nvielen Dank für Deine Rücksendung und Geduld.
    \r\nDein refurbed XXXX ist heute bei uns eingegangen.
    \r\nWir werden das Gerät nun auf Herz und Nieren prüfen und es dementsprechend reparieren bzw. austauschen. Dabei werden wir vor allem auf den von Dir beschriebenen Defekt: "XXXXXXXXXXXX" achten.
    \r\nSollten Dir noch weitere Probleme, Defekte oder Ergänzungen zum bereits bekannten Schaden ergeben haben, gib uns bitte hier Bescheid.
    \r\nJe nach Art des Defekts und der Verfügbarkeit benötigter Teile sollten wir Dein Gerät innerhalb der nächsten X-X Werktage reparieren können.
    \r\nSollte eine Reparatur nicht möglich sein, schicken wir Dir spätestens in XX Werktagen ein entsprechendes Austauschgerät zurück.
    \r\nSobald Dein repariertes Gerät oder Dein Austauschgerät versandfertig ist, werden wir Dir hier nochmals gesondert Bescheid geben.
    \r\nSollten sich Deinerseits inzwischen noch weitere Fragen ergeben, sind wir hier jederzeit gerne für Dich da.
    \r\nLiebe Grüße`,
    content_da: `Hej XXXX!
    \r\nTak for din retur og tålmodighed.
    \r\nVi har modtaget din enhed i dag.
    \r\nVi vil nu tjekke enheden efter for fejl og reparere enheden I den forbindelse. I den proces vil vi være særligt opmærksomme på den følgende defekt, du har beskrevet: "XXXXXXXXXXXXXXXXXXXX".
    \r\nHvis produktet har yderligere problemer eller fejl eller du gerne vil tilføje nogle informationer om de allerede kendte skader, beder vi dig give os besked her.
    \r\nAfhængigt af typen af defekt og tilgængeligheden af de nødvendige reservedele bør vi kunne reparere din enhed inden for de næste X-X arbejdsdage.
    \r\nHvis en reparation ikke er mulig, sender vi dig en passende erstatningsenhed senest inden for XX arbejdsdage.
    \r\nSå snart din reparerede enhed eller din erstatningsenhed er klar til afsendelse, vil du modtage besked via e-mail.
    \r\nHvis du har yderligere spørgsmål, er du mere end velkommen til at kontakte os igen.
    \r\nVi ønsker dig en god dag/aften/weekend
    \r\nMed venlig hilsen`,
    content_en: `Hello XXXX!
    \r\nThank you for your return and patience.
     \r\nWe received your refurbed XXXX today.
     \r\nWe will now put the unit through its paces and repair it accordingly. In doing so, we will pay particular attention to the defect you described: "XXXXXXXXXXXX".
     \r\nIf the product has any further problems, defects or additions to the already known damage, please let us know here.
     \r\nDepending on the type of defect and the availability of the necessary parts, we should be able to repair your device within the next X-X working days.
     \r\nIf a repair is not possible, we will send you an appropriate replacement device within XX working days at the latest.
     \r\nAs soon as your repaired device or your replacement device is ready for dispatch, you will receive a notification via e-mail.
     \r\nIf you have any further questions, do not hesitate to contact us again.
     \r\nWe wish you a nice day/evening/weekend
     \r\nAll the best
    `,
    content_it: `Gentile XXXX,
    \r\ngrazie per aver spedito il dispositivo.
    \r\nCon la presente le confermiamo la ricezione del Suo prodotto in data odierna.
    \r\nI nostri tecnici si occuperanno di esaminare con cura il dispositivo, ponendo particolare attenzione al malfunzionamento da Lei segnalato: XXXX
    \r\nSe dovesse avere ulteriori informazioni o dettagli al problema sopraccitato, Le chiediamo gentilmente di comunicarcelo.
    \r\nA seconda del malfunzionamento e della disponibilità dei pezzi di ricambio necessari, dovremmo essere in grado di riparare il suo dispositivo entro i prossimi X-X giorni lavorativi.
    \r\nNel caso in cui il prodotto non fosse riparabile, Le invieremo un dispositivo sostitutivo con le stesse specifiche del prodotto da Lei acquistato.
    \r\nA riparazione o sostituzione completata, riceverà una notifica tramite e-mail.
    \r\nPer ulteriori domande non esiti a contattarci nuovamente.
    \r\nLe auguriamo una buona giornata/serata/un buon fine settimana.
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXXX,
    \r\nBedankt voor je geduld.
    \r\nWe hebben jouw toestel vandaag ontvangen.
    \r\nWe zullen het apparaat nu testen en repareren. Daarbij zullen we bijzondere aandacht besteden aan het door jou beschreven defect: "XXXXXXXXXXXX".
    \r\nMocht het product nog meer problemen, defecten of aanvullingen op de reeds bekende schade vertonen, laat het ons dan hier weten.
    \r\nAfhankelijk van het defect en de beschikbaarheid van de benodigde onderdelen zouden wij jouw apparaat binnen de komende X-X werkdagen moeten kunnen repareren.
    \r\nAls een reparatie niet mogelijk is, sturen wij jou uiterlijk binnen XX werkdagen een passend vervangend apparaat toe.
    \r\nZodra jouw gerepareerde apparaat of jouw vervangende apparaat klaar is voor verzending, ontvang je een bericht via e-mail.
    \r\nMocht je nog meer vragen hebben, aarzel dan niet om opnieuw contact met ons op te nemen.
    \r\nWij wensen je een fijne dag/avond/weekend.
    \r\nMet vriendelijke groet,
    `,
    content_sv: `Hej XXXX!
    \r\nTack för din retur och ditt tålamod.
    \r\nVi har idag tagit emot din enhet. Vi kommer nu att testa och reparera enheten. När vi gör det kommer vi att ägna särskild uppmärksamhet åt det fel du beskrivit: "XXXXXXXXXXXXXXXX".
    \r\nOm produkten har ytterligare problem, defekter eller tillägg till den redan kända skadan, vänligen meddela oss detta här.
    \r\nBeroende på typen av defekt och tillgången av nödvändiga delar bör vi kunna reparera din enhet inom de närmaste X-X arbetsdagarna.
    \r\nOm en reparation inte är möjlig skickar vi dig en lämplig ersättningsenhet senast inom XX arbetsdagar.
    \r\nSå snart din reparerade enhet eller din ersättningsenhet är klar för avsändning får du ett meddelande via e-post.
    \r\nOm du har ytterligare frågor, tveka inte på att kontakta oss igen.
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  },
  {
    id: 9,
    name: 'generic.return_instructions_ask_for_pictures',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXXXXX,
    \r\ndanke für deine Nachricht. Es tut uns leid zu hören, dass du Probleme mit Deinem Gerät hast. Bitte entschuldige die Unannehmlichkeiten.
    \r\nGerne helfen wir dir dabei. Kannst Du uns für eine erste Einschätzung bitte Fotos der Vorder- und Rückseite sowie der vier Seiten und Ecken schicken?
    \r\nDadurch können wir sicherstellen, dass das Gerät noch den Garantiebedingungen entspricht. Wir möchten dadurch vermeiden, dass Dir hier unnötiger Aufwand für Dich entsteht.
    \r\nMehr Infos zu den Garantiebedingungen findest Du auch hier: https://www.refurbed.de/warranty/
    \r\nSobald die Fotos vorliegen, leiten wir die Retoure in die Wege, um Dein Gerät so rasch als möglich reparieren zu können.
    \r\nAnsonsten wünschen wir Dir einen schönen Tag. :-)
    \r\nLiebe Grüße`,
    content_da: `Hej XXXXXX!
    \r\nTak for din besked. Vi er kede af at høre, at du har problemer med din enhed. Vi undskylder for ulejligheden.
    \r\nVi vil med glæde hjælpe dig med dette. Kan du venligst sende os billeder af forsiden, bagsiden, de fire sider og hjørnerne så vi kan lave en foreløbig vurdering?
    \r\nDette vil hjælpe os med at sikre, at produktet stadig er i overensstemmelse med garantibetingelserne. Vi ønsker at undgå unødige besværligheder for dig.
    \r\nFor yderligere oplysninger om garantibetingelserne kan du klikke på følgende link: https://www.refurbed.dk/garanti/
    \r\nNår vi har modtaget billederne, igangsætter vi returen, så vi kan reparere din enhed så hurtigt som muligt. Tak!
    \r\nVi ønsker dig en dejlig dag.
    \r\nMed venlig hilsen`,
    content_en: `Hello XXXXXX!
    \r\nThank you for your message. We are sorry to hear that you are having problems with your device. Please accept our apologies for the inconvenience.
    \r\nWe will be happy to help you with this. Can you please send us photos of the front, back, four sides and corners for an initial assessment?
    \r\nThis will help us make sure the product is still in compliance with the warranty conditions. We want to avoid unnecessary efforts for you.
    \r\nFor further information about the warranty conditions click on the following link: https://www.refurbed.ie/warranty/
    \r\nOnce we have received the pictures, we will initiate the return in order to repair your device as quickly as possible. Thank you!
    \r\nWe wish you a lovely day.
    \r\nBest regards
    `,
    content_it: `Gentile XXXXXX,
    \r\ngrazie per il Suo messaggio.
    \r\nCi dispiace che il Suo dispositivo presenti un malfunzionamento e, pertanto, La preghiamo di accettare le nostre più sentite scuse.
    \r\nPotrebbe gentilmente inviarci delle foto del fronte, retro, dei quattro lati e degli angoli del dispositivo per una valutazione iniziale?
    \r\nPuò trovare maggiori informazioni sulle condizioni di garanzia al seguente link: https://www.refurbed.it/warranty/
    \r\nUna volta ricevute e analizzate le foto da Lei inviateci, avvieremo la procedura di restituzione al fine di riparare il suo dispositivo il più rapidamente possibile.
    \r\nNel frattempo, le auguriamo una buona giornata :-)
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXXXXX,
    \r\nBedankt voor je bericht. Het spijt ons om te horen dat je problemen hebt met jouw product Onze excuses voor het ongemak.
    \r\nWij helpen je graag om dit op te lossen. Kun je ons foto's sturen van de voorkant, achterkant, vier zijkanten en hoeken van het product voor een eerste beoordeling?
    \r\nZo kunnen wij controleren of het product nog aan de garantievoorwaarden voldoet. Wij willen je daarmee onnodige verdere moeite besparen.
    \r\nKlik op de volgende link voor meer informatie over de garantievoorwaarden: https://www.refurbed.nl/garantie/
    \r\nZodra wij de foto's hebben ontvangen, zullen wij de retourzending in gang zetten om jouw apparaat zo snel mogelijk te repareren. Alvast bedankt.
    \r\nWij wensen je een fijne dag.
    \r\nMet vriendelijke groet,
    `,
    content_sv: `Hej XXXXXX!
    \r\nTack för ditt meddelande. Vi beklagar att du har problem med din enhet och ber om ursäkt för besväret.
    \r\nVi ska se till att hjälpa dig så gott vi kan! Har du möjlighet att skicka över några bilder av framsidan, baksidan, de fyra sidorna och hörnen för en första bedömning? Detta hjälper oss att se till att produkten fortfarande uppfyller garantivillkoren.
    \r\nFör mer information om garantivillkoren klicka på följande länk: https://www.refurbed.se/warranty/
    \r\nSå fort vi har fått bilderna kommer vi att påbörja returen för att reparera din enhet så snabbt som möjligt.
    \r\nVi önskar dig en härlig dag!
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  },
  {
    id: 10,
    name: 'generic.return_receipt_for_refund',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXX,
    \r\nvielen Dank für Deine Geduld.
    \r\nDein refurbed XXXX ist heute bei uns eingegangen. Wir werden es nun einer kurzen Prüfung unterziehen. Spätestens XXXX sollte unser Technikteam diesen Prozess abgeschlossen haben.
    \r\nSobald das erledigt ist, werden wir Dir wie gewünscht den Kaufbetrag erstatten. Das Geld sollte innerhalb der nächsten XX Werktage auf Deinem Konto eingehen.
    \r\nWir möchten uns nochmals dafür entschuldigen, dass unser Produkt nicht Deinen Ansprüchen genügte. Wir hoffen sehr, dass Du ein Gerät findest, das genau so ist, wie du es Dir wünschst.
    \r\nSolltest Du binnen 10+XX Werktagen das Geld nicht auf Deinem Konto haben, gib uns bitte Bescheid, damit wir die KollegInnen von refurbed um Unterstützung bitten können.
    \r\nSolltest Du in der Zwischenzeit noch Fragen haben, stehen auch wir Dir jederzeit gerne zur Verfügung.
    \r\nWir wünschen Dir alles Gute!
    \r\nLiebe Grüße`,
    content_da: `Kære XXX!
    \r\nMange tak for din tålmodighed.
    \r\nVi har modtaget din enhed i dag. Vi vil teste din enhed. Vores tekniske team skulle have afsluttet denne proces senest den XXXX.
    \r\nSå snart dette er gjort, vil vi igangsætte din refusion som ønsket. Beløbet vil blive krediteret din bankkonto/kort inden for de næste XX arbejdsdage.
    \r\nVi vil gerne endnu en gang undskylde, at vores produkt ikke levede op til dine forventninger. Vi ville påskønne, hvis du gav os en ny chance for at bevise kvaliteten af vores produkter.
    \r\nHvis du ikke modtager refusionen inden for XXXX10+vurderede arbejdsdage, beder vi dig give os besked, så vi kan bede vores refurbed-kolleger om hjælp.
    \r\nTøv ikke med at kontakte os, hvis du har yderligere spørgsmål.
    \r\nVi ønsker dig en god dag/aften/weekend.
    \r\nMed venlig hilsen`,
    content_en: `Dear XXX!
    \r\nMany thanks for your patience.
    \r\nWe received your refurbed XXXX today. We will test your device. Our technical team should have completed this process by XXXX at the latest.
    \r\nAs soon as this is done, we will issue a refund as requested. The amount will be credited to your bank account/card within the next XX business days.
    \r\nWe want to apologize again that our product did not meet your expectations. We would be glad, if you gave us a second chance to prove the quality of our products.
    \r\nIf you do not receive the refund within XXXX10+estimated working days, please let us know so we can ask the refurbed colleagues for support.
    \r\nPlease do not hesitate to contact us, if you have any further questions.
    \r\nWe wish you a nice day/evening/weekend.
    \r\nAll the best
    `,
    content_it: `Gentile XXX,
    \r\ngrazie mille per la Sua pazienza.
    \r\nAbbiamo ricevuto il suo XXXX in data odierna. Il nostro reparto tecnico effettuerà i test di routine sul dispositivo.
    \r\nUna volta conclusa questa fase, verrà emesso il rimborso dell’importo d’acquisto come da Sua richiesta. Il rimborso dovrebbe sarà riaccreditato sul Suo conto entro i prossimi XX giorni lavorativi.
    \r\nCi rincresce molto che il nostro prodotto non abbia soddisfatto le Sue aspettative. Saremmo più che felice, se decidesse di darci una seconda possibilità di mostrarLe la qualità dei nostri prodotti.
    \r\nSe non dovesse ricevere il rimborso entro i prossimi XXXX10+giorni lavorativi, La preghiamo gentilmente di contattare il servizio clienti refurbed.
    \r\nPer ulteriori domande non esiti a contattarci nuovamente.
    \r\nLe auguriamo una buona giornata/serata/un buon fine settimana.
    \r\nCordiali saluti
    `,
    content_nl: `Beste XXX,
    \r\nBedankt voor je geduld.
    \r\nWe hebben jouw toestel vandaag ontvangen, we gaan nu jouw toestel testen. Ons technisch team zou dit proces uiterlijk op XXXX moeten hebben afgerond.
    \r\nZodra dit is gebeurd, zullen wij het gevraagde bedrag terugstorten. Het bedrag zal binnen de komende XX werkdagen op jouw bankrekening/kaart worden bijgeschreven.
    \r\nWij willen ons nogmaals verontschuldigen dat ons product niet aan jouw verwachtingen voldeed. We zouden blij zijn als je ons een tweede kans geeft om de kwaliteit van onze producten te bewijzen.
    \r\nAls je de terugbetaling niet binnen XXXX10+geschatte werkdagen ontvangt, laat het ons dan weten zodat we de refurbed collega's om ondersteuning kunnen vragen.
    \r\nAarzel niet om contact met ons op te nemen als je nog vragen hebt.
    \r\nWij wensen je een fijne dag/avond/weekend.
    \r\nMet vriendelijke groet
    `,
    content_sv: `Hej XXX!
    \r\nTack för ditt tålamod.
    \r\nVi har idag tagit emot din enhet idag och kommer att påbörja tester av den. Vårt tekniska team bör ha slutfört denna process senast den XXXX.
    \r\nSå snart detta är gjort kommer vi att utfärda en återbetalning enligt din begäran. Beloppet kommer att krediteras till ditt bankkonto/kort inom de närmaste XX arbetsdagarna.
    \r\nVi vill återigen be om ursäkt för att vår produkt inte motsvarade dina förväntningar. Vi skulle bli riktigt glada om du vill ge oss en andra chans att bevisa kvaliteten på våra produkter.
    \r\nOm du inte fått återbetalningen inom XXXX 10 arbetsdagar ber vi dig meddela oss så att vi kan be våra refurbed-kollegor om hjälp.
    \r\nTveka inte på att kontakta oss om du har ytterligare frågor.
    \r\nVi önskar dig en trevlig dag/kväll/helg.
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  }, {
    id: 11,
    name: 'generic.shipment_tracking',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXX,
    \r\nvielen Dank für Deine Nachricht.
    \r\nMit dem folgenden Link / Mit folgender Sendungsnummer kannst du Dein Paket auf der Webseite von DHL/UPS/GLS verfolgen:
    \r\nBei weiteren Fragen stehen wir gerne zur Verfügung.
    \r\nWir wünschen Dir einen schönen Tag!
    \r\nLiebe Grüße`,
    content_da: `Kære XXX,
    \r\nmange tak for at du kontaktede os
    \r\n Med følgende link/sporingsnummer kan du spore din forsendelse:
    \r\nTøv ikke med at kontakte os, hvis du har yderligere spørgsmål.
    \r\nVi ønsker dig en god dag.
    \r\nMed venlig hilsen`,
    content_en: `Dear XXX,
    \r\nmany thanks for contacting us.
    \r\nWith the following link/number you can track your shipment:
    \r\nDo not hesitate to contact us, if you have any further questions.
    \r\nWe wish you a nice day.
    \r\nAll the best
    `,
    content_it: `Gentile XXX,
    \r\nLa ringraziamo per averci contattati.
    \r\nCon il seguente codice/link può monitorare lo stato della spedizione sulla pagina di DHL/UPS/GLS.
    \r\nIn caso di ulteriori domande non esiti a contattarci nuovamente.
    \r\nLe auguriamo una buona giornata.
    \r\nCordiali saluti
    `,
    content_nl: `Beste XXX,
    \r\nHartelijk dank dat u contact met ons heeft opgenomen.
    \r\nMet de volgende link/nummer kun je jouw zending volgen:
    \r\nAarzel niet om contact met ons op te nemen als je nog meer vragen heeft.
    \r\nWij wensen je een fijne dag.
    \r\nGroeten
    `,
    content_sv: `Hej XXX,
    \r\nTack för att din kontakt!
    \r\nMed följande länk/nummer kan du spåra din försändelse:
    \r\nTveka inte på att kontakta oss om du har ytterligare frågor!
    \r\nHa en fantastisk dag!
    \r\nBästa hälsningar,`,
    available_languages: availableLanguages,
  },
  {
    id: 12,
    name: 'generic.conversation_outside_rti',
    audience: 'refurbed',
    published: false,
    content_de: `Liebes refurbed Team,
    \r\nWir haben heute mit dem Kunden/mit der Kundin außerhalb eures Systems telefonisch kommuniziert. Hier eine kurze Zusammenfassung des Gesprächs:
    \r\nDatum, Uhrzeit:
    \r\nGesprächspartner:
    \r\nTelefonnummer:
    \r\nInhalt:
    \r\nBei weiteren Fragen stehen wir gerne zur Verfügung.
    \r\nLiebe Grüße`,
    content_da: `Kære team,
    \r\nVi har været i kontakt med kunden i dag via telefon uden for jeres system. Her er et kort resumé af samtalen:
    \r\nDato, klokkeslæt:
    \r\nTalte med:
    \r\nTelefonnummer:
    \r\nSamtalens indhold:
    \r\nTøv ikke med at kontakte os for yderligere oplysninger.
    \r\nMed venlig hilsen`,
    content_en: `Dear refurbed team,
    \r\nWe communicated with the customer today via phone outside of your system. Here is a brief summary of the conversation:
    \r\nDate, Time:
    \r\nTalked to:
    \r\nPhone number:
    \r\nContent:
    \r\nDo not hesitate to contact us for further information.
    \r\nAll the best
    `,
    content_it: `Caro team di refurbed,
    \r\nIn data odierna abbiamo comunicato con il cliente/la client al di fuori del vostro sistema. Ecco un breve riassunto della conversazione:
    \r\nData, ora:
    \r\nparlato con:
    \r\nNumero di telefono:
    \r\nContenuto:
    \r\nPer qualsiasi domanda non esitate a contattarci,
    \r\nCordiali saluti
    `,
    content_nl: `Beste refurbed team,
    \r\nWe hebben vandaag met de klant gecommuniceerd via de telefoon (buiten jullie systeem om).
    \r\nHier is een korte samenvatting van het gesprek:
    \r\nDatum, tijd:
    \r\nGesproken met:
    \r\nTelefoonnummer:
    \r\nInhoud:
    \r\nAarzel niet om contact met ons op te nemen voor verdere informatie.
    \r\nGroeten
    `,
    content_sv: `Bästa team,
    \r\nVi kommunicerade med kunden idag via telefon utanför ert system. Här är en kort sammanfattning av samtalet:
    \r\nDatum, tid:
    \r\nTalade med:
    \r\nTelefonnummer:
    \r\nInnehåll:
    \r\nTveka inte på att kontakta oss för ytterligare information.
    \r\nBästa`,
    available_languages: availableLanguages,
  },
  {
    id: 13,
    name: 'generic.warranty_exclusion',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXXXX,
    \r\nVielen Dank für Deine Rücksendung.
    \r\nWir haben diese erhalten und inzwischen auch geprüft. Bedauerlicherweise müssen wir Dir mitteilen, dass Dein Gerät nicht mehr durch die refurbed Garantie gedeckt ist.
    \r\nBei Deinem Gerät wurde XXXX ein Sturzschaden/ ein Wasserschaden/ starke äußerliche Gewalteinwirkung/ eine unbefugte Öffnung/ unsachgemäße Nutzung/ eine nicht vom Hersteller zugelassene Betriebssoftware/ OtherXXXX festgestellt. Unseren Garantiebedingungen entsprechend handelt es sich hierbei um einen Garantieausschlussgrund. Daher müssen wir Deinen Garantieantrag leider ablehnen. Hier kannst Du unsere Garantiebedingungen nochmals nachlesen: https://www.refurbed.at/warranty/
    \r\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nWir verstehen natürlich, dass das für Dich eine unangenehme Situation ist und Du dringend wieder ein funktionstüchtiges Gerät benötigst. Gerne möchten wir Dir daher eine kostenpflichtige Reparatur anbieten. Im Anfang findest Du einen detaillierten Kostenvoranschlag.//Leider kann das Gerät von uns nicht repariert werden. Wir müssen Dir daher das Gerät in Kulanz kostenlos zurückschicken. // Da wir in Deinem Fall ob seiner Beschaffenheit davon ausgehen müssen, dass Du beim Versand bereits Kenntnis über den Garantieausschlussgrund hattest, müssen wir Dich bitten, für unseren Aufwand aufzukommen. Im Anhang findest Du eine Rechnung für die Versandkosten und unsere Arbeitszeit. Wir senden Dir dann umgehend Dein Gerät zurück. XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nWir bitten um Dein Verständnis, dass wir an die refurbed Garantiebedingungen gebunden sind und wünschen Dir trotz der Unannehmlichkeiten einen schönen Tag.
    \r\nWenn Du noch Fragen dazu hast, sind wir jederzeit gerne für Dich da.
    \r\nLiebe Grüße`,
    content_da: `Hej XXXXX!
    \r\n Mange tak for din tilbagemelding.
    \r\nVi har modtaget din enhed og vi har nu lavet et tjek af den. Desværre må vi oplyse dig om at refurbed-garantien ikke længere dækker din enhed.
    \r\nDin enhed har en skade efter fald/ vandskade/robust ydre kraft/ uautoriseret åbning/ ukorrekt brug/ driftssoftware, der ikke er godkendt af producenten/ andetXXXXXX. I henhold til vores garantibetingelser er dette en årsag til udelukkelse fra garantien. Derfor er vi desværre nødt til at afvise dit garantikrav. Du kan finde vores refurbed garantibetingelser her: https://www.refurbed.dk/garanti/
    \r\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nVi forstår, at dette er en ubehagelig situation for dig, og at du har et stort behov for en fungerende enhed. Derfor vil vi gerne tilbyde dig en reparation mod betaling. Vedhæftet finder du et detaljeret estimat for omkostningerne ved en mulig reparation./ Desværre kan enheden ikke repareres. Derfor er vi nødt til at sende enheden tilbage til dig. Det gør vi gratis som en gestus af god vilje. // Da vi må gå ud fra, at du var klar over udelukkelsen fra garantien da du sendte enheden, må vi bede dig betale vores udgifter i den forbindelse. I vedhæftede fil finder du en faktura for forsendelsesomkostningerne og vores arbejdstid. Når vi har modtaget betalingen, sender vi straks enheden tilbage til dig XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nVi beder om din forståelse for, at vi er bundet af refurbed garantibetingelserne og ønsker dig en god dag på trods af ulejligheden.
    \r\nHvis du har nogen spørgsmål, er du velkommen til at kontakte os.
    \r\nMed venlig hilsen`,
    content_en: `Hello XXXXX!
    \r\nMany thanks for your return.
    \r\nWe have received your device and checked it in the meantime. Unfortunately, we have to inform you that the refurbed warranty no longer covers your device.
    \r\nYour device has a fall damage/ water damage/robust external force/ unauthorized opening/ improper use/ operating software not approved by the manufacturer/ OtherXXXX. According to our warranty conditions, this is a warranty exclusion reason. Therefore, we, unfortunately, have to reject your warranty claim. You can find our refurbed warranty conditions here: https://www.refurbed.ie/warranty/
    \r\nXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nWe understand that this is an unpleasant situation for you and that you urgently need a working device. Therefore, we would like to offer you a repair with costs. Attached you will find a detailed cost estimate./Unfortunately, the device cannot be repaired. Therefore, we have to send the device back to you free of charge as a gesture of goodwill. // Since we have to assume that you were aware of the warranty exclusion at the time of shipment, we have to ask you to pay for our expenses. In the attachment, you will find an invoice for the shipping costs and our working time. Once we have received the payment, we will send the device back to you immediately XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nWe ask for your understanding that we are bound to the refurbed warranty conditions and wish you a nice day despite the inconvenience.
    \r\nIf you have any questions, please do not hesitate to contact us.
    \r\nAll the best
    `,
    content_it: `Gentile XXXXX,
    \r\nLa ringraziamo per la restituzione del prodotto.
    \r\nIl reparto tecnico ha esaminato con cura il Suo dispositivo. Purtroppo, dobbiamo informarLa che il prodotto non è più coperto dalla nostra garanzia.
    \r\nIl Suo dispositivo presenta XXXX un danno da caduta/ un danno causato dall’acqua/ un forte impatto esterno/ un'apertura non autorizzata/ un uso improprio/ un sistema operativo non approvato dal produttore/ altroXXXX. Questo tipo di danno comporta la fine della validità della garanzia. Pertanto, dobbiamo purtroppo respingere la sua richiesta di riparazione coperta da garanzia. Per maggiori informazioni circa la nostre condizioni di garanzia refurbed clicchi sul seguente link: https://www.refurbed.it/warranty/
    \r\nXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nComprendiamo che questa è una situazione spiacevole per Lei e che necessita urgentemente di un dispositivo funzionante. Pertanto, vorremmo offrirLe una riparazione dietro pagamento. In allegato troverà un preventivo dei costi di riparazione. //Purtroppo, il dispositivo non può essere riparato. Pertanto, il prodotto Le verrà restituito, in via del tutto eccezionale, senza costi aggiuntivi. // Dal momento che il prodotto non è più coperto da garanzia, dobbiamo chiederLe di coprire le spese di spedizione, come indicato nelle nostre condizioni di garanzia. In allegato, troverà una fattura per le spese di spedizione e per il tempo di lavoro. Una volta ricevuto il pagamento, il prodotto Le verrà restituito.
    \r\nXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nLe chiediamo gentilmente di comprendere che le nostre condizioni di garanzia valgono indistintamente per tutti i nostri clienti e le nostre clienti, e non possiamo fare eccezioni.
    \r\nNonostante la spiacevole situazione Le auguriamo una buona giornata.
    \r\nPer qualsiasi domanda, non esiti a ricontattarci.
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXXXX,
    \r\nHartelijk dank voor de retourzending.
    \r\nWij hebben jouw toestel ontvangen en inmiddels ook gecontroleerd. Helaas moeten wij je meedelen dat de refurbed garantie jouw toestel niet meer dekt
    \r\nJouw apparaat heeft een valschade/ waterschade/ruw geweld van buitenaf/ ongeoorloofde opening/ ongepast gebruik/ niet door de fabrikant goedgekeurde besturingssoftware/ andereXXXX.
    \r\nVolgens onze garantievoorwaarden is dit een reden voor uitsluiting van garantie. Daarom moeten wij uw garantieclaim helaas afwijzen. Je vindt onze refurbed garantievoorwaarden hier: https://www.refurbed.nl/garantie/
    \r\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX Wij begrijpen dat dit een onaangename situatie voor jou is en dat je dringend een werkend apparaat nodig hebt. Daarom willen wij je graag een reparatie tegen betaling aanbieden. In de bijlage vind je een gedetailleerde kostenraming./ Helaas kan het apparaat niet gerepareerd worden. Daarom sturen wij het apparaat als gebaar van goede wil gratis terug // Aangezien wij ervan uit moeten gaan dat je op het moment van verzending op de hoogte was van de uitsluiting van de garantie, moeten wij je vragen onze kosten te vergoeden. In de bijlage vind je een factuur voor de verzendkosten en onze werktijd.   XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nWij vragen om je begrip voor het feit dat wij gebonden zijn aan de refurbed garantievoorwaarden en we wensen je ondanks het ongemak een fijne dag.
    \r\nMocht je vragen hebben, aarzel dan niet om contact met ons op te nemen.
    \r\nMet vriendelijke groeten
    `,
    content_sv: `Hej XXXXX!
    \r\nTack för din retur.
    \r\nVi har tagit emot din enhet och kontrollerat den. Tyvärr måste vi informera dig om att refurbed-garantin inte längre täcker din enhet.
    \r\nDin enhet har varit med om en fallskada/ vattenskada/robust yttre slag/ obehörig öppning/ felaktig användning/ driftsprogramvara som inte är godkänd av tillverkaren/ AnnatXXXX. Enligt våra garantivillkor är detta en orsak till uteslutning av garantin. Därför måste vi tyvärr avslå ditt garantianspråk. Du hittar våra refurbed™ garantivillkor här: https://www.refurbed.se/warranty/
    \r\nXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nVi förstår att detta är en jobbig situation för dig och att du snarast behöver en fungerande enhet. Därför vill vi erbjuda dig en reparation mot en kostnad Bifogat hittar du en detaljerad kostnadsberäkning./ Tyvärr kan enheten inte repareras. Därför kommer vi att skicka tillbaka enheten till dig, utan kostnad. // Eftersom vi måste anta att du var medveten om att garantin var utesluten vid tidpunkten för avsändningen måste vi be dig betala våra kostnader. I bilagan hittar du en faktura för fraktkostnaderna och vår arbetstid. När vi har mottagit betalningen skickar vi tillbaka enheten till dig omedelbart XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
    \r\nVi måste anpassa oss till refurbeds garantivillkor och ber därför om din förståelse!
    \r\nOm du har några frågor, tveka inte på att kontakta oss.
    \r\nVi önskar dig en trevlig dag trots besväret!
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  },
  {
    id: 14,
    name: 'generic.warranty_return',
    audience: 'refurbed',
    published: false,
    content_de: `Hallo XXXX,
    \r\nvielen Dank für Deine Nachricht.
    \r\nWir bestätigen hiermit den Erhalt Deiner Beanstandung. Es tut uns sehr leid, dass es bei Deinem refurbed XXXX zu einem Defekt kam. Das entspricht absolut nicht unserem Qualitätsanspruch. Bitte entschuldige die Unannehmlichkeiten.
    \r\nXXXXXXXXXX Im Anhang findest du Dein kostenloses Retourenlabel.//Wir haben Dir Dein kostenloses Retourlabel in einer gesonderten E-Mail zugesandt.//Über den folgenden Link kannst Du Dein kostenloses Retourlabel erstellen. XXXXXXXXXXXXX
    \r\nBitte versende das Gerät so, wie du es von uns erhalten hast, möglichst mit allem im Lieferumfang enthaltenen Zubehör und im ursprünglichen Lieferkarton. Alternativ verpacke das Gerät bitte gut ausgepolstert und in einem ausreichend großen Versandkarton!
    \r\nDenk bitte auch daran, dass Du Deine Schutzhülle und andere von Dir angebrachte Accessoire entfernst, bevor Du uns dein Gerät zukommen lässt. Da wir nicht garantieren können, dass du dieses Zubehör auch zurückerhältst. Sollte dies aus Gründen nicht möglich sein, dann besprich das bitte vorab hier mit uns.
    \r\nBitte sende das Gerät innerhalb von 14 Tagen ab heute (=Erhalt des Retourlabels) zurück und bewahre den Einlieferungsbeleg bitte gut auf.
    \r\nSobald Dein Gerät bei uns angekommen ist, werden wir es einer Prüfung unterziehen und es rasch wie möglich reparieren oder austauschen. Sobald das Gerät fertig und auf dem Weg zu Dir ist, geben wir Dir hier Bescheid.
    \r\nBitte vergiss nicht vor dem Rückversand den Zugangscode zu entfernen, das Gerät auf die Werkseinstellungen zurückzusetzen und es gegebenenfalls aus der iCloud zu löschen.
    \r\nXXXXXXXXXXXXXXX
    \r\nHier eine Anleitung zur Löschung Deines Geräts aus der iCloud:
    \r\n1. Gehe zu "Einstellungen" > "[dein Name]".
    \r\n2. Scrolle nach unten und tippe auf "Abmelden"
    \r\n3. Gib das Passwort für deine Apple-ID ein und tippe auf "Deaktivieren".
    \r\n4. Tippe auf "Abmelden".
    \r\n5. Tippe erneut auf "Abmelden", um zu bestätigen, dass du dich bei iCloud abmelden möchtest.
    \r\nVideoanleitung:
    \r\nhttps://www.youtube.com/watch?v=5D2URU5zdUU&feature=emb_imp_woyt XXXXXXXXXXXXXX
    \r\nWir bitten um Verständnis, dass wir Geräte, welche für eine Reparatur eingesendet werden und die noch eine angemeldete iCloud/Google Account haben, nicht reparieren können. In so einem Fall müssen wir das Gerät auf Deine Kosten an Dich retournieren.
    \r\nWeiters bitten wir um Dein Verständnis, dass falls bei dem Gerät Garantieausschlussgründe laut unserer Garantiebedingungen vorliegen und Du es dennoch einsendest, die Reparatur nicht durchgeführt werden kann und Du die Kosten für den Versand und unseren Aufwand tragen musst.
    \r\nHier noch Links zu unseren Garantie- und Rücksendebedingungen sowie zu unseren FAQs:
    \r\nhttps://www.refurbed.de/wie-produkte-zurucksenden/
    \r\nhttps://www.refurbed.de/warranty/
    \r\nhttps://www.refurbed.de/fragen-und-antworten/
    \r\nLiebe Grüße`,
    content_da: `Hej XXXX
    \r\nTak for din besked.
    \r\nVi bekræfter, at vi har modtaget din reklamation. Vi er meget kede af, at din enhed viser denne defekt. Dette er absolut ikke i overensstemmelse med vores kvalitetsstandarder. Vi undskylder for ulejligheden.
    \r\nI vedhæftede bilag finder du din gratis returlabel.//Vi har sendt dig din gratis returlabel i en separat e-mail.//Brug følgende link for at oprette din gratis returlabel.
    \r\nVi beder dig venligst sende enheden, på samme måde som du har modtaget den fra os. Hvis det er muligt beder vi dig sende den med alt tilbehør inkluderet og i den originale emballage, der blev brugt ved levering. Alternativt bedes du pakke enheden i en tilstrækkelig stor kasse il forsendelse og sørge for at bruge tilstrækkeligt beskyttelsesmateriale.
    \r\nHusk at fjerne dit etui eller cover og eventuelt andet tilbehør, du har sat på enheden - før du sender os din enhed. Vi kann ikke kan garantere, at du ellers får dette tilbehør tilbage. Hvis dette af en eller anden grund ikke er muligt, bedes du informere os, og drøfte det på forhånd med os her.
    \r\nSend venligst enheden tilbage inden for 14 dage fra i dag (=fra modtagelse af returlabel) og opbevar kvitteringen for forsendelsen på et sikkert sted.
    \r\nNår vi har modtaget din enhed, vil vi kontrollere den og reparere eller erstatte den hurtigst muligt. Når enheden er klar og på vej til dig, giver vi dig besked.
    \r\nGlem ikke at fjerne eventuelle adgangskoder, nulstille enheden til fabriksindstillinger og slette den fra iCloud, hvis det er nødvendigt, inden du sender den tilbage.
    \r\nXXXXXXXXXXXXXXXXXXX
    \r\nHer er en vejledning i, hvordan du sletter din enhed fra din iCloud-konto:
    \r\n 1. Gå til "indstillinger" > "[dit navn]".
    \r\n2. rul ned, og tryk på "Logout".
    \r\n3. Indtast adgangskoden til dit Apple-id, og tryk på "Deaktiver".
    \r\n4. tryk på "Log ud".
    \r\n5. Tryk på "Log ud" igen for at bekræfte, at du vil logge ud af iCloud.
    \r\nXXXXXXXXXXXX
    \r\nVi beder dig venligst have forståelse for, at vi ikke kan reparere enheder, der er sendt til reparation, og som stadig har en logget ind på iCloud/Google-konto. I sådanne tilfælde er vi nødt til at returnere enheden til dig på din regning.
    \r\nDesuden beder vi om din forståelse for, at hvis garantien ikke dækker enheden i henhold til vores garantibetingelser (skader fra fald, vandskader, ydre vold osv. - for nærmere oplysninger se linket nedenfor), - men du alligevel sender den ind, kan vi ikke fortsætte med reparationen. I et sådant tilfælde skal du selv afholde omkostningerne til forsendelse og vores arbejdskraft.
    \r\nSe venligst nedenstående links til vores garanti- og returbetingelser samt til vores FAQ'er for mere detaljerede oplysninger:
    \r\nhttps://www.refurbed.dk/sadan-returnerer-du-dit-produkt/
    \r\nhttps://www.refurbed.dk/garanti/
    \r\nhttps://www.refurbed.dk/ofte-stillede-sporgsmal/
    \r\nMed venlig hilsen`,
    content_en: `Hello XXXX
    \r\nThank you for your message.
    \r\nWe confirm the receipt of your complaint. We are very sorry that your device shows this defect. This is absolutely not in line with our quality standards. We apologise for the inconvenience.
    \r\nXXXXXXXXXX In attachment you will find your free return label.//We have sent you your free return label in a separate email.//Use the following link to create your free return label. XXXXXXXXXXX
    \r\nPlease send the device as you received it from us, if possible, with all accessories included and in the original delivery box. Alternatively, please pack the device in a sufficiently large shipping box and make sure to use enough protective material.
    \r\nPlease remember to remove your protective case and any other accessories you have attached - before you send us your device. As otherwise, we cannot guarantee that you will get these accessories back. If this is not possible for any reason, please discuss it in advance with us here.
    \r\nPlease return the device within 14 days from today (=receipt of the return label) and keep the shipment receipt in a safe place.
    \r\nOnce we have receive your device, we will check it and repair or replace it as soon as possible. Once the device is ready, and on its way to you, we will let you know.
    \r\nPlease don't forget to remove any passwords, factory reset the device and delete it from iCloud, if necessary, before sending it back.
    \r\nXXXXXXXXXXXXX
    \r\nHere are instructions on how to delete your device from your iCloud account:
    \r\n1. go to "settings" > "[your name]".
    \r\n2. scroll down and tap "Logout".
    \r\n3. enter the password for your Apple ID and tap on "Deactivate".
    \r\n4. tap on "Sign out".
    \r\n5. Tap "Sign out" again to confirm that you want to sign out of iCloud.
    \r\nXXXXXXXXXX
    \r\nPlease understand that we cannot repair devices sent in for repair and that still have a signed-in iCloud/Google account. In such cases, we have to return the device to you at your expense.
    \r\nFurthermore, we ask for your understanding that if the warranty does not cover the device according to our warranty conditions (fall damage, water damage, external force, etc. - for details please see link below), - but you still send it in, we will not be able to proceed with the repair. For such a case, you will have to bear the costs for shipping and our effort.
    \r\nPlease check the below-mentioned links to our warranty and return conditions as well as to our FAQs for detailed information:
    \r\nhttps://www.refurbed.ie/how-to-return-your-product/
    \r\nhttps://www.refurbed.ie/warranty/
    \r\nhttps://www.refurbed.ie/frequently-asked-questions/
    \r\nAll the best
    `,
    content_it: `Gentile XXXX,
    \r\nGrazie per il suo messaggio.
    \r\nLe confermiamo di aver ricevuto il suo reclamo. Siamo molto dispiaciuti che abbia riscontrato un difetto con il suo XXXX refurbed. Questo non è assolutamente in linea con i nostri standard di qualità. La preghiamo di scusare l'inconveniente.
    \r\nXXXXXXXXXX In allegato, troverà la sua etichetta di restituzione gratuita.//Le abbiamo inviato la sua etichetta di restituzione gratuita in una e-mail separata.//Utilizzi il seguente link per creare la sua etichetta di restituzione gratuita. XXXXXXXXXXX
    \r\nLa preghiamo di inviare il dispositivo come lo ha ricevuto da noi, se possibile con tutti gli accessori inclusi e nella scatola di consegna originale. In alternativa si prega di imballare il dispositivo ben protetto e in una scatola di spedizione sufficientemente grande!
    \r\nSi ricordi di rimuovere la custodia protettiva e qualsiasi altro accessorio che ha attaccato prima di inviarci il suo dispositivo. In caso contrario non possiamo garantire la restituzione di questi accessori. Se questo non è possibile per qualsiasi motivo, si prega di discuterne in anticipo con noi qui.
    \r\nSi prega di restituire il dispositivo entro 14 giorni da oggi (=data di ricezione dell'etichetta di restituzione) e di conservare la ricevuta di spedizione in un luogo sicuro.
    \r\nUna volta ricevuto il dispositivo, lo controlleremo e lo ripareremo/sostituiremo il prima possibile. Quando il dispositivo sarà pronto e in viaggio verso di lei, le daremo aggiornamenti qui.
    \r\nLe ricordiamo di rimuovere il codice di accesso, fare un reset di fabbrica del dispositivo e se necessario di scollegarlo da iCloud prima di rimandarlo indietro.
    \r\nXXXXXXXXXXXXX
    \r\nEcco le istruzioni su come cancellare il suo dispositivo da iCloud:
    \r\n1. vada su "impostazioni" > "[il suo nome]".
    \r\n2. scorra verso il basso e tocchi "Logout".
    \r\n3. inserisca la password del suo ID Apple e tocchi "Disattiva".
    \r\n4. tocchi "Esci".
    \r\n5. Tocchi nuovamente "Esci" per confermare che desidera uscire da iCloud.
    \r\nXXXXXXXXXXXXXX
    \r\nLa preghiamo di comprendere che non possiamo riparare dispositivi ancora connessi a un account iCloud/Google. In questi casi, dobbiamo restituire il dispositivo a Sue spese.
    \r\nInoltre, le chiediamo di comprendere che se il dispositivo non rientra più nelle nostre condizioni di garanzia (a causa di danni da caduta, acqua, forza esterna, ecc. - per i dettagli si prega di vedere il link sottostante), - ma viene spedito comunque, non saremo in grado di procedere con la riparazione. In questo caso, dovrà sostenere le spese di spedizione e del nostro operato.
    \r\nSi prega di controllare i seguenti link per le nostre condizioni di garanzia e di restituzione e le nostre FAQ per informazioni dettagliate:
    \r\nhttps://www.refurbed.it/come-restituire-i-prodotti/
    \r\nhttps://www.refurbed.it/warranty/
    \r\nhttps://www.refurbed.it/domande-e-risposte/
    \r\nCordiali saluti
    `,
    content_nl: `Hallo XXXX,
    \r\nBedankt dat je contact met ons hebt opgenomen. We hebben je klacht ontvangen. Het spijt ons zeer dat jouw toestel dit defect vertoont. Dit is absoluut niet in overeenstemming met onze kwaliteitsnormen. Onze excuses voor het ongemak.
    \r\nIn bijlage vindt je jouw gratis retourlabel.//Wij hebben je jouw gratis retourlabel in een aparte e-mail gestuurd.//Gebruik de volgende link om jouw gratis retourlabel aan te maken.
    \r\nStuur het apparaat precies op zoals je het van ons hebt ontvangen, indien mogelijk met alle accessoires en in de originele verpakking. Je kunt het apparaat ook verpakken in een  verzenddoos die groot genoeg is, en ervoor zorgen dat je voldoende beschermend materiaal gebruikt.
    \r\nVergeet niet de beschermhoes en alle andere accessoires die je zelf hebt bevestigd te verwijderen - voordat je jouw apparaat toestuurt. Anders kunnen wij niet garanderen dat je deze accessoires terugkrijgt. Als dit om welke reden dan ook niet mogelijk is, bespreek dit dan vooraf met ons.
    \r\nStuur het apparaat binnen 14 dagen na vandaag (=ontvangst van het retourlabel) terug en bewaar het verzendbewijs goed.
    \r\nZodra wij jouw toestel hebben ontvangen, controleren wij het en repareren of vervangen het zo snel mogelijk. Zodra het apparaat klaar is en naar jou onderweg is, laten wij je dit weten.
    \r\nVergeet niet eventuele wachtwoorden te verwijderen, het toestel een fabrieksreset te geven en het uit iCloud te verwijderen, indien nodig, voordat je het terugstuurt.
    \r\nXXXXXXXXXXX
    \r\nHier vind je instructies voor het verwijderen van jouw apparaat uit jouw iCloud-account:
    \r\n1. ga naar "instellingen" > "[uw naam]".
    \r\n2. scroll naar beneden en tik op "uitloggen".
    \r\n3. voer het wachtwoord van uw Apple ID in en tik op "deactiveren".
    \r\n4. tik op "Afmelden".
    \r\n5. Tik nogmaals op "Afmelden" om te bevestigen dat je je wilt afmelden bij iCloud.
    \r\nXXXXXXXXXX
    \r\nWij vragen om jouw begrip voor het feit dat wij geen apparaten kunnen repareren die ter reparatie worden ingestuurd en die nog een aangemeld iCloud/Google-account hebben. In dergelijke gevallen moeten wij het apparaat op jouw kosten naar je terugsturen.
    \r\nVerder vragen wij om jouw begrip dat als de garantie het apparaat niet dekt volgens onze garantievoorwaarden (valschade, waterschade, geweld van buitenaf, enz. - voor details zie onderstaande link), - maar je het toch opstuurt, wij de reparatie niet kunnen uitvoeren. In een dergelijk geval zul je de kosten voor verzending en onze inspanningen moeten dragen.
    \r\nKijk op de onderstaande links naar onze garantie- en retourvoorwaarden en naar onze FAQ's voor gedetailleerde informatie:
    \r\nhttps://www.refurbed.nl/hoe-retourneer-ik-een-bestelling/
    \r\nhttps://www.refurbed.nl/garantie/
    \r\nhttps://www.refurbed.nl/veelgestelde-vragen/
    \r\nMet vriendelijke groeten
    `,
    content_sv: `Hej XXXX
    \r\nTack för ditt meddelande.
    \r\nVi bekräftar att vi mottagit ditt klagomål. Vi beklagar verkligen att din enhet uppvisar detta fel. Detta är absolut inte i linje med våra kvalitetsstandarder och vi ber om ursäkt för besväret.
    \r\nI bilagan hittar du din kostnadsfria returetikett.//Vi har skickat din kostnadsfria returetikett i ett separat e-mail.//Använd följande länk för att skapa din kostnadsfria returetikett.
    \r\nVänligen skicka enheten så som du fick den av oss, om möjligt, med alla tillbehör inkluderade och i den ursprungliga leveranslådan. Alternativt kan du packa enheten i en tillräckligt stor kartong och se till att använda tillräckligt med skyddsmaterial.
    \r\nKom ihåg att ta av skalet och eventuella andra accessoarer på enheten nnan du skickar den till oss. Vi kan nämligen inte garantera att du får tillbaka dessa tillbehör. Om detta inte är möjligt av någon anledning, vänligen meddela oss i förväg här.
    \r\nVänligen returnera enheten inom 14 dagar från och med idag (= mottagande av returetiketten) och spara kvittot av försändelsen.
    \r\nNär vi har tagit emot din enhet kommer vi att kontrollera den och reparera eller ersätta den så snart som möjligt. När enheten är klar och på väg till dig meddelar vi dig detta.
    \r\nGlöm inte att ta bort eventuella lösenord, fabriksåterställa enheten och radera den från iCloud, om det behövs, innan du skickar tillbaka den.
    \r\nHär finns instruktioner om hur du raderar enheten från ditt iCloud-konto:
    \r\nXXXXXXXXXXXXXXXXX
    \r\n1. Gå till "inställningar" > "[ditt namn]".
    \r\n2. Scrolla ned och klicka på "Logga ut".
    \r\n3. Ange lösenordet för ditt Apple-ID och klicka på "Deaktivera".
    \r\n4. Klicka på "Logga ut".
    \r\n5. Klicka på "Logga ut" igen för att bekräfta att du vill logga ut från iCloud.
    \r\nXXXXXXXXXXXX
    \r\nVi kan inte reparera enheter som skickas in för reparation och som fortfarande har ett inloggat iCloud/Google-konto. I sådana fall måste vi skicka tillbaka enheten till dig på din bekostnad.
    \r\nVidare ber vi om din förståelse för att om garantin inte täcker enheten enligt våra garantivillkor (fallskada, vattenskada, yttre slag osv. - för detaljer se länk nedan), - men du ändå skickar in den, kommer vi inte att kunna fortsätta med reparationen. I ett sådant fall måste du stå för kostnaderna för frakt och vårt arbete.
    \r\nKontrollera länkarna nedan till våra garanti- och returvillkor samt till våra vanliga frågor och svar för detaljerad information:
    \r\nhttps://www.refurbed.se/att-returnera-din-produkt/
    \r\nhttps://www.refurbed.se/warranty/
    \r\nhttps://www.refurbed.se/vanliga-fragor/
    \r\nBästa hälsningar`,
    available_languages: availableLanguages,
  },
];

const getMerchantTemplate = (templateName) => {
  const template = templates.find(t => t.name === templateName);
  return convertKeys(template);
};

const merchantMessageTemplates = messageTemplates.map(template => convertKeys(template));

export {
  merchantMessageTemplates,
  getMerchantTemplate,
};
