const monthDateLocalized = {
  'en-GB': 'MMMM dd',
  it: 'd MMMM',
  'de-AT': 'd. MMMM',
  de: 'd. MMMM',
  fr: 'd MMMM',
  sv: 'd MMMM',
  pl: 'd MMMM',
  da: 'd MMMM',
  nl: 'd MMMM',
  sk: 'd MMMM',
  si: 'd MMMM',
  es: 'd MMMM',
  default: 'd MMMM',
};

export const DateFormat = Object.freeze({
  DateTime: 'dd.MM.yyyy - HH:mm',
  Date: 'dd.MM.yyyy',
  Time: 'HH:mm',
  DayMonth: 'dd.MM',
  DayMonthLong: 'dd. MMMM',
  MonthDay: 'MMMM dd',
  DayName: 'EEE',
  DayNameLong: 'EEEE',
});

export const DateFormatLocalized = {
  DayMonth: (locale = 'de') => monthDateLocalized[locale] || monthDateLocalized.default,
};
