import DateTime from 'luxon/src/datetime';
import { formatCurrency } from '../services/currency';
import { DateFormat } from '../types/dateFormat';

function formatTime(val, format, locale = 'de') {
  return DateTime.fromISO(val, { setZone: false })
    .setLocale(locale)
    .toFormat(format);
}

export default {
  install(Vue) {
    Vue.filter('uppercase', (value) => {
      if (!value) {
        return '';
      }
      return value.toString().toUpperCase();
    });

    Vue.filter('formatCurrency', (value, currencyIso, languageIso, countryIso) => formatCurrency(value, currencyIso, languageIso, countryIso));

    // Read more at https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
    Vue.filter('formatDate', value => DateTime
      .fromISO(value, { setZone: false })
      .setLocale('en')
      .toFormat(DateFormat.Date));

    Vue.filter('formatTime', value => DateTime
      .fromISO(value, { setZone: false })
      .setLocale('en')
      .toFormat(DateFormat.Time));

    Vue.filter('formatDateTime',
      (jsDate, format = DateFormat.DateTime) => DateTime
        .fromISO(jsDate, { setZone: false })
        .setLocale('en')
        .toFormat(format));

    Vue.filter('formatDayMonth', value => formatTime(value, DateFormat.DayMonth));
    Vue.filter('formatDayMonthLong', (value, locale = 'de') => formatTime(value, DateFormat.DayMonthLong, locale));
    Vue.filter('formatDayName', (value, locale = 'de') => formatTime(value, DateFormat.DayName, locale));
    Vue.filter('formatMonthLongName', (value, locale = 'de') => formatTime(value, DateFormat.MonthDay, locale));
    Vue.filter('formatDayNameLong', (value, locale = 'de') => formatTime(value, DateFormat.DayNameLong, locale));
  },
};
