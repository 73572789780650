// Tickets
export const SET_TICKETS = 'SET_TICKETS';
export const SET_TICKET_FILTER = 'SET_TICKET_FILTER';
export const SET_STATISTICS = 'SET_STATISTICS';
export const SET_TICKET_STATUS_FILTER = 'SET_TICKET_STATUS_FILTER';
export const SET_SELECTED_TICKET = 'SET_SELECTED_TICKET';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';
export const SET_SORT_COLUMN = 'SET_SORT_COLUMN';
export const SET_TICKET_OFFSET = 'SET_TICKET_OFFSET';
export const SET_TICKET_LIMIT = 'SET_TICKET_LIMIT';
export const INCREASE_TICKET_OFFSET = 'INCREASE_TICKET_OFFSET';
export const SET_OFFSET = 'SET_OFFSET';
export const RESET_OFFSET = 'RESET_OFFSET';
export const RESET_LIMIT = 'RESET_LIMIT';
export const SET_RESPONSE_ITEM_COUNT = 'SET_RESPONSE_ITEM_COUNT';
export const RESET_RESPONSE_ITEM_COUNT = 'RESET_RESPONSE_ITEM_COUNT';
export const SET_LOADING = 'SET_LOADING';
export const SET_PANIC_MODE = 'SET_PANIC_MODE';
export const SET_OVERDUE = 'SET_OVERDUE';
export const SET_HELP_REQUEST_REASONS = 'SET_HELP_REQUEST_REASONS';
export const SET_LAST_REQUEST_HELP_REQUEST_REASONS = 'SET_LAST_REQUEST_HELP_REQUEST_REASONS';

// Tickets treeselect filter
export const SET_TICKETS_TREESELECT_FILTER_DOMAINS = 'SET_TICKETS_TREESELECT_FILTER_DOMAINS';
export const SET_TICKETS_TREESELECT_FILTER_EXPEDITE = 'SET_TICKETS_TREESELECT_FILTER_EXPEDITE';
export const SET_TICKETS_TREESELECT_FILTER_UNANSWERED = 'SET_TICKETS_TREESELECT_FILTER_UNANSWERED';
export const SET_TICKETS_TREESELECT_FILTER_UNASSIGNED = 'SET_TICKETS_TREESELECT_FILTER_UNASSIGNED';
export const SET_TICKETS_TREESELECT_FILTER_TOPICS = 'SET_TICKETS_TREESELECT_FILTER_TOPICS';
export const SET_TICKETS_TREESELECT_FILTER_MERCHANTS = 'SET_TICKETS_TREESELECT_FILTER_MERCHANTS';
export const SET_TICKETS_TREESELECT_FILTER_ASSIGNED_TO = 'SET_TICKETS_TREESELECT_FILTER_ASSIGNED_TO';
export const SET_TICKETS_TREESELECT_FILTER_VALUE = 'SET_TICKETS_TREESELECT_FILTER_VALUE';
export const SET_TICKETS_TREESELECT_FILTER_SELECTED_OPTIONS_BY_CONTEXT = 'SET_TICKETS_TREESELECT_FILTER_SELECTED_OPTIONS_BY_CONTEXT';
export const SET_TICKET_TOPICS = 'SET_TICKET_TOPICS';
// Ticket user interaction dialog mutations
export const SET_DTL_TICKET_DATA = 'SET_DTL_TICKET_DATA';
export const SET_DTL_ORDER_DATA = 'SET_DTL_ORDER_DATA';
export const RESET_DTL_STATE = 'RESET_DTL_STATE';
export const SET_ERR_MESSAGES = 'SET_ERR_MESSAGES';
export const SET_TICKET_STATUS = 'SET_TICKET_STATUS';
export const SET_TICKET_TOPIC = 'SET_TICKET_TOPIC';
export const SET_EXPEDITE_STATUS = 'SET_EXPEDITE_STATUS';
export const SET_ASSIGNED_USER_ID = 'SET_ASSIGNED_USER_ID';
export const APPEND_TICKET_MESSAGE = 'APPEND_TICKET_MESSAGE';
export const APPEND_TICKET_ATTACHMENT = 'APPEND_TICKET_ATTACHMENT';
export const SET_PANIC_STATUS = 'SET_PANIC_STATUS';
export const SET_TICKET_ASSIGNEE = 'SET_TICKET_ASSIGNEE';


// Dialog meta-behaviour
export const DIALOG_START_LOADING = 'DIALOG_START_LOADING';
export const DIALOG_STOP_LOADING = 'DIALOG_STOP_LOADING';

// User data
export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_CAN_LOAD_MORE_USERS = 'SET_CAN_LOAD_MORE_USERS';
export const SET_SEARCH_FILTER_TEXT = 'SET_SEARCH_FILTER_TEXT';

// Ticket logs
export const SET_TICKET_LOGS = 'SET_TICKET_LOGS';

export const SET_ORDER_SEARCH_ORDERS = 'SET_ORDER_SEARCH_ORDERS';
export const SET_ORDER_SEARCH_GET_ORDERS_REQUEST_STATUS = 'SET_ORDER_SEARCH_GET_ORDERS_REQUEST_STATUS';
export const SET_ORDER_SEARCH_LIMIT = 'SET_ORDER_SEARCH_LIMIT';
export const SET_ORDER_SEARCH_OFFSET = 'SET_ORDER_SEARCH_OFFSET';
export const SET_ORDER_SEARCH_PAGINATION_STARTING_AFTER = 'SET_ORDER_SEARCH_PAGINATION_STARTING_AFTER';
export const SET_ORDER_SEARCH_PAGINATION_ENDING_BEFORE = 'SET_ORDER_SEARCH_PAGINATION_ENDING_BEFORE';
export const SET_ORDER_SEARCH_HAS_MORE_ORDERS = 'SET_ORDER_SEARCH_HAS_MORE_ORDERS';
export const SET_ORDER_SEARCH_TYPE = 'SET_ORDER_SEARCH_TYPE';
export const SET_ORDER_SEARCH_USER_ID = 'SET_ORDER_SEARCH_USER_ID';
export const SET_ORDER_SEARCH_INVOICE_NAME = 'SET_ORDER_SEARCH_INVOICE_NAME';
export const SET_ORDER_SEARCH_USER_EMAIL = 'SET_ORDER_SEARCH_USER_EMAIL';
export const SET_ORDER_SEARCH_ORDER_ID = 'SET_ORDER_SEARCH_ORDER_ID';
export const SET_ORDER_SEARCH_ORDER_ITEM_ID = 'SET_ORDER_SEARCH_ORDER_ITEM_ID';

export const SET_STATE = 'SET_STATE';
export const SET_ORDER = 'SET_ORDER';
export const SET_GET_ORDER_REQUEST_STATUS = 'SET_GET_ORDER_REQUEST_STATUS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_ORDER_ITEM_ID = 'SET_ORDER_ITEM_ID';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_SELECTED_TICKET_TOPIC = 'SET_SELECTED_TICKET_TOPIC';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_FILES = 'SET_FILES';
export const SET_FILES_FORM_DATA = 'SET_FILES_FORM_DATA';
export const SET_FILES_ABOVE_SIZE_LIMIT = 'SET_FILES_ABOVE_SIZE_LIMIT';

export const SET_CREATE_TICKET_LOADING_STATUS = 'SET_CREATE_TICKET_LOADING_STATUS';

export const SET_STORE_MODULE_NAMESPACE = 'SET_STORE_MODULE_NAMESPACE';

export const SET_MESSAGE_TEMPLATES_IS_VISIBLE = 'SET_MESSAGE_TEMPLATES_IS_VISIBLE';
export const SET_MESSAGE_TEMPLATE = 'SET_MESSAGE_TEMPLATE';
export const SET_MESSAGE_TEMPLATE_CONTENT = 'SET_MESSAGE_TEMPLATE_CONTENT';
export const SET_MESSAGE_TEMPLATES_LANGUAGE = 'SET_MESSAGE_TEMPLATES_LANGUAGE';

// Global
export const SET_PAGE_VISIBLE = 'SET_PAGE_VISIBLE';
export const SET_DOMAIN_LIST = 'SET_DOMAIN_LIST';
export const SET_MERCHANT_LIST = 'SET_MERCHANT_LIST';
export const SET_ASSIGNEE_LIST = 'SET_ASSIGNEE_LIST';
export const SET_LAST_REFRESH_DATE_MERCHANT_LIST = 'SET_LAST_REFRESH_DATE_MERCHANT_LIST';
export const SET_LAST_REFRESH_DATE_ASSIGNEE_LIST = 'SET_LAST_REFRESH_DATE_ASSIGNEE_LIST';
export const SET_LAST_REFRESH_DATE_DOMAIN_LIST = 'SET_LAST_REFRESH_DATE_DOMAIN_LIST';
