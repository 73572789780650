import get from 'lodash/get';
import { InputFile } from '@refb/shared/services/inputFile';
import { getRequestStatus } from '@refb/shared/services/requestStatus';
import { isOrderOlderThan30Days, getOrderProductById, getOrderProducts } from '@/services/order';
import { getSpecificTicketTopics } from '@/services/ticketTopics';
import { byOrderId } from '@/api';
import {
  SET_STATE,
  SET_ORDER,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCT,
  SET_SELECTED_TICKET_TOPIC,
  SET_MESSAGE,
  SET_FILES,
  SET_FILES_FORM_DATA,
  SET_FILES_ABOVE_SIZE_LIMIT,
  SET_CREATE_TICKET_LOADING_STATUS,
  SET_MESSAGE_TEMPLATES_IS_VISIBLE,
} from '@/store/mutationTypes';

const inputFile = InputFile();
const getInitialState = () => ({
  order: null,
  products: [],
  selectedProduct: null,
  selectedTopic: null,
  message: '',
  attachments: [],
  attachmentsFormData: null,
  attachmentsAboveSizeLimit: [],
  messageTemplatesIsVisible: false,
  createTicketLoadingStatus: getRequestStatus(),
});

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {
    getOrderCreatedAt(state) {
      return get(state.order, 'createdAt', '');
    },
    isOrderOlderThan30Days(state, getters) {
      return isOrderOlderThan30Days(getters.getOrderCreatedAt);
    },
    getSelectedProductType(state) {
      return get(state.selectedProduct, 'itemType', '');
    },
    getTopics(state, getters) {
      return getSpecificTicketTopics({
        orderIsOlderThan30Days: getters.isOrderOlderThan30Days,
        productType: getters.getSelectedProductType,
      });
    },
    getRefundFromProduct(state) {
      if (!state.selectedProduct) {
        return null;
      }

      const item = state.selectedProduct;

      return {
        amount: item.refunded || '',
        dateTime: item.lastRefundAt || '',
      };
    },
  },

  actions: {
    setOrder({ commit }, order) {
      commit(SET_ORDER, order);
    },
    setProducts({ commit }, products) {
      commit(SET_PRODUCTS, products);
    },
    setSelectedProduct({ commit }, selectedProduct) {
      commit(SET_SELECTED_PRODUCT, selectedProduct);
    },
    setSelectedTopic({ commit }, selectedTopicValue) {
      commit(SET_SELECTED_TICKET_TOPIC, selectedTopicValue);
    },
    setMessage({ commit }, message) {
      commit(SET_MESSAGE, message);
    },
    setAttachments({ commit }, attachments) {
      commit(SET_FILES, attachments);
    },
    setAttachmentsFormData({ commit }, attachmentsFormData) {
      commit(SET_FILES_FORM_DATA, attachmentsFormData);
    },
    setAttachmentsAboveSizeLimit({ commit }, attachmentsAboveSizeLimit) {
      commit(SET_FILES_ABOVE_SIZE_LIMIT, attachmentsAboveSizeLimit);
    },
    async getOrder({ dispatch }, orderId) {
      // TODO: remove this method if is not used anymore
      console.log('🚀 createTicket.js ~ actions ~ getOrder');
      try {
        const response = await byOrderId(orderId).getDetails({
          ungrouped: true,
        });

        dispatch('setOrder', response.data);
      } catch (error) {
        dispatch('setOrder', null);
      }
    },
    getProducts({ dispatch }, { order, orderItemId }) {
      // TODO: remove this method if is not used anymore
      console.log('🚀 createTicket.js ~ actions ~ getProducts');

      const products = [];

      if (!orderItemId) {
        products.push(...getOrderProducts(order));
      } else {
        products.push(getOrderProductById(order, orderItemId));
      }

      dispatch('setProducts', products);
    },
    addAttachments({ dispatch }) {
      inputFile.addFiles((response) => {
        dispatch('setAttachments', response.files);
        dispatch('setAttachmentsFormData', response.formData);
        dispatch('setAttachmentsAboveSizeLimit', response.filesAboveSizeLimit);
      });
    },
    removeAttachment({ dispatch }, attachment) {
      inputFile.removeFile(attachment.id, (response) => {
        dispatch('setAttachments', response.files);
        dispatch('setAttachmentsFormData', response.formData);
      });
    },
    showMessageTemplates({ commit }) {
      commit(SET_MESSAGE_TEMPLATES_IS_VISIBLE, true);
    },
    hideMessageTemplates({ commit }) {
      commit(SET_MESSAGE_TEMPLATES_IS_VISIBLE, false);
    },
    setCreateTicketLoadingStatus({ commit }, loadingStatus) {
      commit(SET_CREATE_TICKET_LOADING_STATUS, getRequestStatus(loadingStatus));
    },
    reset({ commit }) {
      inputFile.reset();
      commit(SET_STATE, getInitialState());
    },
  },

  mutations: {
    [SET_STATE](state, newState) {
      Object.assign(state, newState);
    },
    [SET_ORDER](state, order) {
      state.order = order;
    },
    [SET_PRODUCTS](state, products) {
      state.products = products;
    },
    [SET_SELECTED_PRODUCT](state, selectedProduct) {
      state.selectedProduct = selectedProduct;
    },
    [SET_SELECTED_TICKET_TOPIC](state, selectedTopicValue) {
      state.selectedTopic = selectedTopicValue;
    },
    [SET_MESSAGE](state, message) {
      state.message = message;
    },
    [SET_FILES](state, files) {
      state.attachments = files;
    },
    [SET_FILES_FORM_DATA](state, filesFormData) {
      state.attachmentsFormData = filesFormData;
    },
    [SET_FILES_ABOVE_SIZE_LIMIT](state, filesAboveSizeLimit) {
      state.attachmentsAboveSizeLimit = filesAboveSizeLimit;
    },
    [SET_MESSAGE_TEMPLATES_IS_VISIBLE](state, messageTemplatesIsVisible) {
      state.messageTemplatesIsVisible = messageTemplatesIsVisible;
    },
    [SET_CREATE_TICKET_LOADING_STATUS](state, loadingStatus) {
      state.createTicketLoadingStatus = loadingStatus;
    },
  },
};
