export const formatCurrency = (number, options) => {
  const {
    currencyIso,
    languageIso,
    countryIso,
    currencyDisplay = 'symbol',
    forcedCurrency,
  } = options;

  if (forcedCurrency === 'DKK') {
    return new Intl.NumberFormat('da-dk', { style: 'currency', currency: 'DKK' }).format(number);
  }

  const locales = (() => {
    if (languageIso && countryIso) {
      return `${languageIso}-${countryIso}`;
    } if (languageIso && !countryIso) {
      return languageIso;
    }
    return undefined;
  })();

  return new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currencyIso,
    currencyDisplay,
  }).format(number);
};
