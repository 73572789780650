

/* eslint-disable max-len */
import {
  buildFilter, buildListPagination, buildListSorting, defaultParams,
} from '@/utils/grpc';
import GrpcAuthInterceptor from '@/utils/grpcAuthInterceptor';
import { API_GRPC_URL } from '@/config';
import merge from 'lodash/merge';
import get from 'lodash/get';
import { getTicketTopicsByBuybackOrderItem, postBuybackCreateTicket, byTicketId } from '@/api';
import Logger from '@/services/LoggerService';
import { ListBuybackOrdersRequest, GetBuybackOrderRequest } from '~grpc/merchant/v1/services/buyback_order_service_pb';
import { BuybackOrderServicePromiseClient } from '~grpc/merchant/v1/services/buyback_order_service_grpc_web_pb';
import { StringFilter } from '~grpc/core/v1/filters/string_filter_pb';

class BuybackOrderService {
  constructor() {
    Logger.info('BuybackOrderService::merchant');
    this.buybackOrderService = new BuybackOrderServicePromiseClient(
      API_GRPC_URL,
      null,
      { unaryInterceptors: [new GrpcAuthInterceptor()] },
    );
  }

  getOrder = async (orderId) => {
    try {
      Logger.info('BuybackOrderService::getOrder [params]', orderId);
      const request = new GetBuybackOrderRequest();

      request.setId(orderId);

      Logger.info('BuybackOrderService::getOrder [request obj]', request.toObject());
      const response = await this.buybackOrderService.getBuybackOrder(request);
      Logger.info('BuybackOrderService::getOrder [response]', response);
      const data = response.toObject();
      Logger.info('BuybackOrderService::getOrder [data]', data);
      return data;
    } catch (error) {
      Logger.error('BuybackOrderService::getOrder [error]', error);
      throw error;
    }
  }

  getBuybackOrder = async (params) => {
    const paramsWithDefaults = merge(defaultParams, params);
    try {
      Logger.info('BuybackOrderService::getBuybackOrder [params]', paramsWithDefaults);
      const request = new ListBuybackOrdersRequest();

      if (paramsWithDefaults.sort) {
        const sortMap = {
          attributeStorage: 'attribute',
        };
        const attributeMap = {
          attributeStorage: 2,
        };

        const mappedSort = { ...paramsWithDefaults.sort };
        if (sortMap[paramsWithDefaults.sort.by]) {
          mappedSort.by = sortMap[paramsWithDefaults.sort.by];
        }
        const sort = buildListSorting(mappedSort, ListBuybackOrdersRequest);
        if (attributeMap[paramsWithDefaults.sort.by]) {
          sort.setProductAttributeId(attributeMap[paramsWithDefaults.sort.by]);
        }
        request.setSort(sort);
      }

      Logger.info('BuybackOrderService::getBuybackOrder [request obj]', request.toObject());
      const response = await this.buybackOrderService.getBuybackOrder(request);
      Logger.info('BuybackOrderService::getBuybackOrder [response]', response);
      const data = response.toObject();
      Logger.info('BuybackOrderService::getBuybackOrder [data]', data);
      return data;
    } catch (error) {
      Logger.error('BuybackOrderService::getBuybackOrder [error]', error);
      throw error;
    }
  }

  listBuybackOrders = async (params) => {
    const paramsWithDefaults = merge({}, defaultParams, params);

    try {
      Logger.info('BuybackOrderService::listBuybackOrders [params]', paramsWithDefaults);
      const request = new ListBuybackOrdersRequest();

      const pagination = buildListPagination(paramsWithDefaults.pagination);
      request.setPagination(pagination);

      if (paramsWithDefaults.sort) {
        const sortMap = {};
        const attributeMap = {};

        const mappedSort = { ...paramsWithDefaults.sort };
        if (sortMap[paramsWithDefaults.sort.by]) {
          mappedSort.by = sortMap[paramsWithDefaults.sort.by];
        }
        const sort = buildListSorting(mappedSort, ListBuybackOrdersRequest);
        if (attributeMap[paramsWithDefaults.sort.by]) {
          sort.setProductAttributeId(attributeMap[paramsWithDefaults.sort.by]);
        }
        request.setSort(sort);
      }

      const filter = new ListBuybackOrdersRequest.Filter();

      if (paramsWithDefaults.filters.id) {
        const idFilter = buildFilter.int64Filter(paramsWithDefaults.filters.id);
        filter.setId(idFilter);
      }

      if (paramsWithDefaults.filters.customerEmail) {
        const operators = ['any_of', 'none_of'];
        const customerEmailFilter = buildFilter.customEnumFilter(
          paramsWithDefaults.filters.customerEmail,
          operators,
          StringFilter,
        );
        filter.setCustomerEmail(customerEmailFilter);
      }

      request.setFilter(filter);

      Logger.info('BuybackOrderService::listBuybackOrders [request obj]', request.toObject());
      const response = await this.buybackOrderService.listBuybackOrders(request);
      Logger.info('BuybackOrderService::listBuybackOrders [response]', response);
      const data = response.toObject();
      Logger.info('BuybackOrderService::listBuybackOrders [data]', data);
      return data;
    } catch (error) {
      Logger.error('BuybackOrderService::listBuybackOrders [error]', error);
      throw error;
    }
  }

  getTicketTopics = async (orderItemId) => {
    try {
      const response = await getTicketTopicsByBuybackOrderItem(orderItemId);
      return response.data;
    } catch (error) {
      throw new Error('BuybackOrderService::getTicketTopics [error]', error);
    }
  }

  createTicket = async (params) => {
    try {
      const response = await postBuybackCreateTicket(params);

      return response.data;
    } catch (error) {
      throw new Error('BuybackOrderService::createTicket [error]', error);
    }
  }

  addAttachmentToTicket = async ({ ticketId, attachment, audience }) => {
    try {
      const response = await byTicketId(ticketId).postAttachment({
        attachment,
        audience,
      });

      return response.data;
    } catch (error) {
      throw new Error('OrderService::addAttachmentToTicket [error]', error);
    }
  }

  static getOrderItems = order => get(order, 'orderItemsList', []);

  // TODO: If we CAN NOT have orders with addons, this method is not necessary
  // the `getOrderItems` method can be used instead
  getProducts = order => BuybackOrderService.getOrderItems(order);
}

export default BuybackOrderService;
export {
  BuybackOrderService,
};
