// source: refb/refurbed/merchant/v1/common/buyback_attribute.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var refb_refurbed_core_v1_enums_buyback_attribute_type_pb = require('../../../../../refb/refurbed/core/v1/enums/buyback_attribute_type_pb.js');
goog.object.extend(proto, refb_refurbed_core_v1_enums_buyback_attribute_type_pb);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.BuybackAttribute', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue', null, global);
goog.exportSymbol('proto.refb.refurbed.merchant.v1.BuybackAttribute.ValueCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.refb.refurbed.merchant.v1.BuybackAttribute.oneofGroups_);
};
goog.inherits(proto.refb.refurbed.merchant.v1.BuybackAttribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.BuybackAttribute.displayName = 'proto.refb.refurbed.merchant.v1.BuybackAttribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.displayName = 'proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.displayName = 'proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.oneofGroups_ = [[4,5]];

/**
 * @enum {number}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.ValueCase = {
  VALUE_NOT_SET: 0,
  VALUE_STR: 4,
  VALUE_NUM: 5
};

/**
 * @return {proto.refb.refurbed.merchant.v1.BuybackAttribute.ValueCase}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.getValueCase = function() {
  return /** @type {proto.refb.refurbed.merchant.v1.BuybackAttribute.ValueCase} */(jspb.Message.computeOneofCase(this, proto.refb.refurbed.merchant.v1.BuybackAttribute.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.BuybackAttribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    valueStr: (f = msg.getValueStr()) && proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.toObject(includeInstance, f),
    valueNum: (f = msg.getValueNum()) && proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.BuybackAttribute;
  return proto.refb.refurbed.merchant.v1.BuybackAttribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.refb.refurbed.core.v1.BuybackAttributeTypeEnum.BuybackAttributeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue;
      reader.readMessage(value,proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.deserializeBinaryFromReader);
      msg.setValueStr(value);
      break;
    case 5:
      var value = new proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue;
      reader.readMessage(value,proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.deserializeBinaryFromReader);
      msg.setValueNum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.BuybackAttribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getValueStr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getValueNum();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue;
  return proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.prototype.toObject = function(opt_includeInstance) {
  return proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue;
  return proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string unit = 2;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.prototype.setUnit = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.prototype.clearUnit = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue.prototype.hasUnit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional refb.refurbed.core.v1.BuybackAttributeTypeEnum.BuybackAttributeType type = 3;
 * @return {!proto.refb.refurbed.core.v1.BuybackAttributeTypeEnum.BuybackAttributeType}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.getType = function() {
  return /** @type {!proto.refb.refurbed.core.v1.BuybackAttributeTypeEnum.BuybackAttributeType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.refb.refurbed.core.v1.BuybackAttributeTypeEnum.BuybackAttributeType} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional StringValue value_str = 4;
 * @return {?proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.getValueStr = function() {
  return /** @type{?proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue, 4));
};


/**
 * @param {?proto.refb.refurbed.merchant.v1.BuybackAttribute.StringValue|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute} returns this
*/
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.setValueStr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.refb.refurbed.merchant.v1.BuybackAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.clearValueStr = function() {
  return this.setValueStr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.hasValueStr = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NumericValue value_num = 5;
 * @return {?proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.getValueNum = function() {
  return /** @type{?proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue} */ (
    jspb.Message.getWrapperField(this, proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue, 5));
};


/**
 * @param {?proto.refb.refurbed.merchant.v1.BuybackAttribute.NumericValue|undefined} value
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute} returns this
*/
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.setValueNum = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.refb.refurbed.merchant.v1.BuybackAttribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.refb.refurbed.merchant.v1.BuybackAttribute} returns this
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.clearValueNum = function() {
  return this.setValueNum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.refb.refurbed.merchant.v1.BuybackAttribute.prototype.hasValueNum = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.refb.refurbed.merchant.v1);
