import snakeCase from 'lodash/snakeCase';
import enTranslations from '../i18n/en';


const applyUnicodeRules = function applyUnicodeRules(text, args) {
  let suffix = 'other';
  const transKey = snakeCase(text);

  if (Number.isFinite(args) === true) {
    if (args === 0 || args === 1) {
      suffix = 'one';
    }

    // TODO: create adapter for languages
  }

  return [`${transKey}.${suffix}`, args];
};

export function plugin(Vue) {
  Vue.i18n.set('en');
  Vue.i18n.add('en', enTranslations);

  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$ts = (text, args) => {
    const [transKey] = applyUnicodeRules(text, args);
    return Vue.i18n.translate(transKey, args);
  };

  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$tp = function prefixTranslate(prefix, text, args) {
    return this.$ts(`${prefix}.${text}`, args);
  };
}
