/* eslint-disable no-shadow */
import snakeCase from 'lodash/snakeCase';
import merge from 'lodash/merge';
import * as Sentry from '@sentry/browser';
import {
  DEFAULT_TICKET_STATUS,
  TICKET_STATUS,
  SORT_ORDER,
  API_ITEMS_LIMIT,
  DEFAULT_PANIC_MODE,
  DEFAULT_OVERDUE,
} from '@/constants';
import {
  SET_TICKET_FILTER,
  SET_TICKETS,
  SET_STATISTICS,
  SET_TICKET_STATUS_FILTER,
  SET_SELECTED_TICKET,
  SET_ASSIGNED_USER_ID,
  SET_SORT_COLUMN,
  SET_SORT_ORDER,
  RESET_OFFSET,
  RESET_LIMIT,
  SET_TICKET_LIMIT,
  INCREASE_TICKET_OFFSET,
  SET_TICKET_TOPICS,
  SET_TICKET_OFFSET,
  SET_RESPONSE_ITEM_COUNT,
  RESET_RESPONSE_ITEM_COUNT,
  SET_LOADING,
  SET_PANIC_MODE,
  SET_OVERDUE,
  SET_HELP_REQUEST_REASONS,
  SET_LAST_REQUEST_HELP_REQUEST_REASONS,
} from '@/store/mutationTypes';

import { ticket } from '@/api';
import { getReasonLabel } from '@/utils/helpRequestReasons';
import treeselectFilter from '@/store/modules/tickets/treeselectFilter';
import * as actions from './actions/index';
import * as getters from './getters';

const modules = merge({}, treeselectFilter);

const regexUuid =
  '([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})';
// eslint-disable-next-line max-len
// const regexEmail = '((([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))';
const regexLooseEmail = '(^[^\\@]+\\@[^\\@]+$)';
const regexInt = '(\\d+$)';

const extractFilters = (query) => {
  const combinedRegexRule = new RegExp(`${regexUuid}|${regexLooseEmail}|${regexInt}|(.*)`, 'gi');

  const filters = {};

  try {
    // TODO: extract all filters
    const match = query.matchAll(combinedRegexRule).next();
    // eslint-disable-next-line no-unused-vars
    const [regexMatch, ticketId, email, ...meta] = match.value;
    const [queryString, orderId] = meta.reverse();

    filters.email = email || filters.email;
    filters.order = orderId || filters.order;
    filters.ticketId = ticketId || filters.ticketId;
    filters.query = queryString || filters.query;
  } catch (e) {
    // no matches, but it's okay.
  }

  return filters;
};

const makeStatObj = data => Object.entries(data).reduce(
  (prev, [key, val]) => ({
    ...prev,
    [snakeCase(key.replace('num', ''))]: val,
  }),
  {},
);

export default merge({}, modules, {
  namespaced: true,

  state: {
    activeTicket: null,
    tickets: [],
    ticketStatistics: {},
    ticketTopics: [],
    filterText: null,
    selectedStatus: DEFAULT_TICKET_STATUS,
    isLoading: true,
    assignedTo: null,
    sortCol: null,
    sortOrder: null,
    offset: 0,
    limit: API_ITEMS_LIMIT,
    lastResponseCount: 0,
    panicMode: DEFAULT_PANIC_MODE,
    overdue: DEFAULT_OVERDUE,
    lastRequestHelpRequestReasons: null,
    helpRequestReasons: [],
  },

  getters: {
    ...getters,
    isReady(state) {
      return state.isLoading === false;
    },
    getTickets(state) {
      if (!Array.isArray(state.tickets)) return [];

      return state.tickets;
    },
    getTicketStatistics(state) {
      return state.ticketStatistics;
    },
    getFilterText(state) {
      return state.filterText;
    },
    getActiveTicket(state) {
      return state.activeTicket;
    },
    statusFilters(state) {
      const status = state.selectedStatus;

      if (
        [
          // TICKET_STATUS.EXPEDITE,
          // TICKET_STATUS.INVOKED,
          // TICKET_STATUS.OVERDUE,
          // TICKET_STATUS.DELAYED,
          TICKET_STATUS.UNANSWERED,
          TICKET_STATUS.UNASSIGNED,
          // TICKET_STATUS.WAITING_FOR_ANSWER,
        ].some(s => s === status) === true
      ) {
        return {
          [status]: true,
          status: 'unresolved',
        };
      }

      const contexts = {
        [TICKET_STATUS.EXPEDITE]: {
          expedite: true,
          complete: false,
          status: 'unresolved',
        },
        [TICKET_STATUS.INVOKED]: {
          invoke: true,
          status: 'unresolved',
        },
        [TICKET_STATUS.ASSIGNED_TO]: {
          assignedTo: state.assignedTo,
          status: 'unresolved',
        },
        [TICKET_STATUS.UNRESOLVED]: {
          status,
        },
        [TICKET_STATUS.RESOLVED]: {
          status,
        },
        [TICKET_STATUS.PANICKED]: {
          panic: true,
          status: 'unresolved',
          hold: false,
          panic_mode: state.panicMode,
        },
        [TICKET_STATUS.OVERDUE]: {
          [status]: true,
          status: 'unresolved',
          unanswered: state.overdue,
          panic: false,
          hold: false,
        },
        [TICKET_STATUS.DELAYED]: {
          [status]: true,
          status: 'unresolved',
          overdue: true,
          complete: false,
        },
        [TICKET_STATUS.WAITING_FOR_ANSWER]: {
          waiting_for_answer: true,
          unanswered: false,
          complete: false,
          status: 'unresolved',
          overdue: false,
        },
        [TICKET_STATUS.NO_MERCHANT_ANSWER]: {
          [status]: true,
          status: 'unresolved',
          merchant_answer: false,
          overdue: false,
        },
      };

      if (contexts[status] === undefined) {
        throw new Error('Filter for status was not defined');
      }

      return contexts[status];
    },
    queryParams(state, getters) {
      const extractedFilters = extractFilters(state.filterText || '');
      const sortBy = (() => {
        if (state.sortCol === 'orderDomain') {
          return 'domain';
        }

        return snakeCase(state.sortCol);
      })();

      const baseFilters = {
        ...getters.statusFilters,
        ...extractedFilters,
        sort_by: sortBy,
        sort: state.sortOrder,
        limit: state.limit,
        offset: state.offset,
      };

      if (extractFilters.orderId) {
        return baseFilters;
      }

      return {
        ...baseFilters,
        ...getters.treeselectFilterQueryParams,
      };
    },
    filtersDisabled(state, getters) {
      return !!getters.queryParams.order;
    },
    sortDesc(state) {
      return state.sortOrder === SORT_ORDER.DESC;
    },
    sortBy(state) {
      return state.sortCol;
    },
    canLoadMore(state) {
      return (
        (state.lastResponseCount === undefined || state.lastResponseCount >= API_ITEMS_LIMIT) &&
        state.isLoading === false
      );
    },

    defaultSortCol(state) {
      const { OVERDUE, DELAYED } = TICKET_STATUS;
      const status = state.selectedStatus;

      if ([OVERDUE, DELAYED].some(i => status === i)) {
        return 'overdueAt';
      }

      return 'concludingMessageAt';
    },
    defaultSortOrder() {
      const { ASC } = SORT_ORDER;

      return ASC;
    },
    getHelpRequestReasonsWithLabel(state) {
      return state.helpRequestReasons.map(reason => ({
        slug: reason,
        description: getReasonLabel(reason),
      }));
    },
  },
  actions: {
    ...actions,
    setFilterStatus({ commit, dispatch }, filterStatus) {
      dispatch('resetRequestMeta');
      commit(SET_TICKET_STATUS_FILTER, filterStatus);
    },
    setFilterText({ dispatch, commit }, filterText) {
      if (filterText !== null) {
        dispatch('resetRequestMeta');
        commit(SET_TICKET_FILTER, String(filterText).trim());
      }
    },

    setUserId({ commit }, userId) {
      commit(SET_ASSIGNED_USER_ID, userId);
    },

    async loadStats({ getters: { queryParams }, commit }) {
      try {
        const { data } = await ticket.getStats(queryParams);
        commit(SET_STATISTICS, makeStatObj(data));
      } catch (error) {
        Sentry.captureException(error);
      }
    },

    async loadTickets({ getters: { queryParams }, commit, dispatch }) {
      if (queryParams.ticketId) {
        dispatch('selectTicket', queryParams.ticketId);
        return;
      }

      commit(SET_LOADING, true);

      // TODO remove this once a clean solution is ready for order search
      const params = queryParams.order ?
        {
          ...queryParams,
          assigned_to: undefined,
          topic: [],
          merchant: undefined,
          unanswered: undefined,
          expedite: undefined,
        } :
        queryParams;

      const [ticketsResponse, statsResponse] = await Promise.all([
        ticket.getTickets(params),
        ticket.getStats(params),
      ]);

      try {
        const stats = makeStatObj(statsResponse.data);
        const tickets = ticketsResponse.data;

        commit(SET_STATISTICS, stats);
        commit(SET_TICKETS, tickets);
        commit(SET_RESPONSE_ITEM_COUNT, tickets.length);
      } catch (error) {
        commit(SET_TICKETS, null);
        Sentry.captureException(error);
      }

      commit(SET_LOADING, false);
    },

    async appendTickets({ getters: { queryParams, getTickets }, commit }) {
      commit(SET_LOADING, true);

      try {
        const { data } = await ticket.getTickets(queryParams);
        commit(SET_RESPONSE_ITEM_COUNT, data.length);
        commit(SET_TICKETS, [...getTickets, ...data]);
      } catch (error) {
        Sentry.captureException(error);
      }

      commit(SET_LOADING, false);
    },

    selectTicket({ state, commit }, ticketId) {
      const selectedTicket = state.tickets.find(({ id }) => id === ticketId);

      commit(SET_SELECTED_TICKET, selectedTicket || { id: ticketId });
    },

    dropSelection({ commit }) {
      commit(SET_SELECTED_TICKET, null);
    },

    removeUUIDFromFilterText({ commit, getters: { getFilterText } }) {
      if (!getFilterText) {
        return;
      }

      const uuidRegex = new RegExp(regexUuid);
      const updatedFilterText = getFilterText.replace(uuidRegex, '');

      commit(SET_TICKET_FILTER, updatedFilterText);
    },

    toggleSortOrder({ state, commit, dispatch }, colName) {
      let sortOrder = SORT_ORDER.ASC;

      if (state.sortCol === colName) {
        sortOrder = state.sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
      }

      dispatch('resetRequestMeta');
      commit(SET_SORT_ORDER, sortOrder);
    },

    setSortOrder({ commit }, sortOrder) {
      commit(SET_SORT_ORDER, sortOrder);
    },

    setSortColumn({ commit }, colName) {
      commit(SET_SORT_COLUMN, colName);
    },

    resetRequestMeta({ commit }) {
      commit(RESET_OFFSET);
      commit(RESET_RESPONSE_ITEM_COUNT);
    },

    increaseOffset({ commit }) {
      commit(INCREASE_TICKET_OFFSET);
    },

    setPanicMode({ commit }, payload) {
      commit(SET_PANIC_MODE, payload);
    },

    setOverdue({ commit }, payload) {
      commit(SET_OVERDUE, payload);
    },

    setHelpRequestReasons({ commit }, payload) {
      commit(SET_HELP_REQUEST_REASONS, payload);
    },
    setLastRequestHelpRequestReasons({ commit }, payload) {
      commit(SET_LAST_REQUEST_HELP_REQUEST_REASONS, payload);
    },
  },

  mutations: {
    [SET_SELECTED_TICKET](state, _ticket) {
      state.activeTicket = _ticket;
    },
    [SET_TICKET_FILTER](state, filter) {
      state.filterText = filter;
    },
    [SET_TICKET_STATUS_FILTER](state, status) {
      state.selectedStatus = status;
    },
    [SET_TICKETS](state, tickets) {
      state.tickets = tickets;
    },
    [SET_STATISTICS](state, statistics) {
      state.ticketStatistics = statistics;
    },
    [SET_PANIC_MODE](state, payload) {
      state.panicMode = payload;
    },
    [SET_OVERDUE](state, payload) {
      state.overdue = payload;
    },
    [SET_ASSIGNED_USER_ID](state, id) {
      state.assignedTo = id;
    },
    [SET_SORT_COLUMN](state, columnName) {
      state.sortCol = columnName;
    },
    [SET_SORT_ORDER](state, order) {
      state.sortOrder = order;
    },
    [RESET_OFFSET](state) {
      state.offset = 0;
    },
    [RESET_LIMIT](state) {
      state.limit = API_ITEMS_LIMIT;
    },
    [SET_TICKET_TOPICS](state, ticketTopics) {
      state.ticketTopics = ticketTopics;
    },
    [SET_TICKET_LIMIT](state, limit) {
      state.limit = limit;
    },
    [SET_TICKET_OFFSET](state, offset) {
      state.offset = offset;
    },
    [INCREASE_TICKET_OFFSET](state) {
      state.offset += API_ITEMS_LIMIT;
    },
    [SET_RESPONSE_ITEM_COUNT](state, count) {
      state.lastResponseCount = count;
    },
    [RESET_RESPONSE_ITEM_COUNT](state) {
      state.lastResponseCount = undefined;
    },
    [SET_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
    [SET_HELP_REQUEST_REASONS](state, payload) {
      state.helpRequestReasons = payload;
    },
    [SET_LAST_REQUEST_HELP_REQUEST_REASONS](state, payload) {
      state.lastRequestHelpRequestReasons = payload;
    },
  },
});
